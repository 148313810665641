import React, { useState, CSSProperties, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";

import {
  CreateLocationStep,
  resetStateCreatLocUsers,
  setCreateLocationStep,
  setCSVResults,
  setCsvSize,
  setMultiLocError,
} from "../../../redux/slices/location/createLocation";
import { State } from "../../../redux/slices";
import Alert from "@mui/material/Alert";

import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { managingErrorValidator } from "./InarixTips";
import { createMultiLocationLocation } from "../../../redux/actions/locations/locationsAsyncActions";
import { TokenPayload } from "../../../redux/actions/authentication/authentication";
import jwt from "jsonwebtoken";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

//Table

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCsvResults: (value: any) => {
      dispatch(setCSVResults(value));
    },
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
    setCSVSize: (value: number) => {
      dispatch(setCsvSize(value));
    },

    createMultiLocation: (token: string, realmId: number) => {
      dispatch(createMultiLocationLocation({ token, realmId }));
    },
    cancel: () => {
      dispatch(resetStateCreatLocUsers());
    },
    removeError: () => {
      dispatch(setMultiLocError(""));
    },
  };
};

export interface ObjectRowsTransform {
  siteName: string;
  latitude: number;
  longitude: number;
  isLatitudeGood: boolean;
  isLongitudeGood: boolean;
  isSiteNameGood: boolean;
  rowPosition: number;
  isTheSiteNameRepeated: string[];
  isTheLocationExist: boolean;
}

const BLUE = "#3478F6";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${BLUE}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: BLUE,
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

const CSVReader = () => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [t] = useTranslation("global");

  const { locations } = useSelector((state: State) => state.MapReducer);

  const existingLocation = locations.map((users) => users.name);

  const { setCsvResults } = SetActions();
  const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;

  const isSiteName = (siteName: string) => {
    if (siteName.length === 0) {
      return false;
    }
    if (!siteName) {
      return false;
    }

    if ((siteName as any) instanceof String) {
      return false;
    } else {
      return true;
    }
  };

  const theLocationsExistValidator = (username: string) =>
    existingLocation.filter((val) => username === val);

  const theLocationsIsRepeatedValidator = (
    username: string,
    csvResultsSites: string[]
  ) => csvResultsSites.filter((val) => username === val);

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        const results1 = results.data.map((values: any) => {
          const [siteName, latitude, longitude] = values;
          const yourObject = { siteName, latitude, longitude };
          return yourObject;
        });
        const resultTransform = results1.map(
          (value: ObjectRowsTransform, index: number) => ({
            siteName: value.siteName,
            latitude: Number(value.latitude),
            longitude: Number(value.longitude),
            isLatitudeGood: isLatitude(Number(value.latitude)),
            isLongitudeGood: isLongitude(Number(value.longitude)),
            isSiteNameGood: isSiteName(value.siteName),
            isTheSiteNameRepeated: theLocationsIsRepeatedValidator(
              value.siteName,
              results1.map((value: any) => value.siteName)
            ),
            rowPosition: index + 1,
            isTheLocationExist:
              theLocationsExistValidator(value.siteName).length >= 1
                ? true
                : false,
          })
        );

        const filterData = resultTransform.filter(function (
          element: ObjectRowsTransform
        ) {
          return (
            element.siteName !==
              t("Create_multi_location.step_two.site_name", {
                lngs: ["fr"],
              }) &&
            element.siteName !==
              t("Create_multi_location.step_two.site_name", {
                lngs: ["en"],
              }) &&
            element.siteName !==
              t("Create_multi_location.step_two.site_name", {
                lngs: ["es"],
              }) &&
            element.siteName !==
              t("Create_multi_location.step_two.site_name", {
                lngs: ["de"],
              }) &&
            element.siteName !== "" &&
            element.latitude !== null &&
            element.longitude !== null
          );
        });

        setCsvResults(filterData);
        setZoneHover(false);
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }: any) => {
        return (
          <>
            <div
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>

                      <span style={styles.name}>
                        {JSON.stringify(acceptedFile.size)}
                      </span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                      onClick={(event: Event) => {
                        getRemoveFileProps().onClick(event);
                        setCsvResults([]);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>

                  <div className="alert-error-csv">
                    {acceptedFile.size > 1200000 ? (
                      <div className="">
                        <Alert severity="error">
                          <h5>
                            {t("Create_multi_location.csv_validator.big")}
                          </h5>
                        </Alert>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <div className="csv-container-drag-drop">
                  <div>
                    <UploadFileRoundedIcon
                      sx={{
                        color: BLUE,
                      }}
                    />
                  </div>
                  <div className="csv-drag-desc">
                    <div className="mr-1 mt-1 drag">
                      {t("Create_multi_location.step_three.drag")}
                    </div>
                    <div className="mt-1 drag-2 drag">
                      {t("Create_multi_location.step_three.drag2")}
                    </div>
                  </div>
                  <div className="mt-1 drag">
                    {t("Create_multi_location.step_three.drag3")}
                  </div>
                </div>
              )}
            </div>
          </>
        );
      }}
    </CSVReader>
  );
};

export const StepThreeMultiLocations: FC<any> = () => {
  const [t] = useTranslation("global");
  const { setCsvResults, createMultiLocation, cancel, removeError } =
    SetActions();
  const { user } = useSelector((state: State) => state.Authentication);
  const { multiLocationCreatedSuccess, multilocError, multiLocationLoading } =
    useSelector((state: State) => state.CreateLocationReducer);
  const token = user?.token ?? "";
  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;
  const navigate = useNavigate();

  const { csvResults } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const encounterErrorLatitude = csvResults.filter(
    (rows: ObjectRowsTransform) => rows.isLatitudeGood === false
  );

  const encounterErrorLongitude = csvResults.filter(
    (rows: ObjectRowsTransform) => rows.isLongitudeGood === false
  );

  const encounterErrorSiteName = csvResults.filter(
    (rows: ObjectRowsTransform) => rows.isSiteNameGood === false
  );

  const encounterErrorLocationExist = csvResults.filter(
    (rows: ObjectRowsTransform) => rows.isTheLocationExist === true
  );

  const encounterRepeatedName = csvResults.filter(
    (rows: ObjectRowsTransform) => rows.isTheSiteNameRepeated.length > 1
  );

  const totalErrors = encounterErrorLatitude.concat(
    encounterErrorLongitude,
    encounterErrorSiteName,
    encounterErrorLocationExist,
    encounterRepeatedName
  );

  if (multiLocationCreatedSuccess) {
    navigate("/");
    setTimeout(() => {
      setCsvResults([]);
      cancel();
    }, 2000);
  }

  if (multilocError) {
    setTimeout(() => {
      removeError();
    }, 20000);
  }

  useEffect(() => {
    setCsvResults([]);
  }, []);

  const resultsToCreate = () => {
    return (
      <div>
        <div className="mt-3 mb-3">
          {t("Create_multi_location.step_three.results")}
        </div>
        <div className="container-table-csv-results">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t("Create_multi_location.step_two.site_name")}
                  </TableCell>
                  <TableCell>
                    {t("Create_multi_location.step_two.latitude")}
                  </TableCell>
                  <TableCell>
                    {t("Create_multi_location.step_two.longitude")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {csvResults.map((row) => (
                  <TableRow key={row.siteName}>
                    <TableCell>{row.siteName}</TableCell>
                    <TableCell>{row.latitude}</TableCell>
                    <TableCell>{row.longitude}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Common_options.step")} 3/3</div>
      <div>
        <div className="step-title-create">
          {t("Create_multi_location.step_three.upload")}
        </div>
        <div className="csv-style-container">{CSVReader()}</div>

        <div>
          <div className="">
            {totalErrors.length >= 1
              ? managingErrorValidator(
                  encounterErrorLatitude,
                  encounterErrorLongitude,
                  encounterErrorSiteName,
                  encounterErrorLocationExist,
                  encounterRepeatedName,
                  t,
                  anchorEl,
                  setAnchorEl
                )
              : csvResults.length >= 1
              ? resultsToCreate()
              : ""}

            <div>
              {multilocError ? (
                <Alert severity="error" className="mt-3" sx={{ width: "40vw" }}>
                  {multilocError}
                </Alert>
              ) : (
                ""
              )}
            </div>

            <div>
              <Alert severity="info" className="mt-3" sx={{ width: "40vw" }}>
                {t("Create_multi_location.step_three.upload_desc")}
              </Alert>
            </div>

            <div className="mt-3">
              {totalErrors.length === 0 && csvResults.length >= 1 ? (
                <LoadingButton
                  loading={multiLocationLoading}
                  onClick={() => createMultiLocation(token, realmId)}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                >
                  {t("Create_multi_location.step_three.create")}
                </LoadingButton>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
