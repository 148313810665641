import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSampleViewPhotos } from "../../slices/data/dataSampleView";

export const fetchFiles = async (
  token: string,
  locale: string,
  fileId: number
): Promise<any> => {
  const res = await axios({
    method: "GET",
    url: `${API}/tools/file-manager/${fileId}`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const sampleDetails = createAsyncThunk(
  "SampleViewReducer/fetchDataSampleViewDetails",
  async (arg: { token: string; locale: string; pastAction: string }, store) => {
    try {
      const response = await axios.get(
        `${API}/samples/past-action/${arg.pastAction}?$eager=[creator,device,targetSample.[acquisitions.[images]],geoPoint,device,acquisitionPredictionRequests.[scenarioInstanceStep.[userFacingShortNameText.[localizedTexts.locale]]], sampleLabelInstances.labelTemplate.[configs.userFacingName.[localizedTexts.[locale]], type], scenarioInstance.[scenario.cerealType.[names]]]`,

        {
          headers: {
            Authorization: `Bearer ${arg.token}`,
            "Accept-Language": `${arg.locale}`,
          },
        }
      );
      const { data } = response;
      console.log(data, "pastActions");
      const values = data;
      const photos = values.targetSample.acquisitions.flat();
      const imagesFlat = photos;
      const imagesFlat2 = imagesFlat.flat();
      const finalImg = imagesFlat2.map((c: any) => c.images).flat();
      const fileId = finalImg.map((elt: any) => {
        return fetchFiles(arg.token, arg.locale, elt.fileId as number);
      });
      const photosPromises = await Promise.all(fileId);
      store.dispatch(setSampleViewPhotos(photosPromises));
      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(
        error.request.response ? error.request.response : error
      );
    }
  }
);
