import { createSlice } from "@reduxjs/toolkit";
import { DColumns } from "../../actions/data/DataDeclarations";
import {
  groupTemplateLooksLike,
  userProfiles,
} from "../../actions/users/userAsyncActions";
import { SiteUsersConfigRows } from "../../actions/users/userTypes";

export interface ManagerUsersConfigRows {
  assignedGroups: number[];
  assignedSite: number[];
  email: string;
  id: number;
  isNew: boolean;
  role: number | undefined | string;
  groupsLocationsSelectedIds?: number[];
  groupsSelected?: GroupStructure[];
}

export interface GroupStructure {
  createdAt: string | null;
  deletedAt: string | null;
  groupTemplateId: number;
  id: number;
  isAutoGenerated: boolean;
  name: string;
  orgId: number;
  typeId: number;
  updatedAt: null | string;
  locations: number[];
}

export interface CreateUsersStep {
  step: number;
  option: string;
  place: string;
  UsersValidate: boolean;
}

export interface CreateUsersQuantity {
  numberOfUserToCreate: number;
  usersNames: string;
  usersToCreate: string[];
}

export interface GroupTemplateRaw {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  description: null | string;
  defaultMembershipType: number;
  defaultTypeId: null | number;
}

export interface CreateUsersState {
  createUserSteps: CreateUsersStep;
  createUsers: CreateUsersQuantity;
  searchValues: {
    sites: string;
    actions: string;
  };
  error: string;
  errorCreateUsers: string;
  errorCreateLocations: string;
  usersConfigRows: SiteUsersConfigRows[];
  rowsAlpha: SiteUsersConfigRows[];
  columns: any[];
  actionsValues: any[];
  managerColumns: any[];
  managerUsersConfigRows: ManagerUsersConfigRows[];
  tryingToCreateSomething: boolean;
  theMaximumNumberIntheUsernamesCreations: number;
  verifyIfAllIsCreated: {
    createdUsers: boolean;
    linkActions: boolean;
    usersCreated: number;
  };
  createManagers: {
    createSuccess: boolean;
    createLoading: boolean;
    createError: string;
    linkActions: boolean;
    usersManagersCreated: number;
  };
  userRoles: any;
  createErrorManagerAllInarix: string[];
  createErrorManagerAllInarixShow: boolean;
  groupTemplateRaw: GroupTemplateRaw[];
  loading: boolean;
}

export const initialState: CreateUsersState = {
  createUserSteps: {
    step: 0,
    option: "",
    place: "",
    UsersValidate: true,
  },

  createUsers: {
    numberOfUserToCreate: 0,
    usersNames: "",
    usersToCreate: [],
  },

  searchValues: {
    sites: "",
    actions: "",
  },

  usersConfigRows: [],
  rowsAlpha: [],
  managerUsersConfigRows: [],
  columns: [
    {
      field: "id",
      sortable: true,
      width: 100,
      alwaysHide: true,
    },

    {
      field: "username",
      sortable: true,
      width: 200,
      alwaysHide: false,
    },
    {
      field: "assignedSite",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },
    {
      field: "assignedAction",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },

    {
      field: "nomadUser",
      sortable: false,
      width: 200,
      alwaysHide: false,
    },
    {
      field: "actions",
      sortable: false,
      width: 200,
      alwaysHide: false,
    },
  ] as DColumns[],
  error: "",
  errorCreateLocations: "",
  errorCreateUsers: "",
  actionsValues: [{ name: "", id: 0 }],
  managerColumns: [
    {
      field: "id",
      sortable: true,
      width: 100,
      alwaysHide: true,
    },
    {
      field: "email",
      sortable: true,
      width: 200,
      alwaysHide: false,
    },
    {
      field: "role",
      sortable: false,
      width: 200,
      alwaysHide: false,
    },
    {
      field: "assignedGroups",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },
    {
      field: "assignedSite",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },

    {
      field: "actions",
      sortable: false,
      width: 200,
      alwaysHide: false,
    },
  ] as DColumns[],
  tryingToCreateSomething: false,
  theMaximumNumberIntheUsernamesCreations: 0,
  verifyIfAllIsCreated: {
    createdUsers: false,
    linkActions: false,
    usersCreated: 0,
  },

  createManagers: {
    createSuccess: false,
    createLoading: false,
    createError: "",
    linkActions: false,
    usersManagersCreated: 0,
  },
  createErrorManagerAllInarix: [],
  createErrorManagerAllInarixShow: false,
  userRoles: [],
  groupTemplateRaw: [],
  loading: false,
};

export const CreateUsersSlice = createSlice({
  name: "CreateUsersReducer",
  initialState,
  reducers: {
    setCreateManagerCreationErrorShow(state, actions) {
      return {
        ...state,
        createErrorManagerAllInarixShow: actions.payload,
      };
    },
    setCreateManagerCreationError(state, actions) {
      return {
        ...state,
        createErrorManagerAllInarix: actions.payload,
      };
    },
    setCreateManagerValues(state, actions) {
      return {
        ...state,
        createManagers: actions.payload,
      };
    },
    setVerifyAllIsCreated(state, actions) {
      return {
        ...state,
        verifyIfAllIsCreated: actions.payload,
      };
    },
    setMaximumNumberFromUserCreations(state, action) {
      return {
        ...state,
        theMaximumNumberIntheUsernamesCreations: action.payload,
      };
    },
    setTryingToCreateSomething(state, action) {
      return { ...state, tryingToCreateSomething: action.payload };
    },
    setManagerUserConfigRows(state, action) {
      return { ...state, managerUsersConfigRows: action.payload };
    },

    setCreateUsersStep(state, action) {
      return {
        ...state,
        createUserSteps: action.payload,
      };
    },
    setCreateUsers(state, action) {
      return {
        ...state,
        createUsers: action.payload,
      };
    },

    setRowsAlpha(state, action) {
      return {
        ...state,
        rowsAlpha: action.payload,
      };
    },
    setUserConfigRows(state, action) {
      return {
        ...state,
        usersConfigRows: action.payload,
      };
    },
    setColumns(state, action) {
      return {
        ...state,
        columns: action.payload,
      };
    },
    setManagerColumns(state, action) {
      return {
        ...state,
        managerColumns: action.payload,
      };
    },
    resetStateCreateUsers() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userProfiles.pending, (state) => {})
      .addCase(userProfiles.fulfilled, (state, action) => {
        state.userRoles = action.payload;

        state.error = "";
      })
      .addCase(userProfiles.rejected, (state, action: any) => {
        state.userRoles = [];
        state.error =
          action.payload?.response?.data?.message || action?.payload;
      });

    builder
      .addCase(groupTemplateLooksLike.pending, (state) => {
        state.loading = true;
      })
      .addCase(groupTemplateLooksLike.fulfilled, (state, action) => {
        state.loading = false;
        state.groupTemplateRaw = action.payload;
        state.error = "";
      })
      .addCase(groupTemplateLooksLike.rejected, (state, action: any) => {
        state.loading = false;
        state.groupTemplateRaw = [];
        state.error =
          action.payload?.response?.data?.message || action?.payload;
      });
  },
});

export const {
  setCreateManagerCreationErrorShow,
  setCreateManagerCreationError,
  setCreateManagerValues,
  setVerifyAllIsCreated,
  setMaximumNumberFromUserCreations,
  setManagerColumns,
  setManagerUserConfigRows,
  setCreateUsersStep,
  setCreateUsers,
  setColumns,
  setRowsAlpha,
  setUserConfigRows,
  resetStateCreateUsers,
  setTryingToCreateSomething,
} = CreateUsersSlice.actions;

export const CreateUsers = CreateUsersSlice.reducer;
