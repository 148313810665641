import { ThemeProvider } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import themeInarix from "../StylesMuiInarix";
import UserLocationsColumns from "./UserLocationsFetchingColumns";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import AddIcon from "@mui/icons-material/Add";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  setSelectLocations,
  setCreatedGroupValues,
} from "../../redux/slices/fleet/locations";
import { useNavigate } from "react-router-dom";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setSelectLocations: (locations: any[]) => {
      dispatch(setSelectLocations(locations));
    },

    setCreatedGroupValues: (values: any) => {
      dispatch(setCreatedGroupValues(values));
    },
  };
};

export function UserLocationsTable(t: (arg0: string) => string) {
  const apiRef = useGridApiRef();
  const { setCreatedGroupValues } = SetActions();
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { loading, createdGroups, selectedGroupTitle } = useSelector(
    (state: State) => state.LocationsReducer
  );

  const navigate = useNavigate();

  const locationsSelectedB =
    createdGroups.locationsSelectedAutoComplete.valuesSelected.map((d) => d.id);

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  const { columns, locationsRows } = UserLocationsColumns(t);

  const topMenu = (
    <div className="container-options-locations-top-menu">
      <div className="top-menu-styles-locations">
        <div className="tab-title-locations">{selectedGroupTitle}</div>
        <div>
          <Button
            onClick={() => navigate("/create-locations")}
            variant="contained"
            endIcon={<AddIcon />}
            sx={{ textTransform: "none" }}
          >
            {t("Locations.create_new_site")}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-locations-table-">
      {topMenu}
      <Box sx={{ height: 500, width: 1, maxWidth: "calc(100% - 20px)" }}>
        <ThemeProvider theme={themeInarix(locale)}>
          <DataGridPro
            className="table-styles-alone"
            disableColumnSelector={true}
            rows={locationsRows}
            columns={columns as any}
            rowHeight={78}
            loading={loading}
            pagination
            checkboxSelection
            rowSelectionModel={locationsSelectedB as number[]}
            slots={{ toolbar: customToolbar }}
            pageSizeOptions={[25, 50, 100]}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onRowSelectionModelChange={(e: any) => {
              const locationsOptions = e.map((location: any) => ({
                id: location,
                name: apiRef.current.getCellValue(location, "name"),
              }));

              const transform = locationsOptions?.map((c: any) => c);

              setCreatedGroupValues({
                ...createdGroups,
                locationsSelectedAutoComplete: {
                  allValuesSelected: false,
                  valuesSelected: transform,
                },
              });
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            apiRef={apiRef}
          />
        </ThemeProvider>
      </Box>
    </div>
  );
}
