/**
 * @author David Roman <david@inarix.com>
 * @file Implements helper functions to manipulate localized texts
 * @desc Created on 2023-06-19 4:44:42 pm
 * @copyright Inarix
 */
import { LocalizedText } from "../declarations/localized-text";
import { LocalizedTextVersionTwo } from "../redux/actions/users/userTypes";

/**
 * This TypeScript function returns a localized text object based on a given locale or defaults to
 * English if the locale is not found.
 * @param {LocalizedText[]} names - an array of objects containing localized text, where each object
 * has a "locale" property indicating the language/locale of the text, and a "text" property containing
 * the actual localized text.
 * @param {string} locale - The `locale` parameter is a string that represents the language and region
 * for which the localized text is being requested. For example, "en-US" represents English language in
 * the United States, "fr-FR" represents French language in France, and so on.
 * @returns a `LocalizedText` object that matches the specified `locale` parameter, or if no match is
 * found, it returns the `LocalizedText` object with the `locale` property set to `'en'`. If no match
 * is found for `'en'` either, it returns `undefined`.
 */
export function getLocalizedTextByLocale(
  locale: string,
  names?: LocalizedText[]
): LocalizedText | undefined {
  if (!names) {
    return undefined;
  }

  let inLocaleText = names.find((x) => x.locale === locale);

  if (!inLocaleText) {
    inLocaleText = names.find((x) => x.locale === "en");
  }

  return inLocaleText;
}

export function getLocalizedTextByLocaleVersionTwo(
  locale: string,
  names?: LocalizedTextVersionTwo[]
): LocalizedText | undefined {
  if (!names) {
    return undefined;
  }

  let inLocaleText = names.find((x) => x.locale.value === locale);

  if (!inLocaleText) {
    inLocaleText =
      names.find((x) => x.locale.value === "fr") ||
      names.find((x) => x.locale.value === "en");
  }

  return inLocaleText?.translation as any;
}
