import { Box } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { FC } from "react";
import { ThemeProvider } from "@mui/material/styles";
import themeInarix from "../../../StylesMuiInarix";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../../redux/slices";
import CreateAllUsersColumns from "../columns/AllusersFetchingColumns";
import { fetchUsersNewRequest } from "../../../../redux/actions/users/userAsyncActions";
import { setTabNameLayout } from "../../../../redux/slices/layout";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  setAllUsersTableColumns,
  setNumberRowsUsers,
  setSelectedUser,
  setSelectionModelUsers,
} from "../../../../redux/slices/userGroups/userGroupsSliceMultipleTables";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    listUsersInfo: (token: string) => {
      dispatch(fetchUsersNewRequest(token));
      dispatch(setTabNameLayout("/users"));
      dispatch(setSelectionModelUsers([]));
    },

    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),

    setSelectionModel: (users: GridRowSelectionModel) => {
      dispatch(setSelectionModelUsers(users));
    },

    setAllUsersTableColumns: (allUserColumns: GridColDef[]) => {
      dispatch(setAllUsersTableColumns(allUserColumns));
    },

    selectUser: (selectedUser: GridRowParams<any>) => {
      dispatch(setSelectedUser(selectedUser));
    },
  };
};

export const AllUsersTable: FC<any> = () => {
  const apiRef = useGridApiRef();
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const { usersSelected } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );
  const { loading } = useSelector(
    (state: State) => state.UsersReducer
  );
  const {
    setRowsNumber,
    setSelectionModel,
    setAllUsersTableColumns,
    selectUser,
  } = SetActions();

  const { allUsersColumns, rowsAllUsersTable } = CreateAllUsersColumns();

  return (
    <Box sx={{ height: "65vh", width: 1 }}>
      <ThemeProvider theme={themeInarix(locale)}>
        <DataGridPro
          rows={rowsAllUsersTable.length === 0 ? [] : rowsAllUsersTable}
          columns={allUsersColumns}
          rowHeight={70}
          pagination
          disableColumnSelector={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          loading={loading}
          onColumnOrderChange={() => {
            setAllUsersTableColumns(apiRef.current.getAllColumns());
          }}
          onColumnWidthChange={() =>
            setAllUsersTableColumns(apiRef.current.getAllColumns())
          }
          pageSizeOptions={[25, 50, 100]}
          filterModel={undefined}
          onPaginationModelChange={(newPageSize) => setRowsNumber(newPageSize)}
          rowSelectionModel={usersSelected}
          onRowClick={(x) => selectUser(x.row)}
          onRowSelectionModelChange={(e) => {
            setSelectionModel(e);
          }}
          apiRef={apiRef}
        />
      </ThemeProvider>
    </Box>
  );
};
