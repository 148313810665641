import React from "react";
import { Box, Button, Skeleton, Snackbar } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const handlingSizeOptionsModal = (options: any[]) => {
  if (options.length > 6) {
    return "option-chips-scroll";
  }
  if (options.length) {
    return "";
  }
};

export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>
) {
  function ComponentWithRouterProp(props: Readonly<ComponentProps>) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const skeletonGroupsActionsAccordions = () => (
  <div className="accordion-actions-styles">
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
  </div>
);

export const skeletonSearchLocatioMap = () => (
  <div className="accordion-actions-styles">
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
  </div>
);

export const removeUndefined = (arrayWithUndefined: any[]) => {
  const remove = arrayWithUndefined.filter(function (element) {
    return element !== undefined;
  });

  return remove;
};

export const SnackBarAddCreate = (
  open: boolean,
  selected: any[],
  t: (arg0: string) => string,
  buttonTitle: string,
  whatIsSelected: string,
  functionButton: () => void,
  functionDelete: () => void,
  functionClose:() =>void,
  moreOptions?: string[]
) => {
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        // eslint-disable-next-line no-useless-concat
        key={"bottom" + "center"}
      >
        <div className="snackbar-styles">
          <div className="selected-snackbar-option">
            {`${selected.length} ${whatIsSelected} ${t(
              "Common_options.selected"
            )}`}
          </div>
          <div className="button-snackbar-general">
            <Button
              onClick={() => functionButton()}
              variant="contained"
              sx={{ textTransform: "none", fontSize: "13px" }}
            >
              {buttonTitle}
            </Button>
          </div>
          <div>
            {moreOptions?.map((x) => (
              <span onClick={() => console.log(x)}>{x}</span>
            ))}
          </div>

          {/* <div
            onClick={() => functionDelete()}
            className="delete-snackbar-genera"
          >
            {t("Common_options.Delete")}
          </div> */}
          <div className="close-snackbar-option" onClick={() => functionClose()}>X</div>
        </div>
      </Snackbar>
    </Box>
  );
};
