/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2023-08-16
 * @copyright Inarix
 */
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./configureStore";
import posthog from "posthog-js";

import { I18nextProvider } from "react-i18next";
import i18next from "./translate";
import { Buffer } from "buffer";
import "./assets/GlobalStyles.scss";
import "./App.css";

import { LicenseInfo } from "@mui/x-data-grid-pro";
global.Buffer = Buffer;

const { store, persistor } = configureStore();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// posthog.init(process.env.REACT_APP_POSTHOG_KEY as string, {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG,
// });

LicenseInfo.setLicenseKey(process.env.REACT_APP_KEY_UI as string);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>
);
