import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/slices";
import { LabelInstanceNewPortal } from "../../../redux/actions/data/DataDeclarations";
import { Alert, Button, TextField } from "@mui/material";
import { LoadingCircularProgress } from "../../Loading";
import {
  initialState,
  setAnnotationValuesToEdit,
  setModalEditAnnotation,
} from "../../../redux/slices/data/dataSampleView";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useStyles } from "../DataStyles";
import { v4 as uuidv4 } from "uuid";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setModalEditAnnotation: (value: any) => {
      dispatch(setAnnotationValuesToEdit(value));
    },

    closeModal: () => {
      dispatch(setModalEditAnnotation(false));
    },

    editAnnotationFunc: (token: string) => console.log("edit annotation"),
  };
};

export const BodyEditDeleteAnnotation = (
  t: (arg0: string) => string,
  token: string
): JSX.Element => {
  const classes = useStyles();
  const {
    annotationSelectedValuesToEdit,
    annotationSelectedToEdit,
    error,
    loadSampleDetails,
  } = useSelector((state: State) => state.DataSampleViewReducer);

  const { setModalEditAnnotation, closeModal, editAnnotationFunc } =
    SetActions();

  const {
    editAnnotation,
    deleteAnnotation,
    reasonToDeleteUpdate,
    valueSelectedToUpdate,
    newFileAnnotationEdit,
  } = annotationSelectedValuesToEdit;

  const updateAnnotation = editAnnotation;
  const reasonDeprecation = reasonToDeleteUpdate;
  const labelValue = valueSelectedToUpdate;
  const labelText = valueSelectedToUpdate;
  const setAnnotation = annotationSelectedToEdit as LabelInstanceNewPortal;

  const continuousAnnotation = "continuous";
  const categoricalAnnotation = "categorical";
  const freetextAnnotation = "freetext";
  const imgfileAnnotation = "img/file";

  const resetEdit = () => {
    closeModal();
    setModalEditAnnotation(initialState.annotationSelectedValuesToEdit);
  };

  const deprecateLabel = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
  };

  const editAnnotationAlfa = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    editAnnotationFunc(token);
  };

  const extractValue = (annotation: LabelInstanceNewPortal): string => {
    if (setAnnotation?.labelTemplate?.type?.value !== categoricalAnnotation) {
      return setAnnotation?.labelText as string;
    }

    let subDict = annotation?.generatedByLabelTemplateConfig
      ?.substitutionDict as any;
    if (!!subDict) {
      subDict = Object.keys(subDict).reduce((p, key) => {
        p[subDict[key]] = key;
        return p;
      }, {} as any);
    }
    return !!subDict && !!subDict[annotation?.labelText as string]
      ? subDict[annotation?.labelText as string]
      : annotation.labelText;
  };

  const handleEditAnnotation = () => {
    if (!updateAnnotation && !deleteAnnotation) {
      const handleFirstValueShow = () => {
        if (
          setAnnotation?.labelTemplate?.type?.value === categoricalAnnotation
        ) {
          return extractValue(setAnnotation);
        }
        if (
          setAnnotation?.labelTemplate?.type?.value === continuousAnnotation
        ) {
          return `${setAnnotation?.labelValue} ${setAnnotation?.labelTemplate?.unit}`;
        }
        if (setAnnotation?.labelTemplate?.type?.value === freetextAnnotation) {
          return setAnnotation.labelText;
        }
      };

      const manageDisableError = () => {
        if (error) {
          return true;
        } else {
          return false;
        }
      };

      return (
        <div>
          <div className="close-annotation">
            <button className="close mb-2" onClick={resetEdit}>
              &times;
            </button>
          </div>
          <div>
            {error && (
              <div className="alert-content-data-annot">
                <Alert severity="warning" className="mb-5 mt-5">
                  {JSON.stringify(error)}
                </Alert>
              </div>
            )}

            <h5 className="title-modal mt-4 mb-3 annot-edit d-flex justify-content-center">
              {handleFirstValueShow()}
            </h5>
          </div>
          <div className="options-annotations">
            <Button
              variant="outlined"
              color="error"
              className="mr-5"
              onClick={() =>
                setModalEditAnnotation({
                  ...annotationSelectedValuesToEdit,
                  editAnnotation: false,
                  deleteAnnotation: true,
                })
              }
              disabled={manageDisableError()}
            >
              {t("Data.SampleView.annotations.delete_annot")}
            </Button>
            <Button
              variant="outlined"
              disabled={manageDisableError()}
              color="primary"
              onClick={() =>
                setModalEditAnnotation({
                  ...annotationSelectedValuesToEdit,
                  editAnnotation: true,
                  deleteAnnotation: false,
                })
              }
            >
              {t("Data.SampleView.annotations.update_annot")}
            </Button>
          </div>
        </div>
      );
    }
    if (
      !updateAnnotation &&
      deleteAnnotation &&
      setAnnotation?.labelTemplate?.type?.value !== imgfileAnnotation
    ) {
      return (
        <div>
          <section>
            <div className={classes.confirmModal}>
              <form onSubmit={deprecateLabel}>
                <div>
                  <h5 className="title-modal annot-delete">
                    {extractValue(setAnnotation)}
                  </h5>
                  <span className="annot-delete-span">
                    ID : {setAnnotation?.id}
                  </span>
                  <hr />

                  <TextField
                    name="notes"
                    autoComplete="off"
                    placeholder={t("Data.SampleView.annotations.reason_delete")}
                    className="form-control input-pass mb-3 mt-2"
                    value={reasonDeprecation}
                    disabled={loadSampleDetails}
                    onChange={(e) =>
                      setModalEditAnnotation({
                        ...annotationSelectedValuesToEdit,
                        reasonToDeleteUpdate: e.currentTarget.value,
                      })
                    }
                    required
                  />
                </div>

                {!loadSampleDetails ? (
                  <div className="d-flex justify-content-center mt-3 login-container">
                    <Button
                      onClick={resetEdit}
                      variant="outlined"
                      type="submit"
                      className="mr-3"
                    >
                      {t("Common_options.Cancel")}
                    </Button>
                    <Button
                      color="info"
                      variant="contained"
                      type="submit"
                      disabled={
                        reasonDeprecation.length < 4 ||
                        reasonDeprecation.length > 50 ||
                        loadSampleDetails
                      }
                    >
                      {t("Data.SampleView.annotations.delete")}
                    </Button>
                  </div>
                ) : (
                  <LoadingCircularProgress />
                )}
              </form>
            </div>
          </section>
        </div>
      );
    }

    if (
      !updateAnnotation &&
      deleteAnnotation &&
      setAnnotation?.labelTemplate.type.value === imgfileAnnotation
    ) {
      return (
        <div>
          <section>
            <div className={classes.confirmModal}>
              <form onSubmit={deprecateLabel}>
                <div>
                  <h5 className="title-modal annot-delete">
                    {extractValue(setAnnotation)}
                  </h5>
                  <span className="annot-delete-span">
                    ID : {setAnnotation?.id}
                  </span>
                  <hr />

                  <TextField
                    name="notes"
                    autoComplete="off"
                    placeholder={t("Data.SampleView.annotations.reason_delete")}
                    className="form-control input-pass mt-5"
                    value={reasonDeprecation}
                    onChange={(e) =>
                      setModalEditAnnotation({
                        ...annotationSelectedValuesToEdit,
                        reasonToDeleteUpdate: e.currentTarget.value,
                      })
                    }
                    required
                  />
                </div>

                {!loadSampleDetails ? (
                  <div className="d-flex justify-content-center mt-3 login-container">
                    <Button
                      onClick={resetEdit}
                      variant="outlined"
                      type="submit"
                      className="mr-3"
                      disabled={loadSampleDetails}
                    >
                      {t("Common_options.Cancel")}
                    </Button>

                    <Button
                      color="info"
                      variant="contained"
                      type="submit"
                      disabled={
                        reasonDeprecation.length < 4 ||
                        reasonDeprecation.length > 50 ||
                        loadSampleDetails
                      }
                    >
                      {t("Data.SampleView.annotations.delete")}
                    </Button>
                  </div>
                ) : (
                  <LoadingCircularProgress />
                )}
              </form>
            </div>
          </section>
        </div>
      );
    }

    if (updateAnnotation && !deleteAnnotation) {
      const labeInstanceValues = () => {
        if (
          setAnnotation?.labelTemplate?.type?.value === continuousAnnotation
        ) {
          const valueBound = setAnnotation.labelTemplate.versions.map(
            (l) => l.valueBounds
          );

          const minValue = valueBound[0][0];
          const maxValue = valueBound[0][1];

          const labelTranform = labelValue?.replace(/,/g, ".");
          const label = parseFloat(labelTranform);

          return (
            <form onSubmit={editAnnotationAlfa}>
              <h5 className="title-modal annot-update mt-2">
                {setAnnotation?.labelValue}
              </h5>
              <div className="annot-container-edit">
                <span className="annot-update-span mb-2">
                  VALUE: {setAnnotation?.labelValue}
                  <span className="ml-1">{`(${setAnnotation.labelTemplate.unit})`}</span>
                </span>
                <span className="annot-update-span ">
                  ID : {setAnnotation?.id}
                </span>
              </div>

              <hr />
              {label > maxValue && (
                <div className="alert-content-data-annot">
                  <Alert severity="warning" className="">
                    {`${t(
                      "Data.SampleView.annotations.value_less"
                    )} ${maxValue}`}
                  </Alert>
                </div>
              )}
              {label < minValue && (
                <div className="alert-content-data-annot">
                  <Alert severity="warning" className="">
                    {`${t(
                      "Data.SampleView.annotations.value_more"
                    )} ${minValue}`}
                  </Alert>
                </div>
              )}
              <input
                type="text"
                min={minValue}
                max={maxValue}
                defaultValue={minValue}
                className="form-control input-pass mt-5 mb-3"
                title="Please enter a valid decimal number."
                onChange={(e) =>
                  setModalEditAnnotation({
                    ...annotationSelectedValuesToEdit,
                    valueSelectedToUpdate: e.currentTarget.value,
                  })
                }
              />
              <TextField
                name="notes"
                autoComplete="off"
                placeholder={t("Data.SampleView.annotations.reason_update")}
                className="form-control input-pass mt-2 mb-3"
                value={reasonDeprecation}
                onChange={(e) =>
                  setModalEditAnnotation({
                    ...annotationSelectedValuesToEdit,
                    reasonToDeleteUpdate: e.currentTarget.value,
                  })
                }
                required
              />
              {!loadSampleDetails ? (
                <div className="d-flex justify-content-center mt-3 login-container">
                  <Button
                    variant="outlined"
                    type="submit"
                    className="mr-3 cancel-button-annotations"
                    sx={{ width: "100px", height: "37px", marginTop: "15px" }}
                    onClick={resetEdit}
                    disabled={loadSampleDetails}
                  >
                    {t("Common_options.Cancel")}
                  </Button>
                  <Button
                    color="info"
                    variant="contained"
                    type="submit"
                    className="mt-3"
                    disabled={
                      reasonDeprecation.length < 4 ||
                      reasonDeprecation.length > 50 ||
                      !labelValue ||
                      loadSampleDetails ||
                      label < minValue ||
                      label > maxValue
                    }
                  >
                    {t("Common_options.Save")}
                  </Button>
                </div>
              ) : (
                <LoadingCircularProgress />
              )}
            </form>
          );
        }

        if (
          setAnnotation?.labelTemplate?.type?.value === categoricalAnnotation
        ) {
          const subDict: any =
            setAnnotation?.generatedByLabelTemplateConfig?.substitutionDict;
          let defaultValue: string | undefined;

          if (!!subDict) {
            defaultValue = Object.keys(subDict).find(
              (defaultValue: string) => defaultValue === setAnnotation?.rawInput
            );
          }

          let classesValues: string[] | null | undefined | any;

          if (!!subDict) {
            classesValues = Object.keys(subDict)
              .map((classes: string) => {
                if (classes !== defaultValue?.toString()) {
                  return (
                    <option key={`${classes}${uuidv4()}`} value={classes}>
                      {classes}
                    </option>
                  );
                }
                return "";
              })
              .filter((it) => !!it);
          }

          const defaultValueString = defaultValue?.toString();
          return (
            <form onSubmit={editAnnotationAlfa}>
              <h5 className="title-modal annot-update">
                {extractValue(setAnnotation)}
              </h5>
              <div className="annot-container-edit">
                <span className="annot-update-span mb-2">
                  {setAnnotation?.labelValue
                    ? `VALUE: ${setAnnotation?.labelValue} ${setAnnotation.labelTemplate.unit}`
                    : ""}
                </span>
                <span className="annot-update-span ">
                  ID : {setAnnotation?.id}
                </span>
              </div>
              <hr />
              <select
                defaultValue={defaultValueString}
                className="mt-4 form-select"
                onChange={(e) =>
                  setModalEditAnnotation({
                    ...annotationSelectedValuesToEdit,
                    valueSelectedToUpdate: !!subDict
                      ? subDict[e.currentTarget.value]
                      : e.currentTarget.value,
                  })
                }
                required
              >
                <option value={defaultValueString} disabled={true}>
                  {defaultValueString}
                </option>
                {classesValues}
              </select>

              <TextField
                name="notes"
                autoComplete="off"
                placeholder={t("Data.SampleView.annotations.reason_update")}
                className="form-control input-pass mt-3 mb-4"
                value={reasonDeprecation}
                onChange={(e) =>
                  setModalEditAnnotation({
                    ...annotationSelectedValuesToEdit,
                    reasonToDeleteUpdate: e.currentTarget.value,
                  })
                }
                required
              />
              {!loadSampleDetails ? (
                <div className="d-flex justify-content-center mt-3 login-container">
                  <Button
                    size="small"
                    variant="outlined"
                    type="submit"
                    className="mr-3"
                    sx={{ width: "100px", height: "37px", marginTop: "15px" }}
                    disabled={loadSampleDetails}
                    onClick={resetEdit}
                  >
                    {t("Common_options.Cancel")}
                  </Button>
                  <Button
                    color="info"
                    variant="contained"
                    type="submit"
                    className="mt-3"
                    disabled={
                      reasonDeprecation.length < 4 ||
                      reasonDeprecation.length > 50 ||
                      !labelText ||
                      loadSampleDetails
                    }
                  >
                    {t("Common_options.Save")}
                  </Button>
                </div>
              ) : (
                <LoadingCircularProgress />
              )}
            </form>
          );
        }

        if (setAnnotation?.labelTemplate?.type.value === freetextAnnotation) {
          return (
            <form onSubmit={editAnnotationAlfa}>
              <div>
                <h5 className="title-modal annot-update">
                  {setAnnotation?.labelText}
                </h5>
                <span className="annot-update-span">
                  ID : {setAnnotation?.id}
                </span>

                <TextField
                  name="notes"
                  autoComplete="off"
                  placeholder={t("Data.SampleView.annotations.new_info")}
                  className="form-control input-pass mt-5 mb-3"
                  onChange={(e) =>
                    setModalEditAnnotation({
                      ...annotationSelectedValuesToEdit,
                      valueSelectedToUpdate: e.currentTarget.value,
                    })
                  }
                  required
                />
                <TextField
                  name="notes"
                  autoComplete="off"
                  placeholder={t("Data.SampleView.annotations.reason_update")}
                  className="form-control input-pass mb-3 mt-3"
                  value={reasonDeprecation}
                  onChange={(e) =>
                    setModalEditAnnotation({
                      ...annotationSelectedValuesToEdit,
                      reasonToDeleteUpdate: e.currentTarget.value,
                    })
                  }
                  required
                />
              </div>

              {!loadSampleDetails ? (
                <div className="d-flex justify-content-center mt-3 login-container">
                  <Button
                    variant="outlined"
                    type="submit"
                    className="mr-3 cancel-button-annotations"
                    onClick={resetEdit}
                    disabled={loadSampleDetails}
                    sx={{ width: "100px", height: "37px", marginTop: "15px" }}
                  >
                    {t("Common_options.Cancel")}
                  </Button>
                  <Button
                    color="info"
                    variant="contained"
                    type="submit"
                    className="mt-3"
                    disabled={
                      reasonDeprecation.length < 4 ||
                      reasonDeprecation.length > 50 ||
                      loadSampleDetails
                    }
                  >
                    {t("Common_options.Save")}
                  </Button>
                </div>
              ) : (
                <LoadingCircularProgress />
              )}
            </form>
          );
        }
        if (setAnnotation?.labelTemplate?.type.value === imgfileAnnotation) {
          const onChange = (e: React.SyntheticEvent<EventTarget>): void => {
            const fileRef = (e.target as any).files[0];
            setModalEditAnnotation({
              ...annotationSelectedValuesToEdit,
              newFileAnnotationEdit: fileRef,
            });
          };

          const editAnnotationFile = (
            e: React.SyntheticEvent<EventTarget>
          ): void => {
            e.preventDefault();
          };

          return (
            <form onSubmit={editAnnotationFile}>
              <div>
                <h5 className="title-modal annot-update mt-5">
                  {setAnnotation?.labelText}
                </h5>
                <span className="annot-update-span">
                  ID : {setAnnotation?.id}
                </span>

                <hr />

                <TextField
                  name="notes"
                  autoComplete="off"
                  placeholder={t("Data.SampleView.annotations.reason_update")}
                  className="form-control input-pass mt-5"
                  value={reasonDeprecation}
                  onChange={(e) =>
                    setModalEditAnnotation({
                      ...annotationSelectedValuesToEdit,
                      reasonToDeleteUpdate: e.currentTarget.value,
                    })
                  }
                  required
                />

                <input
                  type="file"
                  onChange={onChange}
                  required
                  className="mt-5 mb-2"
                />
              </div>

              {!loadSampleDetails ? (
                <div className="d-flex justify-content-center mt-3 login-container">
                  <Button
                    variant="outlined"
                    type="submit"
                    className="mr-3 cancel-button-annotations"
                    size="small"
                    disabled={loadSampleDetails}
                    onClick={resetEdit}
                    sx={{ width: "100px", height: "37px", marginTop: "15px" }}
                  >
                    {t("Common_options.Cancel")}
                  </Button>
                  <Button
                    color="info"
                    variant="contained"
                    type="submit"
                    className="mt-3"
                    disabled={
                      reasonDeprecation.length < 4 ||
                      reasonDeprecation.length > 50 ||
                      loadSampleDetails ||
                      !newFileAnnotationEdit
                    }
                  >
                    {t("Common_options.Save")}
                  </Button>
                </div>
              ) : (
                <LoadingCircularProgress />
              )}
            </form>
          );
        }
      };
      return (
        <div>
          <div>{labeInstanceValues()}</div>
        </div>
      );
    }
  };

  return (
    <div className={classes.confirmModal}>
      <br />
      {loadSampleDetails ? (
        <LoadingCircularProgress />
      ) : (
        <div>
          <hr />
          {handleEditAnnotation()}
        </div>
      )}
    </div>
  );
};
