import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//Version

import pjson from "../../../package.json";

// IMAGES

import Logo from "../../assets/images/Combined-Shape.png";
import logoInarixLayout from "../../assets/images/logo-inarix-lay.png";

// MUI components

import { inarixColors } from "inarix-theme";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Popover from "@mui/material/Popover";
import MuiDrawer from "@mui/material/Drawer";
import { Button, Tooltip, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, Theme, CSSObject } from "@mui/material/styles";

//@Icons MUI
import MenuIcon from "@mui/icons-material/Menu";
import MapIcon from "@mui/icons-material/Map";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from "@mui/icons-material/People";
import FolderIcon from "@mui/icons-material/Folder";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StayPrimaryPortraitIcon from "@mui/icons-material/StayPrimaryPortrait";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

// Redux
import { State } from "../../redux/slices";
import themeInarix from "../StylesMuiInarix";

// Authentication

// Layout

import {
  setVisible,
  setTabNameLayout,
  setAnchorEl,
} from "../../redux/slices/layout";

import { withRouter } from "../Helpers";
import { DividerLayoutClose, DividerLayoutOpen } from "./LayoutMUIStyles";
import { setSBExtraFiltersVisible } from "../../redux/slices/data/data";
import localforage from "localforage";

const rememberme = localStorage.getItem("rememberme");

type StateProps = {
  title?: string | null;
  component?: any;
};

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    openLayout: () => dispatch(setVisible(true)),
    closeLayout: () => dispatch(setVisible(false)),

    setTabName: (name: string) => dispatch(setTabNameLayout(name)),
    setVisibleSBExtraFilters: (visible: boolean) => {
      dispatch(setSBExtraFiltersVisible(visible));
    },

    setAnchorEl: (setAnchor: boolean) => {
      dispatch(setAnchorEl(setAnchor));
    },

    logoutClickHandler: () => {
      localStorage.removeItem("access_token");

      if (rememberme === "false") {
        localStorage.removeItem("password");
        localStorage.removeItem("username");
      }
      setTimeout(() => {
        //localStorage.removeItem("persist:root");
        localforage.removeItem("persist:root");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 1000);
    },
  };
};

const drawerWidth = 256;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: inarixColors.layout_background,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  background: inarixColors.layout_background,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1.4),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const basicPopover = (
  id: string | undefined,
  open: boolean,
  setAnchorEl: (value: HTMLButtonElement | null) => unknown,
  anchorEl: any,
  t: (arg0: string) => string,
  user: string
) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { logoutClickHandler, closeLayout } = SetActions();

  const removeCookie = () => {
    localStorage.removeItem("user");
  };

  const signoutUniversal = () => {
    closeLayout();
    logoutClickHandler();
    removeCookie();
  };

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Typography sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <span> {`Portal v${pjson.version}`}</span>
        <span className="avatar-options-user">{user}</span>
        <Button variant="text" onClick={signoutUniversal}>
          {t("Login.signout")}
        </Button>
      </Typography>
    </Popover>
  );
};

const Nav: FC<StateProps> = ({ title, component }: StateProps) => {
  const [t] = useTranslation("global");
  const { setTabName, openLayout, closeLayout, setVisibleSBExtraFilters } =
    SetActions();

  const { sectionName, layoutActive, locale } = useSelector(
    (state: State) => state.LayoutReducer
  );

  const { dataExtraFiltersSBVisible } = useSelector(
    (state: State) => state.DataReducer
  );
  const { username, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDrawerOpen = () => {
    openLayout();
  };

  const handleDrawerClose = () => {
    closeLayout();
  };

  const displayingTheGoodName = (url: string) => {
    if (layoutActive) return null;

    switch (url) {
      case "/users":
        return "Fleet_layout.Users_groups";
      case "/dashboard":
        return "Fleet_layout.Dashboard";
      case "/devices":
        return "Fleet_layout.Devices";
      case "/locations":
        return "Fleet_layout.Locations";
      case "/actions":
        return "Fleet_layout.Actions";
      case "/data":
        return "Data.title";
      case "/projects":
        return "Projects.title";
      default:
        return "Fleet_layout.Overview";
    }
  };

  const displayingTheGoodStyle = (layoutActive: boolean) => {
    switch (layoutActive) {
      case true:
        return "sections-fleet-second-links";
      case false:
        return "sections-fleet-second-links-close";
      default:
        return "";
    }
  };

  const displayingTheGoodStyle2 = (layoutActive: boolean) => {
    switch (layoutActive) {
      case true:
        return "sections-fleet";
      case false:
        return "sections-fleet-close";
      default:
        return "";
    }
  };

  const displayTheGoodNameActive = (url: string) => {
    switch (layoutActive) {
      case layoutActive && url === "/users":
        return t("Fleet_layout.Users_groups");

      case layoutActive && url === "/devices":
        return t("Fleet_layout.Devices");

      case layoutActive && url === "/locations":
        return t("Fleet_layout.Locations");

      case layoutActive && url === "/actions":
        return t("Fleet_layout.Actions");

      default:
        return "";
    }
  };

  const displayTheGoodIcon = (url: string) => {
    switch (url) {
      case "/users":
        return <PeopleIcon sx={{ fontSize: "20px" }} />;
      case "/devices":
        return <StayPrimaryPortraitIcon sx={{ fontSize: "20px" }} />;
      case "/locations":
        return <LocationOnIcon sx={{ fontSize: "20px" }} />;
      case "/actions":
        return <FlashOnIcon sx={{ fontSize: "20px" }} />;
      default:
        return "";
    }
  };

  const LinksJustForCommonUserBottom = () => {
    return ["/projects"].map((url) => {
      const correctId = () => {
        switch (url) {
          case "/projects":
            return "projects-icon";
          case "/data":
            return "";
          default:
            return "";
        }
      };

      return (
        <Link
          id={correctId()}
          data-cy={correctId()}
          className={displayingTheGoodStyle(layoutActive)}
          key={url}
          onClick={() => setTabName(url)}
          to={url}
          style={{
            color:
              sectionName === url
                ? inarixColors.main_inarix
                : inarixColors.disabled_button_layout,
          }}
        >
          <Tooltip title={t(displayingTheGoodName(url) as string)} arrow>
            <ListItemIcon
              sx={{
                color:
                  sectionName === url
                    ? inarixColors.main_inarix
                    : inarixColors.disabled_button_layout,
                minWidth: 0,
                mr: layoutActive ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              {url === "/projects" ? (
                <FolderIcon sx={{ fontSize: "20px" }} />
              ) : (
                ""
              )}
            </ListItemIcon>
          </Tooltip>
          {layoutActive && url === "/projects" ? t("Projects.title") : ""}
        </Link>
      );
    });
  };

  const LinksJustForCommonUser = () => {
    return ["/users", "/devices", "/locations"/* , "/actions" */].map((url) => {
      const correctId = () => {
        switch (url) {
          case "/users":
            return "user-groups-icon";
          case "/devices":
            return "devices-icon";
          case "/locations":
            return "locations-icon";
          // case "/actions"
          //   return "actions-icon";
        }
      };
      return (
        <Link
          id={correctId()}
          data-cy={correctId()}
          className={displayingTheGoodStyle2(layoutActive)}
          key={url}
          onClick={() => setTabName(url)}
          to={url}
          style={{
            color:
              sectionName === url
                ? inarixColors.main_inarix
                : inarixColors.disabled_button_layout,
          }}
        >
          <Tooltip title={t(displayingTheGoodName(url) as string)} arrow>
            <ListItemIcon
              sx={{
                color:
                  sectionName === url
                    ? inarixColors.main_inarix
                    : inarixColors.disabled_button_layout,
                minWidth: 0,
                mr: layoutActive ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              {displayTheGoodIcon(url)}
            </ListItemIcon>
          </Tooltip>

          {layoutActive ? displayTheGoodNameActive(url) : ""}
        </Link>
      );
    });
  };

  return (
    <div>
      <ThemeProvider theme={themeInarix(locale)}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer variant="permanent" open={layoutActive}>
            <DrawerHeader>
              {layoutActive ? (
                <img alt="logo" className="logo-layout" src={Logo} />
              ) : (
                ""
              )}
              <IconButton
                onClick={!layoutActive ? handleDrawerOpen : handleDrawerClose}
                sx={{
                  color: inarixColors.disabled_button_layout,
                }}
              >
                {layoutActive ? <KeyboardDoubleArrowLeftIcon /> : <MenuIcon />}
              </IconButton>
            </DrawerHeader>

            <List className={!layoutActive ? "list-links" : "list-links-open"}>
              {["/", "/dashboard"].map((url) => {
                const correctId = () => {
                  switch (url) {
                    case "/dashboard":
                      return "dashboard-icon";
                    case "/dashboard2":
                      return "dashboard-icon-2";
                    case "/dashboard3":
                      return "dashboard-icon-3";
                  }
                };

                return (
                  <Link
                    id={correctId()}
                    data-cy={correctId()}
                    className={
                      !layoutActive ? "sections-fleet-close" : "sections-fleet"
                    }
                    key={url}
                    onClick={() => setTabName(url)}
                    to={url}
                    style={{
                      color:
                        sectionName === url
                          ? inarixColors.main_inarix
                          : inarixColors.disabled_button_layout,
                    }}
                  >
                    <Tooltip
                      title={t(displayingTheGoodName(url) as string)}
                      arrow
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            sectionName === url
                              ? inarixColors.main_inarix
                              : inarixColors.disabled_button_layout,
                          minWidth: 0,
                          mr: layoutActive ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {url === "/" ? (
                          <MapIcon sx={{ fontSize: "20px" }} />
                        ) : (
                          ""
                        )}
                        {url === "/dashboard" ? (
                          <BarChartIcon sx={{ fontSize: "20px" }} />
                        ) : (
                          ""
                        )}
                      </ListItemIcon>
                    </Tooltip>

                    {layoutActive && url === "/"
                      ? t("Fleet_layout.Overview")
                      : ""}

                    {layoutActive && url === "/dashboard"
                      ? t("Fleet_layout.Dashboard")
                      : ""}
                  </Link>
                );
              })}
              {!isViewerOnly ? LinksJustForCommonUser() : ""}
            </List>

            <List
              className={!layoutActive ? "second-links" : "second-links-open"}
            >
              {["/data"]?.map((url) => {
                const correctId = () => {
                  switch (url) {
                    case "/data":
                      return "data-icon";
                    case "/data2":
                      return "data-icon-2";
                    case "/data3":
                      return "data-icon-3";
                  }
                };
                return (
                  <Link
                    className={
                      !layoutActive
                        ? "sections-fleet-second-links-close"
                        : "sections-fleet-second-links"
                    }
                    data-cy={correctId()}
                    key={url}
                    onClick={() => setTabName(url)}
                    to={url}
                    style={{
                      color:
                        sectionName === url
                          ? inarixColors.main_inarix
                          : inarixColors.disabled_button_layout,
                    }}
                  >
                    <Tooltip
                      title={t(displayingTheGoodName(url) as string)}
                      arrow
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            sectionName === url
                              ? inarixColors.main_inarix
                              : inarixColors.disabled_button_layout,
                          minWidth: 0,
                          mr: layoutActive ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {url === "/data" ? (
                          <TableChartIcon sx={{ fontSize: "20px" }} />
                        ) : (
                          ""
                        )}
                      </ListItemIcon>
                    </Tooltip>
                    {layoutActive && url === "/data" ? t("Data.title") : ""}
                  </Link>
                );
              })}
              {!isViewerOnly ? LinksJustForCommonUserBottom() : ""}
              {!layoutActive ? <DividerLayoutClose /> : <DividerLayoutOpen />}

              <div
                onClick={(e: any) => handleClick(e)}
                role="none"
                className={
                  !layoutActive ? "account-button" : "account-button-open"
                }
              >
                <AccountCircleIcon
                  color="action"
                  sx={{
                    color: inarixColors.disabled_button_layout,
                    fontSize: "20px",
                  }}
                />
                {layoutActive ? (
                  <span className="span-account">
                    {t("Fleet_layout.Account")}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              paddingLeft: !layoutActive ? "70px" : "263px",
              background: inarixColors.background_Components,
              WebkitBoxFlex: 1,
              WebkitFlexGrow: 1,
              position: "absolute",
              height: "-webkit-fill-available",
              width: "-webkit-fill-available",
            }}
          >
            <h1
              className={title ? "title-section-styles" : ""}
              role="none"
              onClick={
                dataExtraFiltersSBVisible
                  ? () => setVisibleSBExtraFilters(false)
                  : () => {}
              }
            >
              {title ? (
                <img
                  src={logoInarixLayout}
                  data-cy="logo-inarix-layout"
                  className="img-layout-log"
                  alt="logoInarixLayout"
                />
              ) : (
                ""
              )}
              {title}
            </h1>
            {component}
          </Box>
        </Box>
      </ThemeProvider>
      {basicPopover(id, open, setAnchorEl, anchorEl, t, username)}
    </div>
  );
};

export default withRouter(Nav);
