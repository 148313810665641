import { ThunkDispatch } from "@reduxjs/toolkit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateLocationStep,
  setCreateLocationStep,
} from "../../../redux/slices/location/createLocation";
import { State } from "../../../redux/slices";
import { Button } from "@mui/material";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
  };
};

export const StepTwoMultiLocations: FC<any> = () => {
  const [t] = useTranslation("global");
  const { setSteps } = SetActions();
  const { createLocationStep } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Common_options.step")} 2/3</div>
      <div>
        <div className="step-title-create">
          {t("Create_multi_location.step_two.title")}
        </div>

        <div className="tips-container">
          <div className="title-inarix-tips">
            {t("Create_multi_location.step_two.tips")}
          </div>
          <div className="titles-tips-csv">
            {t("Create_multi_location.step_two.tip_one_title")}
          </div>
          <div className="subtitles-tips-csv spacer-csv-tips ">
            {t("Create_multi_location.step_two.tip_one_desc")}
          </div>

          <div className="titles-tips-csv">
            {t("Create_multi_location.step_two.tip_two_title")}
          </div>
          <div className="subtitles-tips-csv">
            {t("Create_multi_location.step_two.tip_two_desc_one")}
          </div>
          <div className="subtitles-tips-csv spacer-csv-tips ">
            {t("Create_multi_location.step_two.tip_two_desc_two")}
          </div>

          <div className="titles-tips-csv">
            {t("Create_multi_location.step_two.tip_three_title")}
          </div>
          <div className="subtitles-tips-csv">
            {t("Create_multi_location.step_two.latitude_validation")}
          </div>
          <div className="subtitles-tips-csv spacer-csv-tips ">
            {t("Create_multi_location.step_two.longitude_validation")}
          </div>

          <div className="titles-tips-csv mb-1">
            {t("Create_multi_location.step_two.example_desc")}
          </div>

          <div className="table-csv-example">
            <div className="cells">
              <div className="cell-option-csv">
                {t("Create_multi_location.step_two.site_name")}
              </div>
              <div className="cell-option-csv">Marseille-1</div>
              <div className="cell-option-csv">Paris-2</div>
            </div>
            <div className="cells">
              <div className="cell-option-csv">
                {t("Create_multi_location.step_two.latitude")}
              </div>
              <div className="cell-option-csv">43.294219</div>
              <div className="cell-option-csv">48.853928</div>
            </div>
            <div className="cells">
              <div className="cell-option-csv">
                {t("Create_multi_location.step_two.longitude")}
              </div>
              <div className="cell-option-csv">5.37722</div>
              <div className="cell-option-csv">2.348347</div>
            </div>
          </div>
        </div>

        <Button
          variant="contained"
          onClick={() => setSteps({ ...createLocationStep, step: 3 })}
          sx={{ textTransform: "none" }}
        >
          {t("Create_site.stepone.Continue")}
        </Button>
      </div>
    </div>
  );
};
