import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../layout/Layout";
import { useTranslation } from "react-i18next";
import { UserLocationsTable } from "./UserLocationTable";
import { State } from "../../redux/slices";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { setNumberRowsUsers } from "../../redux/slices/fleet/users";
import { GridPaginationModel } from "@mui/x-data-grid";
import { Modal } from "@mui/material";
import {
  cleanUserLocationMessage,
  initialState,
  setCreatedGroupValues,
  setModalLocations,
  setNewValuesUpdateLocation,
  setSelectLocations,
} from "../../redux/slices/fleet/locations";
import { errorsGeneralModal } from "../ErrorModal";
import { fetchLocationsWithActivity } from "../../redux/actions/map/mapAsyncActions";
import { setTabNameLayout } from "../../redux/slices/layout";
import NotFound from "../NotFound";
import { LocationsLayout } from "./LocationsLayout";
import {
  createLocationGroup,
  fetchLocationsGroups,
} from "../../redux/actions/locations/locationsAsyncActions";
import { SnackBarAddCreate } from "../Helpers";
import { BodyModalAddGroups } from "./modals/AddToGroup";
import {
  groupsCreatedSnackBar,
  usersUpdateSnackBar,
} from "./multilocation/InarixTips";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    locationsInit: (token: string) => {
      dispatch(fetchLocationsWithActivity(token));
      dispatch(fetchLocationsGroups(token));
      dispatch(setTabNameLayout("/locations"));
      dispatch(setNewValuesUpdateLocation(initialState.editLocation));
      dispatch(setModalLocations(false));
      dispatch(cleanUserLocationMessage());
    },

    createNewGroup: (token: string) => {
      dispatch(createLocationGroup({ token }));
    },
    deleteLocations: (token: string) => {
      console.log("delete`");
    },
    closeSnackBar: () => {
      dispatch(setSelectLocations([]));
    },

    setCreatedGroupValues: (values: any) => {
      dispatch(setCreatedGroupValues(values));
    },
    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),
    disableError: () => dispatch(cleanUserLocationMessage()),

    cancelAddGroup: () => {
      dispatch(setCreatedGroupValues(initialState.createdGroups));
      dispatch(setSelectLocations([]));
    },
  };
};

const UserLocations: FC = () => {
  const [t] = useTranslation("global");
  const {
    disableError,
    locationsInit,
    deleteLocations,
    setCreatedGroupValues,
    cancelAddGroup,
  } = SetActions();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );

  const {
    error,

    createdGroups,
  } = useSelector((state: State) => state.LocationsReducer);

  const token = user?.token ?? "";
  const bodyAddGroup = BodyModalAddGroups(t, token);
  const bodyErrors = errorsGeneralModal(JSON.stringify(error), disableError, t);

  const locationsSelectedB =
    createdGroups.locationsSelectedAutoComplete.valuesSelected.map((d) => d.id);

  const prepareToCreateGroup = () => {
    setCreatedGroupValues({
      ...createdGroups,
      isCreatingGroup: true,
      typeOfGroup: "add",
    });
  };

  const deleteLocation = () => {
    deleteLocations(token);
  };

  if (createdGroups.isCreatedGroupSuccess) {
    setTimeout(() => {
      setCreatedGroupValues({ ...createdGroups, isCreatedGroupSuccess: false });
    }, 2000);
  }

  useEffect(() => {
    locationsInit(token);
  }, []);

  const globalComponentsUserLocations = () => (
    <>
      <div className="container-locations-section-global">
        {LocationsLayout(t)}
        {UserLocationsTable(t)}
        <Modal open={!!error}>{bodyErrors}</Modal>
      </div>
      {!createdGroups.isCreatingGroup
        ? SnackBarAddCreate(
            locationsSelectedB.length >= 1 ? true : false,
            locationsSelectedB,
            t,
            t("Fleet_actions.table.Add_to_group"),
            t("Common_options.sites"),
            prepareToCreateGroup,
            deleteLocation,
            cancelAddGroup
          )
        : ""}
      <Modal open={createdGroups.isCreatingGroup} onClose={cancelAddGroup}>
        {bodyAddGroup}
      </Modal>

      {createdGroups.typeOfGroup === "new"
        ? groupsCreatedSnackBar(1, t, createdGroups.isCreatedGroupSuccess)
        : usersUpdateSnackBar(createdGroups.isCreatedGroupSuccess, t)}
    </>
  );

  return (
    <Layout
      title={!isViewerOnly ? "" : ""}
      component={!isViewerOnly ? globalComponentsUserLocations() : <NotFound />}
    />
  );
};

export default UserLocations;
