import axios from "axios";
import { API } from "../../../config";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";

import { setSelectionModelUsers } from "../../slices/fleet/users";
import { LocalizedTextGrouping, Method, Scopes, UserData } from "./userTypes";
import { setFiltersConfig } from "../../slices/userGroups/userGroupsSliceMultipleTables";
import { addHours, setZeroHrs } from "../dashboard/dashboardAsyncActions";
import { moveScenarioToGroup } from "../actions/actionsAsyncActions";
import {
  resetStateCreateUsers,
  setCreateManagerCreationError,
  setCreateManagerCreationErrorShow,
  setCreateManagerValues,
} from "../../slices/users/createUsers";
import { getLocalizedTextByLocaleVersionTwo } from "../../../utils/localized-text";
import {
  generatePassword,
  moveSiteTogroup2,
} from "../locations/locationsAsyncActions";

export const fetchScenarios = async (
  token: string,
  orgId: number,
  locale: string
): Promise<any> => {
  const res = await axios({
    method: "GET",
    url: `${API}/core/scenario?isSystemScenario=false&deletedAt=null&statusConstantId[$in][]=34&statusConstantId[$in][]=35&$eager=[scopes.[group], cerealType.text.[localizedTexts.[locale]],statusConstant, name.[localizedTexts.[locale]], desc.[localizedTexts.[locale]]]&$modifyEager[scopes.group][orgId]=${orgId}`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const fetchManagerGeopoints = async (
  token: string,
  groupsId: number[],
  locale: string
): Promise<any> => {
  const queryString = groupsId.map((id) => `groupId[$in][]=${id}`).join("&");
  const res = await axios({
    method: "GET",
    url: `${API}/users/organization-group-membership?${queryString}&deletedAt=null&geoPointId[$ne]=null`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const fetchUsersNewRequest = createAsyncThunk(
  "UsersReducer/fetchUsersNewRequest",
  async (token: string, store) => {
    const { orgId } = (store.getState() as RootState).Authentication;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { locations } = (store.getState() as RootState).MapReducer;
    const { usersSBFilters } = (store.getState() as RootState)
      .CreateTablesUsersReducer;

    const { filtersRoles, filtersLocation, filterActions, filterCreationDate } =
      usersSBFilters;

    const locationFilter = usersSBFilters.locations.valuesSelected.map(
      (value) => `associatedGeoPointId[$in][]=${value.id}&`
    );

    const dateFilter = usersSBFilters.CreationDateSelected.filter(
      (value) => value.isSelected === true
    ).map((x) => `createdAt[$gte]=${addHours(setZeroHrs(x.id)).toISOString()}`);

    const last_dateFilter = dateFilter[dateFilter.length - 1];

    const queryParams = [
      "$eager=[groups,associatedGeoPoint]",
      "$modifyEager[groups.deletedAt]=null",
      locationFilter,
      last_dateFilter === undefined ? "" : last_dateFilter,
    ].join("&");

    const filtersCount = [
      filtersRoles,
      filtersLocation,
      filterActions,
      filterCreationDate,
    ];

    const filtersCounts2 = filtersCount.filter(function (element) {
      return element !== undefined;
    });

    const myNums = filtersCounts2.map((d: any) => d?.value.length);
    let sum = 0;
    myNums.forEach((num) => {
      sum += num;
    });

    store.dispatch(
      setFiltersConfig({
        ...usersSBFilters,
        filtersSelectedAfterFetch: sum,
      })
    );

    const dataUser = await fetchScenarios(token, orgId as number, locale);

    try {
      const response = await axios.get(`${API}/users/user?${queryParams}`, {
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;

      const managerUsers = data.data.filter(
        (users: any) => users.userType === "real"
      );

      const managerLocations = await Promise.all(
        managerUsers.map((u: any) =>
          fetchManagerGeopoints(
            token,
            u.groups.map((g: any) => g.id),
            locale
          )
        )
      );

      const myManagerLocationsIds = managerLocations.map((c) =>
        c.total ? [...new Set(c.data.map((x: any) => x.geoPointId))] : []
      ) as any[];

      managerUsers.forEach((arr: any, index: number) => {
        arr.associatedGeoPoint = myManagerLocationsIds.find(
          (a: number[], i: number) => i === index
        );
      });

      managerUsers.forEach((element: any, index: number) => {
        element.associatedGeoPoint = locations.filter((f) =>
          element.associatedGeoPoint.includes(f.id)
        );
      });

      const newUsersWithActions = () => {
        if (dataUser.data.length >= 1) {
          const newActionObject = dataUser?.data?.map(
            (x: {
              name: LocalizedTextGrouping;
              scopes: Scopes[];
              id: number;
            }) => ({
              actionId: x.id,
              name: x.name,
              scopes: x.scopes.filter((e: any) => e.deletedAt === null),
            })
          );

          const scenarios = newActionObject?.filter(
            (x: { name: LocalizedTextGrouping; scopes: Scopes[] }) =>
              x.name !== null
          ) as Method[];

          const transformData = {
            total: data.total,
            limit: data.limit,
            skip: data.skip,
            data: data.data.map((user: UserData) => ({
              createdAt: user.createdAt,
              updatedAt: user.updatedAt,
              id: user.id,
              username: user.username,
              email: user.email,
              emailVerifiedAt: user.emailVerifiedAt,
              firstname: user.firstname,
              lastname: user.lastname,
              lastAllotedDevice: user.lastAllotedDevice,
              deletedAt: user.deletedAt,
              isSuperAdmin: user.isSuperAdmin,
              userType: user.userType,
              associatedGeoPointId: user,
              remoteId: user.remoteId,
              metadata: user.metadata,
              attributeId: user.attributeId,
              associatedGeoPoint: Array.isArray(user?.associatedGeoPoint)
                ? user?.associatedGeoPoint
                : [user?.associatedGeoPoint],
              groups: user.groups.filter(
                (c) => c.typeId === 107 || c.typeId === 112
              ),
              actions: scenarios
                ?.map((s: any) =>
                  s.scopes
                    .map((x: any) => {
                      if (user.groups[0]?.id === x.group?.id) {
                        return s.name;
                      }
                    })
                    .filter(function (element: any) {
                      return element !== undefined;
                    })
                )
                .flat(),

              actionsIds: scenarios
                ?.map((s: any) =>
                  s.scopes
                    .map((x: any) => {
                      if (user.groups[0]?.id === x.group?.id) {
                        return s.actionId;
                      }
                    })
                    .filter(function (element: any) {
                      return element !== undefined;
                    })
                )
                .flat(),

              scopesIds: scenarios
                ?.map((s: any) =>
                  s.scopes
                    .map((x: any) => {
                      if (user.groups[0]?.id === x.group?.id) {
                        return {
                          scopeId: x.id,
                          scenarioId: x.scenarioId,
                          name: s.name,
                        };
                      }
                    })
                    .filter(function (element: any) {
                      return element !== undefined;
                    })
                )
                .flat(),
            })),
          };
          return transformData;
        }
        return [];
      };

      return store.fulfillWithValue(newUsersWithActions() as any);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchGroups = createAsyncThunk(
  "UsersReducer/fetchGroups",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(`${API}/users/organization-group`, {
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;

      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const moveTogroup = async (
  token: string,
  userId: string,
  groupId: number,
  locale: string
): Promise<UserData> => {
  const res = await axios({
    method: "POST",
    url: `${API}/users/group-membership`,
    data: { userId: userId, groupId: groupId },
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const moveAllGroup = createAsyncThunk(
  "UsersReducer/moveAllGroup",
  async (token: string, store) => {
    const { usersSelected, groupId } = (store.getState() as RootState)
      .UsersReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const userPromises = usersSelected?.map((elt: string) => {
        const convert = elt.toString();
        return moveTogroup(token, convert, groupId, locale);
      });
      const move = await Promise.all(userPromises);

      store.fulfillWithValue(move);
      store.dispatch(fetchGroups(token));
      store.dispatch(fetchUsersNewRequest(token));
      store.dispatch(setSelectionModelUsers([]));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteSingleUserFromGroup = async (
  token: string,
  userId: string,
  groupId: number
): Promise<UserData> => {
  const res = await axios({
    method: "DELETE",
    url: `${API}/users/group-membership?userId=${userId}&groupId=${groupId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const deleteUserFromGroup = createAsyncThunk(
  "UsersReducer/deleteUserFromGroup",
  async (arg: { token: string; userId: string; groupId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.delete(
        `${API}/users/group-membership?userId=${arg.userId}&groupId=${arg.groupId}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${arg.token}`,
          },
        }
      );

      store.fulfillWithValue(response);
      store.dispatch(fetchGroups(arg.token));
      store.dispatch(fetchUsersNewRequest(arg.token));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const deleteMultipleUsersFromGroup = createAsyncThunk(
  "UsersReducer/deleteMultipleUsersFromGroup",
  async (token: string, store) => {
    const { usersSelected, groupId } = (store.getState() as RootState)
      .UsersReducer;

    try {
      const userPromises = usersSelected.map((elt: string) => {
        const convert = elt.toString();
        return deleteSingleUserFromGroup(token, convert, groupId);
      });
      const remove = await Promise.all(userPromises);

      store.fulfillWithValue(remove);
      store.dispatch(fetchGroups(token));
      store.dispatch(fetchUsersNewRequest(token));
      store.dispatch(setSelectionModelUsers([]));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const moveRemoveAddLocToUser = async (
  token: string,
  userId: string,
  geoPointId: number,
  method: string,
  locale: string
): Promise<UserData> => {
  const dataInfo = () => {
    if (method === "addLoc" || method === "editLoc") {
      return geoPointId;
    }
    if (method === "removeLoc") {
      return null;
    }
  };

  const res = await axios({
    method: "PATCH",
    url: `${API}/users/user/${userId}`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      associatedGeoPointId: dataInfo(),
    },
  });

  const { data } = res;
  return data;
};

export const modifyAllUserToLoc = createAsyncThunk(
  "UserReducer/modifyAllUserToLoc",
  async (token: string, store) => {
    const { usersSelected, groupId, optionToModify } = (
      store.getState() as RootState
    ).UsersReducer;

    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const userPromises = usersSelected.map((elt: string) => {
        const convert = elt.toString();

        return moveRemoveAddLocToUser(
          token,
          convert,
          groupId,
          optionToModify,
          locale
        );
      });
      const move = await Promise.all(userPromises);

      store.fulfillWithValue(move);
      store.dispatch(fetchGroups(token));
      store.dispatch(fetchUsersNewRequest(token));
      store.dispatch(setSelectionModelUsers([]));
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const userProfiles = createAsyncThunk(
  "CreateUsersReducer/userProfiles",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `${API}/users/user-facing-group-template?$eager=[name.[localizedTexts.locale], desc.[localizedTexts.locale]]`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return store.fulfillWithValue(response.data.data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const groupTemplateLooksLike = createAsyncThunk(
  "CreateUsersReducer/groupTemplateLooksLike",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `${API}/users/organization-group-template `,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return store.fulfillWithValue(response.data.data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const userUpdateValues = createAsyncThunk(
  "CreateTablesUsersReducer/userUpdateValues",
  async (arg: { token: string; userId: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { userValuesUpdate, selectedUser } = (store.getState() as RootState)
      .CreateTablesUsersReducer;
    const { site, contactEmail } = userValuesUpdate;
    const { groups } = selectedUser;

    const copy = userValuesUpdate?.copyOfActions?.valuesSelected;
    const values = userValuesUpdate?.actions?.valuesSelected;

    const actionWithNoScope = values
      ?.map((x) => {
        if (x.scopeId === undefined) {
          return x;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    const actionAlreadyUsed = copy
      ?.map((x) => {
        if (actionWithNoScope.find((d) => d?.id === x.id)) {
          return x;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    const actionsToAdd = actionWithNoScope
      .map((non) => {
        if (actionAlreadyUsed.find((d) => d?.id === non?.id)) {
          return undefined;
        } else {
          return non;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    try {
      const response = await axios({
        method: "PATCH",
        url: `${API}/users/user/${arg.userId}`,
        headers: {
          "Accept-Language": `${locale}`,
          Authorization: `Bearer ${arg.token}`,
        },
        data: {
          associatedGeoPointId: site ? site : undefined,
          email: contactEmail ? contactEmail : undefined,
        },
      });

      const actionToDeletePromises = copy
        ?.map((copys) => {
          if (!values.find((x) => x.id === copys.id)) {
            return deleteScenarioFromUser(arg.token, copys.scopeId, locale);
          }
        })
        .filter(function (element) {
          return element !== undefined;
        });

      const moveActionsToUserGroupPromises = actionsToAdd
        .map((x) => {
          if (x?.scopeId === undefined) {
            return moveScenarioToGroup(
              arg.token,
              x?.scenarioId as number,
              groups[0].id,
              locale
            );
          }
        })
        .filter(function (element) {
          return element !== undefined;
        });

      await Promise.all(actionToDeletePromises);
      await Promise.all(moveActionsToUserGroupPromises);

      store.dispatch(fetchUsersNewRequest(arg.token));
      return store.fulfillWithValue(response);
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const deleteScenarioFromUser = async (
  token: string,
  scopeId: string,
  locale: string
): Promise<UserData> => {
  const res = await axios({
    method: "DELETE",
    url: `${API}/core/scenario-scope/${scopeId}`,
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });

  const { data } = res;
  return data;
};

export const userManagerCreation = createAsyncThunk(
  "CreateLocationReducer/userManagerCreation",
  async (
    arg: {
      token: string;
      username: string;
      password: string;
      associatedGeoPointId: number;
      role: number;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/register`,
        {
          username: arg.username,
          password: arg.password,
          userType: "real",
          email: arg.username,
          remoteId: "",
          firstname: "",
          lastname: "",
          associatedGeoPointId: arg.associatedGeoPointId,
        },
        { headers }
      );

      const { data } = response;
      return data;
    } catch (error: any) {
      store.dispatch(setCreateManagerCreationErrorShow(true));
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//// manager creation

export const checkGroupsInUser = async (
  token: string,
  userId: string
): Promise<any> => {
  const res = await axios({
    method: "GET",
    url: `${API}/users/organization-group-membership?$eager=[group]&userId=${userId}&deletedAt=null `,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  const filteringIds = data?.data
    ?.filter((group: any) => group.group.groupTemplateId !== null)
    .map((result: any) => result.group.groupTemplateId);

  return filteringIds;
};

export const findTheGroup = async (
  token: string,
  orgId: number,
  missingGroupTemplateId: number[]
): Promise<any> => {
  const queryString = missingGroupTemplateId
    .map((id) => `groupTemplateId[$in][]=${id}`)
    .join("&");
  const res = await axios({
    method: "GET",
    url: `${API}/users/organization-group?$eager=[groupTemplate]&orgId=${orgId}&${queryString}&deletedAt=null`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const createManagerRole = async (
  token: string,
  userId: string,
  groupId: number,
  membershipType: number
): Promise<any> => {
  const res = await axios({
    method: "POST",
    url: `${API}/users/organization-group-membership`,
    data: {
      userId,
      groupId,
      membershipType,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const createManagerGroup = async (
  token: string,
  orgName: string,
  templateName: string,
  username: string,
  defaultTypeId: number,
  orgId: number,
  missingGroupTemplateId: number
): Promise<any> => {
  const res = await axios({
    method: "POST",
    url: `${API}/users/organization-group`,
    data: {
      name: `${orgName} ${templateName} ${username}`,
      typeId: defaultTypeId,
      orgId,
      isAutoGenerated: true,
      groupTemplateId: missingGroupTemplateId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;

  return data;
};

export const createMultiManagerUsersWithActions = createAsyncThunk(
  "CreateTablesUsersReducer/createMultiManagerUsersWithActions",
  async (token: string, store) => {
    const {
      managerUsersConfigRows,
      createManagers,
      userRoles,
      groupTemplateRaw,
    } = (store.getState() as RootState).CreateUsersReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { orgId, companyName } = (store.getState() as RootState)
      .Authentication;
    store.dispatch(
      setCreateManagerValues({ ...createManagers, createLoading: true })
    );

    try {
      const usersPromises = managerUsersConfigRows.map((usersConfigs) => {
        return store.dispatch(
          userManagerCreation({
            token: token,
            username: usersConfigs.email,
            password: generatePassword(),
            associatedGeoPointId: null as any,
            role: usersConfigs.role as number,
          })
        );
      });

      const createdUsers = await Promise.all(usersPromises);
      const res = createdUsers.map((v) => v.payload);

      store.dispatch(setCreateManagerCreationError(res));

      const userWithTableInformation = res.reduce((arr: any, e: any) => {
        arr.push(
          Object.assign(
            {},
            e, //current object
            {
              informationFromTable: managerUsersConfigRows.find(
                (a) => a.email === e.email
              ),
            }
          )
        );
        return arr;
      }, []);

      const arrayWithRoleSelected = userWithTableInformation.map(
        (user: any, index: number) => ({
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
          username: user.username,
          userType: user.userType,
          email: user.email,
          remoteId: user.remoteId,
          firstname: user.firstname,
          lastname: user.lastname,
          id: user.id,
          emailVerifiedAt: user.emailVerifiedAt,
          lastAllotedDevice: user.lastAllotedDevice,
          deletedAt: user.deletedAt,
          isSuperAdmin: user.isSuperAdmin,
          attributeId: user.attributeId,
          associatedGeoPointId: user.associatedGeoPointId,
          metadata: user.metadata,
          group: user.group,
          roletypeId: user.informationFromTable.role,
          role: userRoles.filter((x: any) => {
            if (x.id === user.informationFromTable.role) {
              return x.groupTemplateIds;
            } else {
              return null;
            }
          })[0].groupTemplateIds,

          roleName: getLocalizedTextByLocaleVersionTwo(
            locale,
            userRoles.filter((x: any) => {
              if (x.id === user.informationFromTable.role) {
                return x.groupTemplateIds;
              } else {
                return null;
              }
            })[0].name.localizedTexts
          ),

          groupsSelected: user.informationFromTable.groupsSelected,
          siteWithoutGroup: user.informationFromTable.assignedSite.filter(
            (f: number) =>
              !user.informationFromTable.groupsLocationsSelectedIds.includes(f)
          ),
          informationFromTable: {
            id: user.informationFromTable.id,
            email: user.informationFromTable.email,
            role: 4,
            assignedSite: user.informationFromTable.assignedSite,
            assignedGroups: user.informationFromTable.assignedGroups,

            isNew: user.informationFromTable.isNew,
          },
        })
      );

      if (res.length >= 1) {
        const checkGroupsPromises = arrayWithRoleSelected.map(
          (newManagerUsers: any) => {
            return checkGroupsInUser(token, newManagerUsers.id);
          }
        );

        const actualRoles = await Promise.all(checkGroupsPromises);
        const missingId = arrayWithRoleSelected.map((s: any) =>
          s.role.filter((s: number) => !actualRoles.flat().includes(s))
        );

        const findTheGroups = missingId.map((missingGroupTemplateId: any) => {
          return findTheGroup(token, orgId as number, missingGroupTemplateId);
        });

        const whatGroups = await Promise.all(findTheGroups);

        const toConvertInManager = arrayWithRoleSelected.reduce(
          (arr: any, e: any, index: number) => {
            arr.push(
              Object.assign(
                {},
                e, //current object
                {
                  assignedGroupViaIndex: whatGroups.find(
                    (a, i) =>
                      i === index &&
                      missingId
                        .find((a: number[], i: number) => i === index)
                        .flat()[0] === 28
                  )?.data[0],
                },
                {
                  isMissingId: missingId
                    .find((a: number[], i: number) => i === index)
                    .flat(),
                },
                {
                  groupTemplateLooksLike: groupTemplateRaw.filter(
                    (template) =>
                      template.id ===
                      missingId
                        .find((a: number[], i: number) => i === index)
                        .flat()[0]
                  ),
                }
              )
            );
            return arr;
          },
          []
        );

        const resultsPromises = toConvertInManager.map(async (info: any) => {
          // if user Selected backoffice manager
          if (info.assignedGroupViaIndex !== undefined) {
            console.log("groupsSelected 28");
            return createManagerRole(
              token,
              info.id,
              info.assignedGroupViaIndex.id,
              info.assignedGroupViaIndex.groupTemplate.defaultMembershipType
            );
          } else {
            // if user Selected another kind of manager
            if (info.groupsSelected.length >= 1) {
              const managerRoles = info.groupsSelected.map((group: any) =>
                createManagerRole(
                  token,
                  info.id,
                  group.id,
                  groupTemplateRaw.find((f) => f.id === group.groupTemplateId)
                    ?.defaultMembershipType as number
                )
              );

              const managerRolesCreated = await Promise.all(managerRoles);
              console.log(managerRolesCreated, "groupsSelected finish");
            }

            if (info.siteWithoutGroup.length >= 1) {
              const group = await createManagerGroup(
                token,
                companyName as string,
                info.roleName,
                info.username,
                info.groupTemplateLooksLike[0].defaultTypeId,
                orgId as number,
                info.groupTemplateLooksLike[0].id
              );

              if (group.id) {
                const managerRoles = await createManagerRole(
                  token,
                  info.id,
                  group.id,
                  groupTemplateRaw.filter(
                    (rawGroup) => rawGroup.id === group.groupTemplateId
                  )[0].defaultMembershipType
                );

                if (managerRoles) {
                  const sitesToAttach = info.siteWithoutGroup.map(
                    (sites: number) => {
                      console.log("manage roles sites to attach");
                      return moveSiteTogroup2(
                        token,
                        group.id,
                        sites,
                        locale,
                        0
                      );
                    }
                  );

                  console.log(sitesToAttach, "siteWithoutGroup finish");
                  await Promise.all(sitesToAttach);
                }
              }
            }
          }
        });

        const done = await Promise.all(resultsPromises);
        store.dispatch(
          setCreateManagerValues({
            ...createManagers,
            createSuccess: createdUsers.length >= 1 ? true : false,
            createLoading: false,
            usersManagersCreated: createdUsers.length,
          })
        );

        setTimeout(() => {
          if (createdUsers.length >= 1) {
            store.dispatch(resetStateCreateUsers());
          } else {
            return;
          }
        }, 3000);
        return done;
      }
    } catch (error: any) {
      store.dispatch(
        setCreateManagerValues({
          ...createManagers,
          createLoading: false,
          createError: error?.response?.data?.message,
        })
      );
    }
  }
);
