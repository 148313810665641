import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { inarixColors } from "inarix-theme";
import { DColumnsOverview } from "../../redux/actions/data/DataDeclarations";
import { setFilterFromLocToUserTabs } from "../../redux/slices/fleet/users";
import {
  setLoadingUserLoc,
  setModalLocations,
  setUserLocationId,
  setUserLocationName,
} from "../../redux/slices/fleet/locations";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import EditIcon from "@mui/icons-material/Edit";
import { OrgGeoPointData } from "../../redux/actions/locations/locationsTypes";
import { State } from "../../redux/slices";
import { v4 as uuidv4 } from "uuid";

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    setLocationId: (id: number, name: string) => {
      dispatch(setUserLocationId(id));
      dispatch(setUserLocationName(name));
      dispatch(setModalLocations(true));
    },

    setLocFilter: (location: string, navigate: any) => {
      dispatch(setFilterFromLocToUserTabs(location));
      dispatch(setLoadingUserLoc(true));
      setTimeout(() => {
        dispatch(setLoadingUserLoc(false));
        navigate(`/users`);
      }, 1000);
    },
  };
};

const UserLocationsColumns = (t: (arg0: string) => string) => {
  const navigate = useNavigate();
  const { setLocationId, setLocFilter } = SetActions();
  const { locations, selectedLocationsToShow } = useSelector(
    (state: State) => state.LocationsReducer
  );

  const locationsToShow = locations.filter((loc) =>
    selectedLocationsToShow.includes(loc.id)
  );

  const columnNames = {
    name: t("Fleet_users_locations.table.Location_name"),
    id: t("Fleet_users_locations.table.Location_inarix_id"),
    longitude: t("Fleet_users_locations.table.Longitude"),
    latitude: t("Fleet_users_locations.table.Latitude"),
    location: t("Common_options.loc"),
    group: t("Users_managers.groups"),
    idParams: "",
  };

  const rawColumns = [
    {
      columnField: "name",
      sortable: true,
    },

    {
      columnField: "location",
      sortable: true,
      align: "center",
    },
    {
      columnField: "group",
      sortable: true,
      align: "center",
    },
  ];

  let columns: GridColDef[] = rawColumns.map((column: DColumnsOverview) => {
    if (column.columnField === "name") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 250,
        sortable: false,
      };
    }

    if (column.columnField === "group") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        width: 250,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const list = params.value;
          if (list.length === 1) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {list.map((value: string) => (
                  <Chip variant="outlined" key={value} label={value} />
                ))}
              </Box>
            );
          }

          if (list.length > 1) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        {t("Users_managers.groups")}
                      </Typography>
                      <ul>
                        {list.map((l: string) => (
                          <li key={`${l}${uuidv4()}`}>{l}</li>
                        ))}
                      </ul>
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <Chip
                    variant="outlined"
                    label={`${list[0]} , ${list[1]}, ${
                      list.length >= 3
                        ? `+ ${list.length - 2} ${t("Common_options.others")}`
                        : ""
                    }  `}
                  />
                </Tooltip>
              </Box>
            );
          }
        },
      };
    }

    if (column.columnField === "idParams") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        flex: 1,
        minWidth: 150,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams) => {
          const inarixId = params;

          const chipIdDevice = () => {
            const idConsole = () => {
              setLocationId(inarixId.row.id, inarixId.row);
            };
            const locName = () => {
              setLocFilter(inarixId.row.name, navigate);
            };
            return (
              <div>
                <Tooltip
                  title={t("Fleet_users_locations.table.Edit_location")}
                  arrow
                >
                  <IconButton
                    sx={{
                      color: inarixColors.main_inarix,
                      d: "#ffff",
                      boxShadow: `0 5px 15px -5px ${inarixColors.box_shadow_components}`,
                      marginRight: "5px",
                      "&:hover": {
                        color: inarixColors.light_inarix,
                      },
                    }}
                    onClick={idConsole}
                    aria-label="delete"
                  >
                    <EditIcon data-cy={`tooltip-locations-${inarixId.id}`} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={t("Fleet_users_locations.table.Look_up_users")}
                  arrow
                >
                  <IconButton
                    sx={{
                      color: inarixColors.main_inarix,
                      background: "#ffff",
                      boxShadow: `0 5px 15px -5px ${inarixColors.box_shadow_components}`,
                      "&:hover": {
                        color: inarixColors.light_inarix,
                      },
                    }}
                    onClick={locName}
                    aria-label="delete"
                  >
                    <PersonSearchIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          };
          return chipIdDevice();
        },
      };
    }

    if (column.columnField === "location") {
      return {
        headerName: (columnNames as never)[column.columnField],
        field: column.columnField,
        flex: 1,
        width: 250,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const handleValues = () => {
            if (params.value) {
              return params.value;
            } else {
              return (
                <div>{`Lat: ${params.row.latitude} , Long: ${params.row.longitude}`}</div>
              );
            }
          };

          return <div>{handleValues()}</div>;
        },
      };
    }

    return {
      field: column.columnField,
      headerName: (columnNames as never)[column.columnField]
        ? (columnNames as never)[column.columnField]
        : column.columnName,
      width: 200,
      sortable: column.sortable,
    };
  });

  let locationsRows = locationsToShow?.map((locations: OrgGeoPointData) => {
    const rows = {
      name: locations?.name,
      id: locations?.id,
      longitude: locations?.longitude.toString().slice(0, 7),
      latitude: locations?.latitude.toString().slice(0, 7),
      idParams: locations?.id,
      deletedAt: locations.deletedAt,
      createdAt: locations?.createdAt,
      updatedAt: locations?.updatedAt,
      typeId: locations?.typeId,
      primaryContactName: locations?.primaryContactName,
      telephone: locations?.telephone,
      email: locations?.email,
      location: locations?.address,
      notes: locations?.notes,
      remoteId: locations?.remoteId,
      group: locations.locationGroups.map((group) => group.groupName),

      type: {
        id: locations?.type?.id,
        name: locations?.type?.name,
      },
    };

    return rows;
  });

  if (!columns) {
    columns = [];
  }

  if (!locationsRows) {
    locationsRows = [];
  }

  return {
    columns,
    locationsRows,
  };
};

export default UserLocationsColumns;
