import React, { useState, useMemo } from "react";
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { DColumns } from "../../../../redux/actions/data/DataDeclarations";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { State } from "../../../../redux/slices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@mui/styles";
import { inarixColors } from "inarix-theme";
import { getLocalizedTextByLocaleVersionTwo } from "../../../../utils/localized-text";
import SaveIcon from "@mui/icons-material/Save";

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const useStyles = makeStyles({
  root: {
    width: 200,
    "& .MuiOutlinedInput-input": {
      color: "transparent",
    },
    "& .MuiInputLabel-root": {
      color: "#79777D",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#F6F6F6",
    },
    "&:hover .MuiInputLabel-root": {
      color: inarixColors.light_inarix,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "transparent",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },

  rootError: {
    width: 200,
    "& .MuiOutlinedInput-input": {
      color: "transparent",
    },
    "& .MuiInputLabel-root": {
      color: "#D32F2F",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#F6F6F6",
    },
    "&:hover .MuiInputLabel-root": {
      color: inarixColors.light_inarix,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "transparent",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },

  singleSelect: {
    width: 200,

    "& .MuiInputLabel-root": {
      color: "#79777D",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },

    "&:hover .MuiInputLabel-root": {
      color: inarixColors.light_inarix,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-inputRoot": {
      color: "#79777D",
    },
  },

  singleSelectError: {
    width: 200,

    "& .MuiInputLabel-root": {
      color: "#D32F2F",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },

    "&:hover .MuiInputLabel-root": {
      color: inarixColors.light_inarix,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },

    "& .MuiAutocomplete-inputRoot": {
      color: "#D32F2F",
    },
  },

  autoComplete: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
      color: "purple",
    },
    "& .MuiAutocomplete-inputRoot": {
      color: "red",
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
        {
          paddingLeft: 26,
        },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "green",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple",
      },
    },
  },
});

const CreateManagerUsersColumnsRows = (
  rawColumns: DColumns[],
  handleDeleteClick: (id: GridRowId) => () => void,
  handleChange: (event: SelectChangeEvent<any>, id: number) => void,
  handleChangeGroups: (event: SelectChangeEvent<any>, id: number) => void,
  handleChangeEmail: (event: any, id: string) => void,
  handleChangeRole: (event: SelectChangeEvent<any>, id: number) => void,
  rowModesModel: GridRowModesModel,
  handleSaveClick: (id: GridRowId) => () => void
) => {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const { locations } = useSelector((state: State) => state.MapReducer);
  const { groups } = useSelector((state: State) => state.LocationsReducer);
  const { tryingToCreateSomething, userRoles } = useSelector(
    (state: State) => state.CreateUsersReducer
  );
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const [searchText, setSearchText] = useState("");

  const displayedOptions = useMemo(
    () => locations.filter((option) => containsText(option.name, searchText)),
    [searchText]
  );
  const displayedOptionsActions = useMemo(
    () => groups.filter((option: any) => containsText(option.name, searchText)),
    [searchText]
  );

  const filteruserRoles = userRoles?.filter(
    (role: any) => role.id === 4 || role.id === 3
  );

  const columnNames = {
    id: t("Data.table.Details"),
    email: t("Users_managers.email"),
    role: t("Users_tables.columns_table_one.role"),
    assignedSite: t("Users_tables.columns_table_one.sites"),
    assignedGroups: t("Users_managers.groups"),
    actions: "actions",
  };

  let columns: any[] = rawColumns.map((column: DColumns) => {
    if (column.field === "assignedSite") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        minWidth: 220,
        alwaysHide: column.alwaysHide,
        disableColumnMenu: true,
        sortable: false,
        type: "string",

        renderCell: (params: any) => {
          const handleRoleChange = (event: any) => {
            handleChange(event, params.id);
          };

          return (
            <FormControl
              className={
                !tryingToCreateSomething ? classes.root : classes.rootError
              }
              sx={{
                m: 1,
                width: 300,
                "MuiInputLabel-root": {
                  color: "red",
                },
              }}
            >
              <InputLabel sx={{ marginLeft: "-3px" }}>
                {params.value.length === 0 ? t("Common_options.select") : ""}
              </InputLabel>
              <Select
                multiple
                value={
                  params.value
                    ? params?.value?.filter(function (element: any) {
                        return element !== undefined;
                      })
                    : []
                }
                onChange={handleRoleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => {
                  if (selected.length === 1) {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((roleId: any) => (
                          <Chip
                            key={roleId}
                            label={
                              locations?.find((e) => e.id === roleId)?.name
                            }
                          />
                        ))}
                      </Box>
                    );
                  }

                  if (selected.length > 1) {
                    return (
                      <Chip
                        label={`${
                          locations?.find((e) => e.id === selected[0])?.name
                        } + ${selected.length - 1} ${t(
                          "Common_options.others"
                        )}`}
                      />
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    value={searchText}
                    autoFocus
                    placeholder={t("Common_options.type_to_search")}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>

                {displayedOptions.map((option, i) => {
                  return (
                    <MenuItem
                      disabled={params.row.groupsLocationsSelectedIds?.includes(
                        option.id
                      )}
                      key={option?.id}
                      value={option?.id}
                    >
                      <Checkbox
                        value={option?.id}
                        checked={params.value.includes(option?.id)}
                      />
                      {option?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      };
    }

    if (column.field === "assignedGroups") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        minWidth: 220,
        type: "string",
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        renderCell: (params: any) => {
          const handleRoleChange = (event: any) => {
            handleChangeGroups(event, params.id);
          };
          return (
            <FormControl
              className={
                !tryingToCreateSomething ? classes.root : classes.rootError
              }
              sx={{ m: 1, width: 300, border: "none" }}
            >
              <InputLabel sx={{ marginLeft: "-3px" }}>
                {params.value.length === 0 ? t("Common_options.select") : ""}
              </InputLabel>
              <Select
                multiple
                value={params.value}
                onChange={handleRoleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((roleId: any) => (
                      <Chip
                        key={roleId}
                        label={groups?.find((e) => e.id === roleId)?.name}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    value={searchText}
                    autoFocus
                    placeholder={t("Common_options.type_to_search")}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>

                {displayedOptionsActions.map((option: any, i: number) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      value={option?.id}
                      checked={params?.value?.includes(option.id)}
                    />
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      };
    }
    if (column.field === "email") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        minWidth: 220,
        type: "string",
        sortable: false,
        disableColumnMenu: true,
        editable: true,
        alwaysHide: column.alwaysHide,
      };
    }

    if (column.field === "role") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        minWidth: 220,
        type: "string",
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        renderCell: (params: any) => {
          const handleChange = (event: any) => {
            handleChangeRole(event, params.id);
          };

          return (
            <FormControl
              className={
                !tryingToCreateSomething
                  ? classes.singleSelect
                  : classes.singleSelectError
              }
              sx={{ m: 1, width: 300, border: "none" }}
              fullWidth
            >
              <InputLabel sx={{ marginLeft: "-3px" }}>
                {params.value.length === 0 ? t("Common_options.select") : ""}
              </InputLabel>
              <Select value={params.value} label="role" onChange={handleChange}>
                {filteruserRoles.map((roles: any) => (
                  <MenuItem value={roles.id}>
                    {`${getLocalizedTextByLocaleVersionTwo(
                      locale,
                      roles.name.localizedTexts
                    )}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      };
    }

    if (column.field === "actions") {
      return {
        headerName: "",
        field: column.field,
        width: column.width ? column.width : 100,
        hide: !column.show,
        filterable: false,
        sortable: false,
        alwaysHide: column.alwaysHide,
        disableColumnMenu: true,
        cellClassName: "actions",
        renderCell: ({ id }: any) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={uuidv4()}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
            ];
          }
          return [
            <GridActionsCellItem
              key={uuidv4()}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      };
    }

    return {
      field: column.field,
      headerName: (columnNames as never)[column.field],
      width: column.width,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      alwaysHide: column.alwaysHide,
      editable: true,
    };
  });

  const columnsFilter = columns?.filter((columns: any) => !columns.alwaysHide);

  return {
    rawColumns: columnsFilter,
  };
};

export default CreateManagerUsersColumnsRows;
