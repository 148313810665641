export function FiltersUsersTableObject(
  name: string,
  value: string[],
  operator: string
) {
  if (value.length > 0)
    return {
      name,
      operator: name === "" ? undefined : operator,
      value,
    };
  else {
    return undefined;
  }
}
