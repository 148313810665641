import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import {
  initialState,
  setModal,
  setScenariosId,
  setStepsBySlug,
} from "../../slices/fleet/actions";

import { setConfirmModal } from "../../slices/layout";
import { listGroups, listScenarios } from "./actionsAsyncScenarioAssignment";

/*
 * @Deprecated do no use anymore please!
 */

export const fetchGroupsRelationsShip = async (
  token: string,
  locale: string
) => {
  const res = await axios.get(
    `${API}/users/user-group-scenario?deletedAt=null`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": `${locale}`,
      },
    }
  );
  const { data } = res;
  return data.data;
};

export const fetchCerealNames = async (
  token: string,
  cerealType: number,
  locale: string
) => {
  const res = await axios.get(
    `${API}/core/cereal-type-names?cerealTypeId=${cerealType}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": `${locale}`,
      },
    }
  );
  const { data } = res;
  return data.data;
};

export const fetchCerealType = async (
  token: string,
  cerealType: number,
  locale: string
) => {
  const res = await axios.get(`${API}/core/cereal-type/${cerealType}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": `${locale}`,
    },
  });
  const { data } = res;
  return data;
};

// Individual Action

export const fetchStepBySlug = async (
  token: string,
  slug: string,
  locale: string
) => {
  const res = await axios.get(
    `${API}/core/scenario-instance-step?slug=${slug}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": `${locale}`,
      },
    }
  );
  const { data } = res;
  return data.data;
};

export const fetchScenarioInstance = createAsyncThunk(
  "ActionsReducer/fetchScenarioInstance",
  async (arg: { token: string; id: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    store.dispatch(setStepsBySlug(initialState.individualActionsSteps));
    try {
      const response = await axios.get(
        `${API}/core/scenario-instance/${arg.id}`,
        {
          headers: {
            Authorization: `Bearer ${arg.token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );
      const { data } = response;
      const slugs = data.steps.map((steps: string) =>
        fetchStepBySlug(arg.token, steps, locale)
      );

      const fetchSteps = await Promise.all(slugs);
      store.dispatch(setStepsBySlug(fetchSteps));
      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const deleteScenarioFromGroup = createAsyncThunk(
  "ActionsReducer/deleteScenarioFromGroup",
  async (arg: { token: string; scenarioId: number; orgId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      await axios.delete(`${API}/core/scenario-scope/${arg.scenarioId}`, {
        headers: {
          Authorization: `Bearer ${arg.token}`,
          "Accept-Language": `${locale}`,
        },
      });
      store.dispatch(setConfirmModal(false));
      store.dispatch(listScenarios({ token: arg.token, orgId: arg.orgId }));
      store.dispatch(listGroups(arg.token));
      return store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const moveScenarioToGroup = async (
  token: string,
  scenarioId: number,
  groupId: number,
  locale: string
): Promise<any> => {
  const res = await axios({
    method: "POST",
    url: `${API}/core/scenario-scope`,
    data: {
      scopeGroupId: groupId,
      scenarioId: scenarioId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": `${locale}`,
    },
  });
  const { data } = res;
  console.log(data, "move");
  return data;
};

export const moveAllGroup = createAsyncThunk(
  "ActionsReducer/moveAllScenearioToGroup",
  async (arg: { token: string; orgId: number }, store) => {
    const { scenariosId, groupId } = (store.getState() as RootState)
      .ActionsReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const userPromises = scenariosId?.map((elt: number) => {
        return moveScenarioToGroup(arg.token, elt, groupId, locale);
      });
      await Promise.all(userPromises);
      store.dispatch(listScenarios({ token: arg.token, orgId: arg.orgId }));
      store.dispatch(listGroups(arg.token));
      store.dispatch(setScenariosId(initialState.scenariosId));
      store.dispatch(setModal(false));
      return store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);
