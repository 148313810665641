import { FC, FormEvent } from "react";

import {
  GroupScopes,
  OrganizationUserGroup,
  OrganizationsUserGroups,
  ScenarioData,
} from "../../redux/actions/actions/actionTypes";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  Chip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmModal,
  setInfoConfirmModal,
} from "../../redux/slices/layout";
import { skeletonGroupsActionsAccordions } from "../Helpers";

import { State } from "../../redux/slices";
import {
  AccordionSummaryInarix,
  useStyles,
} from "../userGroups/UserTabsStyles";

const SetActions = () => {
  const dispatch = useDispatch();
  return {
    prepareValuesToRemoveAction: (info: any) => {
      dispatch(setConfirmModal(true));
      dispatch(setInfoConfirmModal(info));
    },
  };
};

type ChipFormProps = {
  id: string | number;
  username: string;
  submitHandler: (e: FormEvent) => void;
};

export const ChipBody: FC<ChipFormProps> = ({
  id,
  username,
  submitHandler,
}: ChipFormProps) => {
  return (
    <div key={id}>
      <div>
        <Chip
          size="small"
          label={username}
          variant="outlined"
          onDelete={submitHandler}
        />
      </div>
    </div>
  );
};

const ActionsAccordion = (
  token: string,
  t: (arg0: string) => string,
  actions: any,
  groups: OrganizationsUserGroups | null,
  loadingFetchGroup: boolean
) => {
  const classes = useStyles();
  const { prepareValuesToRemoveAction } = SetActions();
  const { groupsScopes } = useSelector((state: State) => state.ActionsReducer);

  return (
    <div className="accordion-actions-styles">
      {!loadingFetchGroup
        ? groups?.data.map((groupInfo: OrganizationUserGroup, ind: number) => {
            return (
              <div key={groupInfo.id}>
                <Accordion data-cy="groups-accordion">
                  <AccordionSummaryInarix
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <AccordionActions className={classes.heading}>
                      {groupInfo.name} (
                      {
                        groupsScopes?.filter(
                          (s: GroupScopes) => s?.scopeGroupId === groupInfo?.id
                        )?.length
                      }
                      )
                    </AccordionActions>
                  </AccordionSummaryInarix>
                  <AccordionDetails>
                    <AccordionActions className={classes.chips}>
                      {groupsScopes.map((scopes: GroupScopes) => {
                        if (groupInfo.id === scopes.scopeGroupId)
                          return actions?.map((actions: ScenarioData) => {
                            if (actions?.id === scopes.scenarioId) {
                              return (
                                <div key={actions?.id}>
                                  <ChipBody
                                    id={actions?.id}
                                    username={`${actions?.scenarioName}, (${actions.cerealName}),`}
                                    submitHandler={() =>
                                      prepareValuesToRemoveAction({
                                        user: {
                                          username: actions.scenarioName,
                                          id: scopes.id,
                                        },
                                        groups: groupInfo.name,
                                      })
                                    }
                                  />
                                </div>
                              );
                            }
                          });
                      })}
                    </AccordionActions>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
        : skeletonGroupsActionsAccordions()}
    </div>
  );
};

export default ActionsAccordion;
