import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../../redux/slices";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { setCreatedGroupValues } from "../../../redux/slices/fleet/locations";
import { ThunkDispatch } from "@reduxjs/toolkit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreatedGroupValues: (values: any) => {
      dispatch(setCreatedGroupValues(values));
    },
  };
};

export const AutoCompleteSelectLocations = (t: (arg0: string) => string) => {
  const { locations } = useSelector((state: State) => state.MapReducer);
  const { createdGroups } = useSelector(
    (state: State) => state.LocationsReducer
  );

  const { locationsSelectedAutoComplete } = createdGroups;

  const loading = false;
  const locationsOptions = locations.map((location) => ({
    id: location.id,
    name: location.name,
  }));

  const { setCreatedGroupValues } = SetActions();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div>
      <Autocomplete
        multiple
        loading={loading}
        options={locationsOptions}
        getOptionLabel={(option: any) => option.name}
        filterOptions={(options, params) => {
          const filter = createFilterOptions();
          const filtered = filter(options, params as any);
          return [
            { name: t("Dashboard.setAllLocations"), id: "all" },
            ...filtered,
          ];
        }}
        disableCloseOnSelect
        value={locationsSelectedAutoComplete.valuesSelected as any}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                option.id === "all"
                  ? !!(
                      locationsSelectedAutoComplete.valuesSelected.length ===
                      locationsOptions.length
                    )
                  : selected
              }
            />
            {option.name}
          </li>
        )}
        renderTags={(list) => {
          if (list.length === 1) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {list.map((value: any) => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </Box>
            );
          }

          if (list.length > 1) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <Chip
                  label={`${list[0].name} , ${list[1]?.name}, ${
                    list.length >= 3
                      ? `+ ${list.length - 2} ${t("Common_options.others")}`
                      : ""
                  }  `}
                />
              </Box>
            );
          }
        }}
        onChange={(event, newValue) => {
          if (newValue.find((option) => option.id === "all"))
            return setCreatedGroupValues({
              ...createdGroups,
              locationsSelectedAutoComplete: {
                allValuesSelected: false,
                valuesSelected:
                  locationsSelectedAutoComplete.valuesSelected.length ===
                  locationsOptions.length
                    ? []
                    : locationsOptions,
              },
            });

          setCreatedGroupValues({
            ...createdGroups,
            locationsSelectedAutoComplete: {
              allValuesSelected: false,
              valuesSelected: newValue,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label={t("Locations_groups.sites_to_add")}
          />
        )}
      />
    </div>
  );
};
