/**
 * @author David Roman <david@inarix.com>
 * @file Routing Pages Principal
 * @desc Created on 2023-08-17
 * @copyright Inarix
 */

import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  autoRefreshToken,
  orgFetch,
  TokenPayload,
} from "./redux/actions/authentication/authentication";

import {
  disableErrorLogin,
  isPortalVersionDifferent,
  setUsername,
  signOut,
} from "./redux/slices/authentication/authentication";

import { ThemeProvider } from "@mui/styles";
import { State } from "./redux/slices/index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import themeInarix from "./components/StylesMuiInarix";

import { ThunkDispatch } from "@reduxjs/toolkit";

import PrivateRoute from "./components/authentication/PrivateRoute";
import PublicRoute from "./components/authentication/PublicRoute";

import { isTokenExpired } from "./utils/authentication";

import Map from "./components/map/Map";
import UserGroups from "./components/userGroups/UserGroups";
import UsersDevices from "./components/userDevices/UserDevices";
import UsersActions from "./components/userActions/UsersActions";
import Projects from "./components/projects/Projects";
import UsersIndividualAction from "./components/userActions/IndividualAction";
import Dashboard from "./components/dashboard/Dashboard";
import { BodyPortalVersion } from "./components/authentication/ModalVersion";
import { Modal } from "@mui/material";
import pjson from "../package.json";
import IndividualProject from "./components/projects/IndividualProject/IndividualProject";
import NotFound from "./components/NotFound";
import Data from "./components/data/DataIndex";
import Login from "./components/authentication/Login";
import RecoverPassword from "./components/authentication/ResetPassword";
import UserLocations from "./components/userLocations/locations";
import { usePostHog } from "posthog-js/react";
import DataSampleView from "./components/data/dataSampleView/DataSampleView";
import SetNewPassword from "./components/authentication/SetNewPassword";
import Activate from "./components/authentication/ActivateAccount";
import CreateLocation from "./components/userLocations/CreateLocation";
import StepConfigCreateUsers from "./components/userLocations/steps/stepConfigUsers/UsersConfigTable";
import { logoutDashboard } from "./redux/slices/dashboard/dashboard";
import { logoutData } from "./redux/slices/data/data";
import { logOutActionDetails } from "./redux/slices/data/dataSampleView";
import { initialStateActions } from "./redux/slices/fleet/actions";
import { resetDevicesState } from "./redux/slices/fleet/devices";
import { resetLocationsState } from "./redux/slices/fleet/locations";
import { logoutProjects } from "./redux/slices/fleet/projects";
import { resetStateUserGroups } from "./redux/slices/fleet/users";
import { resetStateMap } from "./redux/slices/map/map";
import CreateUsers from "./components/userGroups/creationUsers/MainCreateUsers";
import StepConfigCreateUsersFromUsers from "./components/userGroups/creationUsers/stepsSiteUsers/Step2-2";
import StepConfigCreateManagerUsers from "./components/userGroups/creationUsers/stepsManagerUsers/UsersManagersConfigTable";
import jwt from "jsonwebtoken";
import { listActionsScenariosGeneral } from "./redux/actions/masterInfoActions/ActionsScenarios";
import { logOutUserTables } from "./redux/slices/userGroups/userGroupsSliceMultipleTables";
import { resetStateCreateUsers } from "./redux/slices/users/createUsers";
import { resetStateCreatLocUsers } from "./redux/slices/location/createLocation";
import { logOutMasterInfo } from "./redux/slices/masterInfoReducer/masterInfoSlice";

const rememberme = localStorage.getItem("rememberme");

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    signOutUser: () => {
      dispatch(signOut());
      dispatch(logoutDashboard());
      dispatch(logoutData());
      dispatch(logOutActionDetails());
      dispatch(resetStateMap());
      dispatch(initialStateActions());
      dispatch(resetDevicesState());
      dispatch(resetLocationsState());
      dispatch(logoutProjects());
      dispatch(resetStateUserGroups());
      dispatch(logOutUserTables());
      dispatch(resetStateCreateUsers());
      dispatch(resetStateCreatLocUsers());
      dispatch(logOutMasterInfo());

      localStorage.removeItem("access_token");

      if (rememberme === "false") {
        localStorage.removeItem("password");
        localStorage.removeItem("username");
      }
      setTimeout(() => {
        localStorage.removeItem("persist:root");
      }, 1000);
    },
    startRefreshingToken: () => dispatch(autoRefreshToken()),
    setUserName: (value: string) => dispatch(setUsername(value)),
    disableError: () => dispatch(disableErrorLogin()),
    fetchOrg: (realmId: number, token: string, locale: string) =>
      dispatch(orgFetch({ realmId, token, locale })),
    isPortalVersionDifferent: (value: boolean) =>
      dispatch(isPortalVersionDifferent(value)),

    prefetchGeneralInformation: (token: string, orgId: number) => {
      dispatch(listActionsScenariosGeneral({ token, orgId }));
    },
  };
};

const App: FC = () => {
  const bodyVersionModal = BodyPortalVersion();
  const posthog = usePostHog();
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const {
    authenticated,
    user,
    isThePortalVersionDifferent,
    portalVersion,
    noAccess,
    username,
    error,
    errorAuthenticated,
  } = useSelector((state: State) => state.Authentication);

  const token = user?.token ? user.token : "";

  const {
    startRefreshingToken,
    signOutUser,
    isPortalVersionDifferent,
    disableError,
    fetchOrg,
    prefetchGeneralInformation,
  } = SetActions();

  const managingPrefetch = (realmId: number) => {
    prefetchGeneralInformation(token, realmId);
  };

  const PortalVersionJSON = pjson.version;

  const isThePortalVersionCorrect = () => {
    if (portalVersion.version !== PortalVersionJSON) {
      isPortalVersionDifferent(true);
    }
  };

  if (noAccess === 403) {
    signOutUser();
    isThePortalVersionCorrect();
  }

  if (authenticated) {
    posthog.identify(username);
  }

  if (error || errorAuthenticated) {
    setTimeout(() => {
      disableError();
    }, 5000);
  }

  window.addEventListener("storage", (e) => {
    if (e.key === "access_token" && e.oldValue && !e.newValue) {
      signOutUser();
    }
  });

  window.addEventListener("storage", (event) => {
    if (event.storageArea !== localStorage) return;
    if (event.key === "access_token") {
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 1500);
    }
  });
  useEffect(() => {
    if (authenticated) {
      const { realmId }: TokenPayload = jwt.decode(
        token as string
      ) as TokenPayload;
      
      fetchOrg(realmId, token, locale);
      setInterval(() => {
        if (!isTokenExpired(user?.token)) {
          return startRefreshingToken();
        } else {
          return () => {};
        }
      }, 40000);
      if (isTokenExpired(user?.token)) {
        signOutUser();
      }

      ///Prefech general information

      managingPrefetch(realmId);
    }
    isThePortalVersionCorrect();
    localStorage.setItem("access_token", user?.token as string);
  }, [authenticated]);

  return (
    <ThemeProvider theme={themeInarix(locale)}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/activate/:id"
            element={
              <PublicRoute user={authenticated}>
                <Activate />
              </PublicRoute>
            }
          />

          <Route
            path="/login"
            element={
              <PublicRoute user={authenticated}>
                <Login />
              </PublicRoute>
            }
          />

          <Route
            path="/password-change/:id"
            element={
              <PublicRoute user={authenticated}>
                <SetNewPassword />
              </PublicRoute>
            }
          />

          <Route
            path="/recoverPassword"
            element={
              <PublicRoute user={authenticated}>
                <RecoverPassword />
              </PublicRoute>
            }
          />

          <Route
            path="/"
            element={
              <PrivateRoute user={authenticated}>
                <Map />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute user={authenticated}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute user={authenticated}>
                <UserGroups />
              </PrivateRoute>
            }
          />

          <Route
            path="/devices"
            element={
              <PrivateRoute user={authenticated}>
                <UsersDevices />
              </PrivateRoute>
            }
          />

          <Route
            path="/locations"
            element={
              <PrivateRoute user={authenticated}>
                <UserLocations />
              </PrivateRoute>
            }
          />

          <Route
            path="/create-locations"
            element={
              <PrivateRoute user={authenticated}>
                <CreateLocation />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-locations/configs"
            element={
              <PrivateRoute user={authenticated}>
                <StepConfigCreateUsers />
              </PrivateRoute>
            }
          />

          <Route
            path="/create-users"
            element={
              <PrivateRoute user={authenticated}>
                <CreateUsers />
              </PrivateRoute>
            }
          />

          <Route
            path="/create-users/configs"
            element={
              <PrivateRoute user={authenticated}>
                <StepConfigCreateUsersFromUsers />
              </PrivateRoute>
            }
          />

          <Route
            path="/create-users/configs-managers"
            element={
              <PrivateRoute user={authenticated}>
                <StepConfigCreateManagerUsers />
              </PrivateRoute>
            }
          />

          <Route
            path="/actions/:id"
            element={
              <PrivateRoute user={authenticated}>
                <UsersIndividualAction />
              </PrivateRoute>
            }
          />

          <Route
            path="/actions"
            element={
              <PrivateRoute user={authenticated}>
                <UsersActions />
              </PrivateRoute>
            }
          />

          <Route
            path="/projects"
            element={
              <PrivateRoute user={authenticated}>
                <Projects />
              </PrivateRoute>
            }
          />

          <Route
            path="/projects/:id"
            element={
              <PrivateRoute user={authenticated}>
                <IndividualProject />
              </PrivateRoute>
            }
          />

          <Route
            path="/data"
            element={
              <PrivateRoute user={authenticated}>
                <Data />
              </PrivateRoute>
            }
          />
          <Route
            path="/data/:id"
            element={
              <PrivateRoute user={authenticated}>
                <DataSampleView />
              </PrivateRoute>
            }
          />

          <Route
            path="*"
            element={
              <PublicRoute user={authenticated}>
                <NotFound />
              </PublicRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute user={authenticated}>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      <Modal open={isThePortalVersionDifferent}>{bodyVersionModal}</Modal>
    </ThemeProvider>
  );
};

export default App;
