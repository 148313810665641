import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Box, Button, Drawer, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { State } from "../../../../redux/slices";
import {
  CheckboxFilters,
  setFiltersConfig,
  setSearchInput,
  showFilters,
  UsersSBFilters,
} from "../../../../redux/slices/userGroups/userGroupsSliceMultipleTables";
import {
  DateFilter,
} from "../filterOptions/UsersListFilterOptions";
import { DashboardLocIdInfo } from "../../../../redux/actions/dashboard/dashboardTypes";

import { fetchUsersNewRequest } from "../../../../redux/actions/users/userAsyncActions";
import AutocompleteExtraFiltersInput from "./AutoCompleteUsers";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setVisibleSBExtraFilters: (visible: boolean) => {
      dispatch(showFilters(visible));
    },
    applyFilters: (token: string) => {
      dispatch(setSearchInput(""));
      dispatch(fetchUsersNewRequest(token));
      dispatch(showFilters(false));
    },

    resetFilters: (
      rolesSelectedReset: any,
      creationDateSelectedReset: any,
      usersSBFilters: UsersSBFilters,
      token: string
    ) => {
      dispatch(setSearchInput(""));
      dispatch(
        setFiltersConfig({
          ...usersSBFilters,
          rolesSelected: rolesSelectedReset,
          CreationDateSelected: creationDateSelectedReset,
          inputSearch: "",
          filtersRoles: undefined,
          filtersLocation: undefined,
          filterActions: undefined,
          filterVarieties: undefined,
          filterCreationDate: undefined,
          filtersSelectedAfterFetch: 0,
          locations: { valuesSelected: [], allValuesSelected: false },

          actions: {
            valuesSelected: [],
            allValuesSelected: false,
          },
          varieties: {
            valuesSelected: [],
            allValuesSelected: false,
          },
        })
      );
      dispatch(fetchUsersNewRequest(token));
    },
  };
};

const SidebarUsersFilters = (
  t: (arg0: string) => string,
  token: string,
  locale: string
) => {
  const { setVisibleSBExtraFilters, resetFilters, applyFilters } = SetActions();
  const { dataSBFilterValues, dataSamplesLoading } = useSelector(
    (state: State) => state.DataReducer
  );

  const { usersSBFilters } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );

  const { showFilters } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );
  const { locations, actions } = dataSBFilterValues;
  const { rolesSelected, CreationDateSelected } = usersSBFilters;

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const disableAll = (idRolesSelected: CheckboxFilters[], idDatesSelected: CheckboxFilters[]) => {
    const rolesSelectedReset = rolesSelected.map((el) => {
      if (el.id === idRolesSelected) {
        return { ...el, isSelected: false };
      } else return { ...el, isSelected: false };
    });

    const CreationDateReset = CreationDateSelected.map((el) => {
      if (el.id === idDatesSelected) {
        return { ...el, isSelected: false };
      } else return { ...el, isSelected: false };
    });
    resetFilters(rolesSelectedReset, CreationDateReset, usersSBFilters, token);
  };

  const resetFilter = () => {
    disableAll(rolesSelected, CreationDateSelected);
  };

  function PersistentDrawerLeft() {
    const theme = useTheme();

    const handleDrawerClose = () => {
      setVisibleSBExtraFilters(false);
    };

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer
          onClose={handleDrawerClose}
          sx={{
            width: 420,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 420,
              boxSizing: "border-box",
              position: "absolute",
              zIndex: 1,
              overflow: "auto",
              overflowX: "hidden",
            },
          }}
          variant="persistent"
          anchor="left"
          open={showFilters}
        >
          <DrawerHeader>
            <div className="title-container-sb">
              <h4>{t("Data_sidebar.Add_filter")}</h4>
            </div>

            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <div className="data-extra-filters-container">
            {/* <div className="by-crop">
              <div>
                <span className="titles-sb-features">
                  {t("Users_tables.columns_table_one.role")}
                </span>
              </div>
              {RolesOptions()}
            </div> */}

            <div className="autocomplete-sb-data">
              <div className="by-site">
                <div>
                  <span className="titles-sb-features">{t("Data.Sites")}</span>
                </div>
                <div className="by-site-container">
                  {AutocompleteExtraFiltersInput(
                    token,
                    "locations",
                    locations as DashboardLocIdInfo[],
                    usersSBFilters?.locations?.allValuesSelected,
                    usersSBFilters?.locations?.valuesSelected,
                    t
                  )}
                </div>
              </div>
              <div className="by-action">
                <div>
                  <span className="titles-sb-features">
                    {t("Data.Actions")}
                  </span>
                </div>
                <div className="by-site-container">
                  {AutocompleteExtraFiltersInput(
                    token,
                    "actions",
                    actions as DashboardLocIdInfo[],
                    usersSBFilters?.actions?.allValuesSelected,
                    usersSBFilters?.actions?.valuesSelected,
                    t
                  )}
                </div>
              </div>

              <div className="by-date">
                <div>
                  <span className="titles-sb-features">
                    {t("Users_tables.creation_date.creation")}
                  </span>
                </div>
                {DateFilter()}
              </div>
            </div>

            <div className="button-position-sb-users">
              <div className="buttons-actions-data-sb">
                <Button
                  sx={{ width: "100%", textTransform: "none" }}
                  variant="outlined"
                  className="mt-3"
                  disabled={dataSamplesLoading}
                  onClick={resetFilter}
                  startIcon={<FilterListOffIcon />}
                >
                  {t("Common_options.Reset_filters")}
                </Button>
              </div>
              <div className="buttons-actions-data-sb">
                <Button
                  variant="contained"
                  disabled={dataSamplesLoading}
                  onClick={() => applyFilters(token)}
                  className="mt-3"
                  sx={{ width: "100%", textTransform: "none" }}
                >
                  {t("Common_options.Apply")}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </Box>
    );
  }

  return PersistentDrawerLeft();
};

export default SidebarUsersFilters;
