import React, { useEffect } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  setCreatedGroupValues,
  setSelectedLocationToShow,
  setSelectGroupTitle,
} from "../../redux/slices/fleet/locations";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { State } from "../../redux/slices";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreatedGroupValues: (values: any) => {
      dispatch(setCreatedGroupValues(values));
    },

    setSelectedLocations: (values: any) => {
      dispatch(setSelectedLocationToShow(values));
    },
    setSelectGroupTitle: (title: string) => {
      dispatch(setSelectGroupTitle(title));
    },
  };
};

export const LocationsLayout = (t: (arg0: string) => string) => {
  const { createdGroups, groups, locations } = useSelector(
    (state: State) => state.LocationsReducer
  );
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const { setCreatedGroupValues, setSelectedLocations, setSelectGroupTitle } =
    SetActions();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    locations: number[],
    title: string
  ) => {
    setSelectedIndex(index);
    setSelectedLocations(locations);
    setSelectGroupTitle(title);
  };

  const allSitesGroup = [
    {
      createdAt: new Date().toISOString(),
      deletedAt: null,
      groupTemplateId: null,
      id: 0.123,
      isAutoGenerated: false,
      name: t("Locations.all_sites"),
      orgId: null,
      typeId: 112,
      updatedAt: null,
      locations: locations.map((location) => location.id),
    },
  ];

  const allGroupsRightFormat = allSitesGroup.concat(groups);

  useEffect(() => {
    setSelectedIndex(0);
    setSelectedLocations(allSitesGroup[0]?.locations);
    setSelectGroupTitle(allSitesGroup[0]?.name);
  }, []);

  return (
    <div className="layout-locations-container">
      <div className="title-location-layout">{t("Locations.title")}</div>
      <div className="subtitle-location-layout">{t("Locations.desc")}</div>
      <div className="buttton-layout-locations-styles">
        <Button
          variant="contained"
          onClick={() =>
            setCreatedGroupValues({
              ...createdGroups,
              isCreatingGroup: true,
              typeOfGroup: "new",
            })
          }
          fullWidth
          sx={{ textTransform: "none" }}
        >
          {t("Locations.new_group")}
        </Button>
      </div>
      <div className="general-divider" />

      <Box
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
      >
        <List component="nav" aria-label="secondary mailbox folder">
          {locations.length >= 1
            ? allGroupsRightFormat.map((groups: any, indx) => {
                return (
                  <ListItemButton
                    selected={selectedIndex === indx}
                    key={groups.id}
                    onClick={(event) =>
                      handleListItemClick(
                        event,
                        indx,
                        groups.locations.map((x: any) => x),
                        groups.name
                      )
                    }
                  >
                    <ListItemText primary={groups.name} />
                    <ListItemIcon>{groups?.locations?.length}</ListItemIcon>
                  </ListItemButton>
                );
              })
            : ""}
        </List>
      </Box>
    </div>
  );
};
