import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import {
  fetchLocationsWithActivity,
  managingUndefined,
} from "../map/mapAsyncActions";
import {
  setCreatedGroupValues,
  setModalLocations,
} from "../../slices/fleet/locations";
import {
  CreateNewSiteSetValues,
  setActionValues,
  setCreateLocationStep,
  setErrorCreateLoc,
  setMultiLocationLoading,
  setMultiLocationSuccess,
  setMultiLocError,
  setMultiLocsCreated,
  setUsersCrated,
} from "../../slices/location/createLocation";
import { ScenarioData } from "../actions/actionTypes";
import { moveRemoveAddLocToUser } from "../users/userAsyncActions";
import { moveScenarioToGroup } from "../actions/actionsAsyncActions";
import { setVerifyAllIsCreated } from "../../slices/users/createUsers";

export const generatePassword = () => {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const patchLocationName = createAsyncThunk(
  "LocationsReducer/patchLocationName",
  async (token: string, store) => {
    const { locationId, editLocation } = (store.getState() as RootState)
      .LocationsReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { name, type, primaryContactName, telephone, email, address, notes } =
      editLocation;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.patch(
        `${API}/users/organization-geo-point/${locationId}`,
        {
          name: managingUndefined(name),
          typeId: managingUndefined(parseInt(type)),
          primaryContactName: managingUndefined(primaryContactName),
          telephone: managingUndefined(telephone),
          email: managingUndefined(email),
          address: managingUndefined(address),
          notes: managingUndefined(notes),
          altitude: 0,
        },
        { headers }
      );

      store.dispatch(setModalLocations(false));
      store.dispatch(fetchLocationsWithActivity(token as unknown as string));
      return store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchLocationMapboxCoordinates = createAsyncThunk(
  "CreateLocationReducer/fetchLocationMapbox",
  async (arg: { longitude: number; latitude: number }, store) => {
    const { createNewSite, createLocationStep } = (
      store.getState() as RootState
    ).CreateLocationReducer;

    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${arg.longitude}&latitude=${arg.latitude}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
          },
        }
      );
      const { data } = response;
      const values = data.features[0];
      if (values) {
        store.dispatch(
          CreateNewSiteSetValues({
            ...createNewSite,
            siteAddress: values.properties.full_address,
            countryName: values.properties.context.country.name,
            longitude: values.properties.coordinates.longitude,
            latitude: values.properties.coordinates.latitude,
          })
        );
        store.dispatch(setErrorCreateLoc(""));
      } else {
        store.dispatch(
          setCreateLocationStep({
            ...createLocationStep,
            step: 1,
            locationValidate: false,
          })
        );
      }
    } catch (error: any) {
      store.dispatch(setErrorCreateLoc(error.response.data.message));
      store.dispatch(
        setCreateLocationStep({
          ...createLocationStep,
          step: 1,
          locationValidate: false,
        })
      );
      store.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchActionsNamesUsersConfig = createAsyncThunk(
  "CreateLocationReducer/fetchActionsNamesUsersConfig",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `${API}/core/scenario?isSystemScenario=false&deletedAt=null&statusConstantId[$in][]=34&statusConstantId[$in][]=35&$eager=[scopes.[group], statusConstant, name.[localizedTexts.[locale]], desc.[localizedTexts.[locale]]]`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );

      const { data } = response;

      const actionsValues = data.data.map((row: ScenarioData) => {
        const scenarioNameValue = () => {
          if (row.name) {
            let def = "";
            const arr = row.name.localizedTexts
              .map((c: any) => {
                if (locale === c.locale.value) {
                  return c.translation;
                }
                def = c.translation;
              })
              .filter((val: any) => null != val);
            if (!arr.length) {
              return [def].toString();
            } else {
              return arr.toString();
            }
          } else {
            return "Null";
          }
        };

        const tableRows = {
          name: scenarioNameValue().toLowerCase() as string,
          id: row.id,
        };
        return tableRows;
      });

      store.dispatch(setActionValues(actionsValues));
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const userCreation = createAsyncThunk(
  "CreateLocationReducer/userCreation",
  async (
    arg: {
      token: string;
      username: string;
      password: string;
      associatedGeoPointId: number;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/register`,
        {
          username: arg.username,
          password: arg.password,
          userType: "site",
          email: "",
          remoteId: "",
          firstname: "",
          lastname: "",
          associatedGeoPointId: arg.associatedGeoPointId,
        },
        { headers }
      );

      const { data } = response;
      return data;
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const createLocation = createAsyncThunk(
  "CreateLocationReducer/createLocation",
  async (arg: { token: string; realmId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { createNewSite, rowsAlpha } = (store.getState() as RootState)
      .CreateLocationReducer;

    const { verifyIfAllIsCreated } = (store.getState() as RootState)
      .CreateUsersReducer;
    const { siteName, siteAddress, longitude, latitude, countryName } =
      createNewSite;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    store.dispatch(setMultiLocationLoading(true));
    try {
      const response = await axios.post(
        `${API}/users/organization-geo-point`,
        {
          name: siteName,
          orgId: arg.realmId,
          typeId: 1,
          latitude,
          longitude,
          address: siteAddress,
          altitude: 0,
          countryName,
        },
        { headers }
      );
      const { data } = response;

      const usersPromises = rowsAlpha.map((usersConfigs) => {
        const manageSiteAssign = () => {
          if (usersConfigs.assignedSite.id === "upcomingSite") {
            return data.id;
          }

          if (usersConfigs.assignedSite.id === "nomad") {
            return undefined;
          } else {
            return usersConfigs.assignedSite.id;
          }
        };

        return store.dispatch(
          userCreation({
            token: arg.token,
            username: usersConfigs.username,
            password: "password",
            associatedGeoPointId: manageSiteAssign(),
          })
        );
      });

      const createdUsers = await Promise.all(usersPromises);
      const res = createdUsers.map((v) => v.payload);
      const rowsAlphaTransform = rowsAlpha.reduce((arr, e) => {
        arr.push(
          Object.assign(
            {},
            e,
            res.find((a) => a.username === e.username)
          ) as never
        );
        return arr;
      }, []);

      const actionsValues = rowsAlphaTransform.map((v: any) =>
        v.assignedAction.map((x: any) => ({
          action: x,
          group: v.group.id,
        }))
      );

      const flatActions = actionsValues.flat();

      const actionsPromises = flatActions.map(
        (actions: { action: number; group: number }) => {
          return moveScenarioToGroup(
            arg.token,
            actions.action,
            actions.group,
            locale
          );
        }
      );

      const movingScenarioGrouping = await Promise.all(actionsPromises);
      store.dispatch(
        setVerifyAllIsCreated({
          ...verifyIfAllIsCreated,
          createdUsers: createdUsers.length >= 1 ? true : false,
          linkActions: movingScenarioGrouping.length >= 1 ? true : false,
          usersCreated: createdUsers.length,
        })
      );

      store.dispatch(setMultiLocsCreated(data.length));
      store.dispatch(setMultiLocationLoading(false));
      store.dispatch(setMultiLocationSuccess(true));

      return createdUsers;
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const createMultiLocationLocation = createAsyncThunk(
  "CreateMultiLocationReducer/createLocation",
  async (arg: { token: string; realmId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { csvResults } = (store.getState() as RootState)
      .CreateLocationReducer;
    store.dispatch(setMultiLocationLoading(true));
    const objectToMultiLoc = csvResults.map((values) => ({
      name: values.siteName,
      orgId: arg.realmId,
      typeId: 1,
      latitude: values.latitude,
      longitude: values.longitude,
      altitude: 0,
    }));

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/organization-geo-point`,
        objectToMultiLoc,
        { headers }
      );

      const { data } = response;
      store.dispatch(setMultiLocsCreated(data.length));
      store.dispatch(setMultiLocationLoading(false));
      store.dispatch(setMultiLocationSuccess(true));
      return data;
    } catch (error: any) {
      return store.dispatch(setMultiLocError(error?.response?.data?.message));
    }
  }
);

export const createMultiUsersWithActions = createAsyncThunk(
  "createMultiUsersWithActions/createLocation",
  async (arg: { token: string; realmId: number }, store) => {
    const { rowsAlpha, verifyIfAllIsCreated } = (store.getState() as RootState)
      .CreateUsersReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    store.dispatch(setMultiLocationLoading(true));

    try {
      const usersPromises = rowsAlpha.map((usersConfigs) => {
        const manageSiteAssign = () => {
          if (usersConfigs.assignedSite.id === "nomad") {
            return undefined;
          } else {
            return usersConfigs.assignedSite.id;
          }
        };

        return store.dispatch(
          userCreation({
            token: arg.token,
            username: usersConfigs.username,
            password: generatePassword(),
            associatedGeoPointId: manageSiteAssign() as number,
          })
        );
      });

      const createdUsers = await Promise.all(usersPromises);
      const res = createdUsers.map((v) => v.payload);

      const rowsAlphaTransform = rowsAlpha.reduce((arr, e) => {
        arr.push(
          Object.assign(
            {} as never,
            e,
            res.find((a) => a.username === e.username)
          )
        );
        return arr;
      }, []);

      const actionsValues = rowsAlphaTransform.map((v: any) =>
        v.assignedAction.map((x: any) => ({
          action: x,
          group: v.group.id,
        }))
      );

      const flatActions = actionsValues.flat();

      const actionsPromises = flatActions.map(
        (actions: { action: number; group: number }) => {
          return moveScenarioToGroup(
            arg.token,
            actions.action,
            actions.group,
            locale
          );
        }
      );

      const movingScenarioGrouping = await Promise.all(actionsPromises);

      store.dispatch(
        setVerifyAllIsCreated({
          ...verifyIfAllIsCreated,
          createdUsers: createdUsers.length >= 1 ? true : false,
          linkActions: movingScenarioGrouping.length >= 1 ? true : false,
          usersCreated: createdUsers.length,
        })
      );

      store.dispatch(setUsersCrated(res));
      store.dispatch(setMultiLocationLoading(false));
    } catch (error: any) {
      store.dispatch(setMultiLocationLoading(false));
      return store.dispatch(setMultiLocError(error?.response?.data?.message));
    }
  }
);

export const createLocationLinkExistingUsers = createAsyncThunk(
  "CreateMultiLocationReducer/createLocationLinkExistingUsers",
  async (
    arg: {
      token: string;
      usersSelected: string[];
      realmId: number;
      withUsers: boolean;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { createNewSite } = (store.getState() as RootState)
      .CreateLocationReducer;
    const { siteName, siteAddress, latitude, longitude, countryName } =
      createNewSite;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    store.dispatch(setMultiLocationLoading(true));
    try {
      const response = await axios.post(
        `${API}/users/organization-geo-point`,
        {
          name: siteName,
          orgId: arg.realmId,
          typeId: 1,
          latitude: latitude,
          longitude: longitude,
          altitude: 0,
          address: siteAddress,
          countryName,
        },
        { headers }
      );

      const { data } = response;
      if (arg.withUsers) {
        const userAssignThelocationPromise = arg.usersSelected.map((users) => {
          return moveRemoveAddLocToUser(
            arg.token,
            users,
            data.id,
            "addLoc",
            locale
          );
        });

        const move = await Promise.all(userAssignThelocationPromise);
        return move;
      }

      store.dispatch(setMultiLocsCreated(data.length));
      store.dispatch(setMultiLocationLoading(false));
      store.dispatch(setMultiLocationSuccess(true));
    } catch (error: any) {
      store.dispatch(setMultiLocationLoading(false));
      return store.dispatch(setMultiLocError(error?.response?.data?.message));
    }
  }
);

export const fetchLocationsGroups = createAsyncThunk(
  "LocationsReducer/fetchLocationsGroups",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    try {
      const response = await axios.get(
        `${API}/users/organization-group?typeId=112&$eager=[memberships.[user,geoPoint]]`,
        {
          headers: {
            "Accept-Language": `${locale}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;
      const filterGroups = data.data.filter(
        (group: any) => group.typeId === 112 && !group.isAutoGenerated
      );

      const newGroupObject = filterGroups.map((g: any) => ({
        createdAt: g.createdAt,
        deletedAt: g.deletedAt,
        groupTemplateId: g.groupTemplateId,
        id: g.id,
        isAutoGenerated: g.isAutoGenerated,
        name: g.name,
        orgId: g.orgId,
        typeId: g.typeId,
        updatedAt: g.updatedAt,
        locations: g.memberships
          .filter((memberships: any) => memberships.geoPoint !== null)
          .map((d: any) => d.geoPointId),
      }));

      return store.fulfillWithValue(newGroupObject);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const moveSiteTogroup = async (
  token: string,
  groupId: number,
  geoPointId: number,
  locale: string,
  orgId: number
): Promise<any> => {
  const res = await axios({
    method: "POST",
    url: `${API}/users/organization-group-membership`,
    data: {
      groupId: groupId,
      geoPointId: geoPointId,
      isActive: true,
      membershipType: 0, //to change later
    },
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });

  const { data } = res;
  return data;
};

export const moveSiteTogroup2 = async (
  token: string,
  groupId: number,
  geoPointId: number,
  locale: string,
  membershipType: number
): Promise<any> => {
  const res = await axios({
    method: "POST",
    url: `${API}/users/organization-group-membership`,
    data: {
      groupId: groupId,
      geoPointId: geoPointId,
      isActive: true,
      membershipType,
    },
    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });

  const { data } = res;
  console.log(data, "movingsites");
  return data;
};

export const moveSitesTogroup = createAsyncThunk(
  "LocationsReducer/moveSitesTogroup",
  async (
    arg: {
      token: string;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { orgId } = (store.getState() as RootState).Authentication;
    const { createdGroups } = (store.getState() as RootState).LocationsReducer;
    const { locationsSelectedAutoComplete, groupSelectedToMoveLocation } =
      createdGroups;

    try {
      const locationPomises = locationsSelectedAutoComplete.valuesSelected.map(
        (locations) => {
          return moveSiteTogroup(
            arg.token,
            groupSelectedToMoveLocation as any,
            locations.id as number,
            locale,
            orgId as number
          );
        }
      );
      const move = await Promise.all(locationPomises);

      if (move.length >= 1) {
        store.dispatch(
          setCreatedGroupValues({
            ...createdGroups,
            isLoading: false,
            errorCreateGroup: "",
            groupName: "",
            isCreatingGroup: false,
            typeOfGroup: "",
            groupSelectedToMoveLocation: "",
            isCreatedGroupSuccess: true,
            locationsSelectedAutoComplete: {
              allValuesSelected: false,
              valuesSelected: [],
            },
          })
        );
        store.dispatch(fetchLocationsWithActivity(arg.token));
      }
    } catch (error: any) {
      store.dispatch(
        setCreatedGroupValues({
          ...createdGroups,
          errorCreateGroup: error?.response?.data?.message,
        })
      );
    }
  }
);

export const createLocationGroup = createAsyncThunk(
  "LocationsReducer/createLocationGroup",
  async (
    arg: {
      token: string;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { orgId } = (store.getState() as RootState).Authentication;
    const { createdGroups } = (store.getState() as RootState).LocationsReducer;

    const clusterTypeGroup = 112;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/organization-group`,
        {
          name: createdGroups.groupName,
          typeId: clusterTypeGroup,
          orgId,
          groupTemplateId: 27,
        },
        { headers }
      );

      const { data } = response;

      if (data.id) {
        const locationsToMovePromise =
          createdGroups.locationsSelectedAutoComplete.valuesSelected.map(
            (locations) => {
              return moveSiteTogroup(
                arg.token,
                data.id,
                locations.id as number,
                locale,
                orgId as number
              );
            }
          );

        await Promise.all(locationsToMovePromise);
      }

      store.dispatch(
        setCreatedGroupValues({
          ...createdGroups,
          isLoading: false,
          errorCreateGroup: "",
          groupName: "",
          isCreatingGroup: false,
          typeOfGroup: "",
          groupSelectedToMoveLocation: "",
          isCreatedGroupSuccess: true,
          locationsSelectedAutoComplete: {
            allValuesSelected: false,
            valuesSelected: [],
          },
        })
      );
      store.dispatch(fetchLocationsWithActivity(arg.token));
      store.dispatch(fetchLocationsGroups(arg.token));
      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.dispatch(
        setCreatedGroupValues({
          ...createdGroups,
          errorCreateGroup: error?.response?.data?.message,
        })
      );
    }
  }
);
