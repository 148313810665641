import React, { FC, useEffect } from "react";

import { Box, Button, SelectChangeEvent } from "@mui/material";
import {
  DataGridPro,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { ThemeProvider } from "@mui/material/styles";

import themeInarix from "../../../StylesMuiInarix";

import { useTranslation } from "react-i18next";
import { State } from "../../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import {
  resetStateCreateUsers,
  setManagerUserConfigRows,
  setCreateUsersStep,
  CreateUsersStep,
  setManagerColumns,
  setTryingToCreateSomething,
  initialState,
  setCreateManagerValues,
  GroupStructure,
  ManagerUsersConfigRows,
} from "../../../../redux/slices/users/createUsers";
import AddIcon from "@mui/icons-material/Add";
import { ThunkDispatch } from "@reduxjs/toolkit";
import Alert from "@mui/material/Alert";

import CreateManagerUsersColumnsRows from "./FetchingColumnsManagers";
import validator from "validator";
import {
  createMultiManagerUsersWithActions,
  groupTemplateLooksLike,
} from "../../../../redux/actions/users/userAsyncActions";
import { v4 as uuidv4 } from "uuid";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setRowsAlpha2: (value: ManagerUsersConfigRows[]) => {
      dispatch(setManagerUserConfigRows(value));
    },
    setSteps: (value: CreateUsersStep) => {
      dispatch(setCreateUsersStep(value));
    },
    cancel: () => {
      dispatch(resetStateCreateUsers());
    },
    setColumnIndex: (columns: any) => {
      dispatch(setManagerColumns(columns));
    },

    tryingToCreate: (trying: boolean) => {
      dispatch(setTryingToCreateSomething(trying));
    },

    createManagersService: (token: string) => {
      dispatch(createMultiManagerUsersWithActions(token));
    },

    getGroupTemplateLookslikes: (token: string) => {
      dispatch(groupTemplateLooksLike(token));
    },
    resetValuesCreateManagers: () => {
      dispatch(setCreateManagerValues(initialState.createManagers));
    },
  };
};
export const infoValidator = (something: any[]) => something?.length === 0;
export const roleValidator = (role: number | undefined) =>
  role && role > 0 ? true : false;

export const StepConfigCreateManagerUsers: FC<any> = () => {
  const apiRef = useGridApiRef();
  const [t] = useTranslation("global");
  const { user } = useSelector((state: State) => state.Authentication);
  const { users } = useSelector((state: State) => state.UsersReducer);
  const { groups } = useSelector((state: State) => state.LocationsReducer);
  const navigate = useNavigate();

  const {
    setSteps,
    setColumnIndex,
    setRowsAlpha2,
    tryingToCreate,
    createManagersService,
    resetValuesCreateManagers,
    getGroupTemplateLookslikes,
  } = SetActions();
  const {
    createUserSteps,
    managerColumns,
    managerUsersConfigRows,
    tryingToCreateSomething,
    createManagers,
    createErrorManagerAllInarix,
    groupTemplateRaw,
    createErrorManagerAllInarixShow,
  } = useSelector((state: State) => state.CreateUsersReducer);

  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const token = user?.token ?? "";

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRowsAlpha2(managerUsersConfigRows.filter((row) => row.id !== id));
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleChange = (event: SelectChangeEvent<any>, id: number) => {
    const {
      target: { value },
    } = event;

    setRowsAlpha2(
      managerUsersConfigRows.map((row) =>
        row.id === id
          ? {
              ...row,
              assignedSite: value === "string" ? value.split(",") : value,
            }
          : row
      )
    );
  };

  const handleChangeGroups = (event: SelectChangeEvent<any>, id: number) => {
    const {
      target: { value },
    } = event;

    setRowsAlpha2(
      managerUsersConfigRows.map((row) =>
        row.id === id
          ? {
              ...row,
              assignedGroups: value === "string" ? value.split(",") : value,
              groupsSelected: groups.filter((s: GroupStructure) =>
                value.flat().includes(s.id)
              ),

              groupsLocationsSelectedIds: [
                ...new Set(
                  groups
                    .filter((s: GroupStructure) => value.flat().includes(s.id))
                    .map((g) => g.locations)
                    .flat()
                ),
              ],

              assignedSite: [
                ...new Set(
                  groups
                    .filter((s: GroupStructure) => value.flat().includes(s.id))
                    .map((g) => g.locations)
                    .flat()
                ),
              ],
            }
          : row
      )
    );
  };

  const handleChangeEmail = (event: any, id: string) => {
    const {
      target: { value },
    } = event;

    setRowsAlpha2(
      managerUsersConfigRows.map((row: any) =>
        row.id === id
          ? {
              ...row,
              email: value === "string" ? value.split(",") : value,
            }
          : row
      )
    );
  };

  const handleChangeRole = (event: any, id: number) => {
    const {
      target: { value },
    } = event;

    setRowsAlpha2(
      managerUsersConfigRows.map((row) =>
        row.id === id
          ? {
              ...row,
              role: value === "string" ? value.split(",") : value,
            }
          : row
      )
    );
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsAlpha2(
      managerUsersConfigRows.map((row) =>
        row.id === newRow.id ? updatedRow : row
      ) as ManagerUsersConfigRows[]
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const { rawColumns } = CreateManagerUsersColumnsRows(
    managerColumns,
    handleDeleteClick,
    handleChange,
    handleChangeGroups,
    handleChangeEmail,
    handleChangeRole,
    rowModesModel,
    handleSaveClick
  );

  useEffect(() => {
    getGroupTemplateLookslikes(token);
    setSteps({ ...createUserSteps, place: "configs-managers" });

    window.onbeforeunload = function () {
      window.setTimeout(function () {
        window.location = "/create-users/" as any;
      }, 0);
      window.onbeforeunload = null;
    };
  }, []);

  const editToolbar = () => {
    const handleClick = () => {
      const id = Math.random();

      setRowsAlpha2([
        ...managerUsersConfigRows,
        {
          id,
          email: "",
          role: "",
          assignedSite: [],
          assignedGroups: [],
          isNew: true,
        },
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "email" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          disabled={managerUsersConfigRows.length >= 100}
          onClick={handleClick}
        >
          {t("Create_site.config_users.add_user")}
        </Button>
      </GridToolbarContainer>
    );
  };

  const existingUserNames = users.data.map((users) => users.username);

  const theUsernameExistValidator = (username: string) =>
    existingUserNames.filter((val) => username.includes(val));

  const theUserIsGood = () => {
    const transformToValidate = managerUsersConfigRows.map(
      (validateRows, index) => ({
        email: validateRows.email,
        role: validateRows.role,
        assignedSite: validateRows.assignedSite,
        assignedGroups: validateRows.assignedGroups,
        isTheAssignedSiteGood: !infoValidator(validateRows.assignedSite),
        isTheassignedGroupsGood: !infoValidator(validateRows.assignedGroups),
        isTheEmailOk: validator.isEmail(validateRows.email),
        isTheEmailExist:
          theUsernameExistValidator(validateRows.email).length >= 1
            ? true
            : false,
        istheRolOk: roleValidator(validateRows.role as number),
        rowPosition: index + 1,
      })
    );
    return transformToValidate;
  };

  const encounterErrorEmail = theUserIsGood().filter(
    (rows) => rows.isTheEmailOk === false
  );

  const encounterErrorEmailExist = theUserIsGood().filter(
    (rows) => rows.isTheEmailExist === true
  );

  const encounterErrorSites = theUserIsGood().filter(
    (rows) => rows.isTheAssignedSiteGood === false
  );

  const encounterErrorGroups = theUserIsGood().filter(
    (rows) => rows.isTheassignedGroupsGood === false
  );

  const encounterErrorRoles = theUserIsGood().filter(
    (rows) => rows.istheRolOk === false
  );

  const totalErrors = encounterErrorEmail?.concat(
    encounterErrorEmailExist?.concat(
      encounterErrorSites?.concat(
        encounterErrorGroups?.concat(encounterErrorRoles)
      )
    )
  ).length;

  const managingError = () => {
    const emailErrors = () => {
      const resultsEmailErrors = encounterErrorEmail.map((results) => (
        <span className="mr-2">
          {encounterErrorEmail.length === 1
            ? `${results.rowPosition}`
            : `${results.rowPosition},`}
        </span>
      ));

      return (
        <div>
          {resultsEmailErrors.length >= 1 ? (
            <div>
              <span className="mr-2">
                {t("Users_managers.email_validator")}
              </span>
              {resultsEmailErrors}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    };

    const emailExists = () => {
      const resultsEmailErrors = encounterErrorEmailExist.map((results) => (
        <span className="mr-2">
          {encounterErrorEmailExist.length === 1
            ? `${results.email}, ${t("Users_managers.lines")} : ${
                results.rowPosition
              }`
            : `${results.email},  ${t("Users_managers.line")} : ${
                results.rowPosition
              }`}
        </span>
      ));

      return (
        <div>
          {resultsEmailErrors.length >= 1 ? (
            <div>
              <span className="mr-2">{t("Users_managers.email_exist")}</span>
              {resultsEmailErrors}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    };

    const assignedSiteError = () => {
      const resultsAssignedSitesErrors = encounterErrorSites.map((results) => (
        <span className="mr-2">
          {encounterErrorSites.length === 1
            ? `${results.rowPosition}`
            : `${results.rowPosition},`}
        </span>
      ));

      return (
        <div>
          {resultsAssignedSitesErrors.length >= 1 ? (
            <div>
              <span className="mr-2">
                {t("Users_managers.not_assigned_sites")}
              </span>
              <span>
                {resultsAssignedSitesErrors.length === 1
                  ? t("Users_managers.line")
                  : `${t("Users_managers.line")} : `}
              </span>
              {resultsAssignedSitesErrors}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    };

    const assignedGroupsError = () => {
      const resultsAssignedGroupsErrors = encounterErrorGroups.map(
        (results) => (
          <span className="mr-2">
            {encounterErrorGroups.length === 1
              ? `${results.rowPosition}`
              : `${results.rowPosition},`}
          </span>
        )
      );

      return (
        <div>
          {resultsAssignedGroupsErrors.length >= 1 ? (
            <div>
              <span className="mr-2">
                {t("Users_managers.not_assigned_groups")}
              </span>

              <span>
                {resultsAssignedGroupsErrors.length === 1
                  ? t("Users_managers.line")
                  : `${t("Users_managers.line")} : `}
              </span>
              {resultsAssignedGroupsErrors}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    };

    const roleError = () => {
      const resultRoleErrors = encounterErrorRoles.map((results) => (
        <span key={`${results.email}${uuidv4()}`} className="mr-2">
          {encounterErrorRoles.length === 1
            ? `${results.rowPosition}`
            : `${results.rowPosition},`}
        </span>
      ));

      return (
        <div>
          {resultRoleErrors.length >= 1 ? (
            <div>
              <span className="mr-2">
                {t("Users_managers.not_completed_roles")}
              </span>
              <span>
                {resultRoleErrors.length === 1
                  ? t("Users_managers.line")
                  : `${t("Users_managers.line")} : `}
              </span>
              {resultRoleErrors}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    };

    return (
      <Alert severity="error">
        <div>{emailExists()}</div>

        {tryingToCreateSomething ? (
          <>
            <div>{emailErrors()}</div>
            <div>{assignedSiteError()}</div>
            <div>{assignedGroupsError()}</div>
            <div>{roleError()}</div>
          </>
        ) : (
          ""
        )}
      </Alert>
    );
  };

  const managingShowErrors = () => {
    if (totalErrors >= 1 && tryingToCreateSomething) {
      return true;
    } else {
      return false;
    }
  };

  const showServiceError = () => {
    if (createManagers.createError) {
      setTimeout(() => {
        resetValuesCreateManagers();
      }, 3000);

      return (
        <Alert sx={{ marginBottom: "2px" }} severity="error">
          <div>{createManagers.createError}</div>
        </Alert>
      );
    } else {
      return "";
    }
  };

  if (createManagers.createSuccess) {
    navigate("/users");
  }

  const userExistGlobalInarix = () => {
    if (createErrorManagerAllInarix.length >= 1) {
      const globalError = createErrorManagerAllInarix?.map((error: string) => {
        return <div>{error}</div>;
      });
      return (
        <Alert sx={{ marginBottom: "2px" }} severity="error">
          {globalError}
        </Alert>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="create-sites-container">
      <div className="steps-container-create-loc">
        <div className="step-title">{t("Create_site.config_users.step")}</div>
        <div>
          <div className="step-title-create">
            {t("Users_managers.create_managers")}
          </div>
          <div className="design-scope">
            {t("Users_managers.create_mang_sub")}
          </div>
          {showServiceError()}
          {createErrorManagerAllInarixShow ? userExistGlobalInarix() : " "}
          {managingShowErrors() ? managingError() : undefined}
          <div className="create-site-form" style={{ background: "#fff" }}>
            <Box sx={{ height: 420, width: 1 }}>
              <ThemeProvider theme={themeInarix(locale)}>
                <DataGridPro
                  loading={createManagers.createLoading}
                  rows={
                    managerUsersConfigRows.length === 0
                      ? []
                      : managerUsersConfigRows
                  }
                  columns={
                    rawColumns.length === 0
                      ? [
                          {
                            field: "id",
                            sortable: true,
                            width: 100,
                          },
                        ]
                      : rawColumns
                  }
                  onColumnOrderChange={() => {
                    setColumnIndex(apiRef.current.getAllColumns());
                  }}
                  onColumnWidthChange={() =>
                    setColumnIndex(apiRef.current.getAllColumns())
                  }
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  pageSizeOptions={[25, 50, 100]}
                  slots={{
                    footer: editToolbar as any["footer"],
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                  }}
                  apiRef={apiRef}
                  rowHeight={70}
                  rowSelection={false}
                  disableColumnSelector={true}
                  disableRowSelectionOnClick={true}
                />
              </ThemeProvider>
            </Box>
            <Button
              onClick={() => {
                if (totalErrors >= 1) {
                  tryingToCreate(true);
                }
                if (totalErrors === 0) {
                  tryingToCreate(false);
                  createManagersService(token);
                }
              }}
              disabled={groupTemplateRaw.length === 0 || createManagers.createLoading}
              sx={{ textTransform: "none", width: "79px", marginTop: "24px" }}
              variant="contained"
            >
              {t("Create_site.config_users.create")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepConfigCreateManagerUsers;
