import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { State } from "../../../../redux/slices";
import {
  setFiltersConfig,
  UsersSBFilters,
} from "../../../../redux/slices/userGroups/userGroupsSliceMultipleTables";
import { ColumnSwitchesTypes } from "../../../../redux/slices/data/data";
import { FiltersUsersTableObject } from "./Helpers";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setRolesSelected: (value: any, userSBFilters: UsersSBFilters) => {
      const values = value?.map((l: ColumnSwitchesTypes) => {
        if (l.isSelected) {
          return l.id;
        }
      });

      const filtered = values.filter((n: number[]) => n);

      dispatch(
        setFiltersConfig({
          ...userSBFilters,
          rolesSelected: value,
          filtersRoles: FiltersUsersTableObject("roles", filtered, ""),
        })
      );
    },

    setDatesSelected: (value: any, userSBFilters: UsersSBFilters) => {
      const values = value?.map((l: ColumnSwitchesTypes) => {
        if (l.isSelected) {
          return l.id;
        }
      });

      const filtered = values.filter((n: number[]) => n);

      dispatch(
        setFiltersConfig({
          ...userSBFilters,
          CreationDateSelected: value,
          filterCreationDate: FiltersUsersTableObject("dates", filtered, ""),
        })
      );
    },
  };
};

export const RolesOptions = () => {
  const { setRolesSelected } = SetActions();
  const { usersSBFilters } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );
  const { rolesSelected } = usersSBFilters;

  const changeStatus = (id: string) => {
    const newArr = rolesSelected.map((el) => {
      if (el.id === id) {
        return { ...el, isSelected: !el.isSelected };
      } else return { ...el, isSelected: el.isSelected };
    });
    setRolesSelected(newArr as any, usersSBFilters);
  };

  return (
    <FormControl className="crops-container ml-2" component="fieldset">
      <FormGroup>
        {rolesSelected.map((value) => {
          return (
            <FormControlLabel
              key={value.id}
              control={
                <Checkbox
                  checked={value.isSelected}
                  onChange={() => changeStatus(value.id)}
                  name={value.name}
                />
              }
              label={value.name}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export const DateFilter = () => {
  const { setDatesSelected } = SetActions();
  const { usersSBFilters } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );
  const { CreationDateSelected } = usersSBFilters;

  const changeStatus = (id: string) => {
    const newArr = CreationDateSelected.map((el) => {
      if (el.id === id) {
        return { ...el, isSelected: !el.isSelected };
      } else return { ...el, isSelected: el.isSelected };
    });
    setDatesSelected(newArr as any, usersSBFilters);
  };

  return (
    <FormControl className="crops-container ml-2" component="fieldset">
      <FormGroup>
        {CreationDateSelected.map((value) => {
          return (
            <FormControlLabel
              key={value.id}
              control={
                <Checkbox
                  checked={value.isSelected}
                  onChange={() => changeStatus(value.id)}
                  name={value.name}
                />
              }
              label={value.name}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
