import { createStore, applyMiddleware, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, Persistor, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "./redux/slices/index";
import localforage from "localforage";

export type RootState = ReturnType<typeof rootReducer>;
export interface ConfiguredStore {
  store: Store;
  persistor: Persistor;
}

const persistConfig = {
  key: "root",
  storage:localforage,
  stateReconciler: autoMergeLevel2,
};

/**
 * Configure and returns store and persistor.
 */

export default (): ConfiguredStore => {
  const store = createStore(
    persistReducer(persistConfig, rootReducer as any),
    composeWithDevTools(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
