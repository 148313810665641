import { State } from "../../../../redux/slices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Chip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getLocalizedTextByLocaleVersionTwo } from "../../../../utils/localized-text";
import { AssociatedGeoPoint } from "../../../../redux/actions/users/userTypes";
import { ColumnTables } from "../../../../redux/slices/userGroups/userTablestyping";
import { GridColDef } from "@mui/x-data-grid-pro";

const CreateAllUsersColumns = () => {
  const [t] = useTranslation("global");
  const { allUserColumnsTable } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );
  const { users } = useSelector((state: State) => state.UsersReducer);
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { usersSBFilters, searchInputUsersTable } = useSelector(
    (state: State) => state.CreateTablesUsersReducer
  );

  const columnNames = {
    id: t("Data.table.Details"),
    username: t("Users_tables.columns_table_one.users"),
    role: t("Users_tables.columns_table_one.role"),
    assignedSite: t("Users_tables.columns_table_one.sites"),
    assignedAction: t("Users_tables.columns_table_one.actions"),
    varieties: t("Users_tables.columns_table_one.varieties"),
    actions: "",
  };

  const actionsSelectedFilter = usersSBFilters.actions.valuesSelected.map(
    (values) => values.id
  );

  const resultsToSearch = users.data.filter((user) => {
    const normalizeUsername = user.username
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    const normalizeEmail = user.email
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");

    if (actionsSelectedFilter.length === 0 && searchInputUsersTable === "") {
      return user;
    } else if (
      user.actionsIds.find(
        (x) =>
          actionsSelectedFilter.find((e) => e === x) &&
          searchInputUsersTable === ""
      )
    ) {
      return user;
    } else if (
      user.actionsIds.find(
        (x) =>
          actionsSelectedFilter.find((e) => e === x) &&
          normalizeUsername
            ?.toLowerCase()
            .includes(searchInputUsersTable.toLowerCase())
      )
    ) {
      return user;
    } else if (
      actionsSelectedFilter.length === 0 &&
      normalizeUsername
        ?.toLowerCase()
        .includes(searchInputUsersTable.toLowerCase())
    ) {
      return user;
    } else if (
      user.actionsIds.find(
        (x) =>
          actionsSelectedFilter.find((e) => e === x) &&
          normalizeEmail
            ?.toLowerCase()
            .includes(searchInputUsersTable.toLowerCase())
      )
    ) {
      return user;
    } else if (
      actionsSelectedFilter.length === 0 &&
      normalizeEmail
        ?.toLowerCase()
        .includes(searchInputUsersTable.toLowerCase())
    ) {
      return user;
    }

    return "";
  });

  let allUsersColumns: any[] = allUserColumnsTable.map(
    (column: ColumnTables) => {
      if (column.field === "username") {
        return {
          field: column.field,
          headerName: (columnNames as never)[column.field],
          width: 220,
          alwaysHide: column.alwaysHide,
          disableColumnMenu: true,
          sortable: false,
          type: "string",

          renderCell: (params: any) => {
            return (
              <div className="username-managing-styles-column">
                <div className="username-title-columns">
                  {params === null || params === undefined ? "" : params.value}
                </div>
                <div className="mail-title-columns">
                  {params.row.email === null || params.row.email === undefined
                    ? ""
                    : params.row.email}
                </div>
              </div>
            );
          },
        };
      }

      if (column.field === "assignedAction") {
        return {
          field: column.field,
          headerName: (columnNames as never)[column.field],
          minWidth: 230,
          alwaysHide: column.alwaysHide,
          disableColumnMenu: true,
          sortable: false,
          type: "string",

          renderCell: (params: any) => {
            const actionNames = params.value.map((x: any) => {
              return getLocalizedTextByLocaleVersionTwo(
                locale,
                x.name.localizedTexts
              );
            });

            if (actionNames.length === 1) {
              return <span>{`${actionNames}`}</span>;
            }

            if (actionNames.length > 1) {
              return (
                <span>{`${actionNames[0]} + ${actionNames.length - 1} ${t(
                  "Common_options.others"
                )}`}</span>
              );
            }

            if (actionNames.length === 0) {
              return <span>{``}</span>;
            }
          },
        };
      }
      if (column.field === "role") {
        return {
          field: column.field,
          headerName: (columnNames as never)[column.field],
          width: 100,
          alwaysHide: column.alwaysHide,
          disableColumnMenu: true,
          sortable: false,
          type: "string",
          renderCell: (params: any) => {
            return (
              <div className="username-managing-styles-column">
                <Chip label={params.value} variant="outlined" />
              </div>
            );
          },
        };
      }

      if (column.field === "assignedSite") {
        return {
          field: column.field,
          headerName: (columnNames as never)[column.field],
          alwaysHide: column.alwaysHide,
          width: 250,
          disableColumnMenu: true,
          sortable: false,
          type: "string",

          renderCell: (params: { value: AssociatedGeoPoint[] }) => {
            const siteNames = params.value?.map((value: AssociatedGeoPoint) => {
              return value?.name;
            });

            const filter = siteNames.filter(function (element) {
              return element !== undefined;
            });

            if (filter.length === 1) {
              return <span>{`${filter}`}</span>;
            }

            if (filter.length > 1) {
              return (
                <span>{`${filter[0]} + ${filter.length - 1} ${t(
                  "Common_options.others"
                )}`}</span>
              );
            }

            if (filter.length === 0) {
              return (
                <span>{`${filter.length}  ${t(
                  "Users_tables.columns_table_one.sites"
                )}`}</span>
              );
            }
          },
        };
      }

      if (column.field === "actions") {
        return {
          field: column.field,
          headerName: (columnNames as never)[column.field],
          width: 100,
          alwaysHide: column.alwaysHide,
          disableColumnMenu: true,
          sortable: false,
          type: "string",

          renderCell: (params: any) => {
            return (
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            );
          },
        };
      }

      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: column.width,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        editable: true,
      };
    }
  );

  let rowsAllUsersTable = resultsToSearch.map((row: any) => {
    const filter = row?.associatedGeoPoint.filter(function (
      element: AssociatedGeoPoint
    ) {
      return element !== undefined;
    });
    const rows = {
      id: row.id,
      username: row.username,
      email: row.email,
      role: row.userType,
      assignedSite:
        row?.associatedGeoPoint.length === 0
          ? [{ name: `${0}  ${t("Users_tables.columns_table_one.sites")}` }]
          : row?.associatedGeoPoint,
      assignedAction: row.scopesIds,
      assignedSiteId:
        row?.associatedGeoPoint.length === 0
          ? []
          : filter.map((site: AssociatedGeoPoint) => site?.id),
      isNew: false,
      actions: row.id,
      createdAt: row.createdAt,
      creator: "",
      password: "",
      contactEmail: "",
      phone: "",
      groups: row.groups.length >= 1 ? row.groups : [],
    };
    return rows;
  }) as any;

  const allUsersColumnsFilter = allUsersColumns?.filter(
    (columns: ColumnTables) => !columns.alwaysHide
  );

  return {
    allUsersColumns: allUsersColumnsFilter as GridColDef[],
    rowsAllUsersTable,
  };
};

export default CreateAllUsersColumns;
