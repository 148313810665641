import React, { useState, useMemo } from "react";
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { DColumns } from "../../../../redux/actions/data/DataDeclarations";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import { State } from "../../../../redux/slices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useStyles } from "../../../userGroups/creationUsers/stepsManagerUsers/FetchingColumnsManagers";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateLocationsColumnsRows = (
  rawColumns: DColumns[],
  rowModesModel: GridRowModesModel,
  handleSaveClick: (id: GridRowId) => () => void,
  handleCancelClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void,
  handleDeleteClick: (id: GridRowId) => () => void,
  toggleNomad: (id: GridRowId) => () => void,
  handleChange: (locationID: string, locationName: string, id: number) => void,
  handleChangeActions: (event: SelectChangeEvent<any>, id: number) => void,
  usersConfigRows: any
) => {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const { locations } = useSelector((state: State) => state.MapReducer);
  const { actionsValues, tryToCreateSomething, createNewSite } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const [searchText, setSearchText] = useState("");

  const displayedOptionsActions = useMemo(
    () =>
      actionsValues.filter((option) => containsText(option.name, searchText)),
    [searchText]
  );

  const columnNames = {
    id: t("Data.table.Details"),
    username: t("Create_site.config_users.username"),
    assignedSite: t("Create_site.config_users.site"),
    assignedAction: t("Create_site.config_users.actions"),
    varietyList: t("Create_site.config_users.variety"),
    nomadUser: t("Create_site.config_users.nomad"),
    actions: "actions",
  };

  const nonamdUserObject = [
    {
      name: "Nomad user",
      id: "nomad",
    },
  ];

  const locWithNomad = locations.concat(nonamdUserObject as any);

  const locTransform = [
    {
      name: createNewSite.siteName,
      id: "upcomingSite",
    },
    ...locWithNomad,
  ];

  let columns: any[] = rawColumns.map((column: DColumns) => {
    if (column.field === "assignedSite") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: 220,
        alwaysHide: column.alwaysHide,
        disableColumnMenu: true,
        sortable: false,

        renderCell: (params: any) => {
          const nomadUser = params.row.nomadUser;
          const paramsRow = params;

          const handleRoleChange = (
            locationID: string,
            locationName: string
          ) => {
            handleChange(locationID, locationName, params.id);
          };

          const managingColor = () => {
            if (
              !tryToCreateSomething &&
              params.value.name !== t("Common_options.select")
            ) {
              return classes.singleSelect;
            }

            if (
              !tryToCreateSomething &&
              params.value.name === t("Common_options.select")
            ) {
              return classes.singleSelect;
            }

            if (
              tryToCreateSomething &&
              params.value.name === t("Common_options.select")
            ) {
              return classes.singleSelectError;
            }
            if (
              tryToCreateSomething &&
              params.value.name !== t("Common_options.select")
            ) {
              return classes.singleSelect;
            }
          };

          return (
            <Autocomplete
              className={managingColor()}
              sx={{ color: "red" }}
              value={params.value}
              options={
                createNewSite.siteName.length === 0
                  ? locWithNomad
                  : locTransform
              }
              getOptionLabel={(option) => option.name}
              onChange={(_e, value, reason) => {
                if (reason === "clear" || reason === "removeOption")
                  handleRoleChange("", ""); ///<=== problem here
                if (reason === "selectOption")
                  handleRoleChange(value?.id as any, value?.name as any);
                if (value?.id === ("Nomad" as any))
                  handleRoleChange("nomad", "Nomad");
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                  <MenuItem
                    disabled={option.id === "nomad" || nomadUser}
                    key={option.id}
                    {...optionProps}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        option.id === ("nomad" as any) && nomadUser
                          ? true
                          : selected
                      }
                    />
                    {option.name}
                  </MenuItem>
                );
              }}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    paramsRow.value.length === 0
                      ? t("Common_options.select")
                      : ""
                  }
                />
              )}
            />
          );
        },
      };
    }

    if (column.field === "assignedAction") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        minWidth: 200,
        type: "string",
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        renderCell: (params: any) => {
          const handleRoleChange = (event: any) => {
            handleChangeActions(event, params.id);
          };
          return (
            <FormControl
              className={
                !tryToCreateSomething ? classes.root : classes.rootError
              }
              sx={{ m: 1, width: 300 }}
            >
              <InputLabel sx={{ marginLeft: "-3px" }}>
                {params.value.length === 0 ? t("Common_options.select") : ""}
              </InputLabel>
              <Select
                multiple
                value={params.value}
                onChange={handleRoleChange}
                input={<OutlinedInput label="Chip" />}
                renderValue={(selected) => {
                  if (selected.length === 1) {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((roleId: any) => (
                          <Chip
                            key={roleId}
                            label={
                              actionsValues?.find((e) => e.id === roleId)?.name
                            }
                          />
                        ))}
                      </Box>
                    );
                  }

                  if (selected.length > 1) {
                    return (
                      <Chip
                        label={`${
                          actionsValues?.find((e) => e.id === selected[0])?.name
                        } + ${selected.length - 1} ${t(
                          "Common_options.others"
                        )}`}
                      />
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    value={searchText}
                    autoFocus
                    placeholder={t("Common_options.type_to_search")}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>

                {displayedOptionsActions.map((option, i) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      value={option?.id}
                      checked={params?.value?.includes(option.id)}
                    />
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      };
    }

    if (column.field === "nomadUser") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: 150,
        editable: false,
        type: "boolean",
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        renderCell: (params: any) => {
          return (
            <Switch
              checked={params.value}
              onClick={toggleNomad(params?.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
      };
    }

    if (column.field === "actions") {
      return {
        headerName: "",
        field: column.field,
        width: column.width ? column.width : 100,
        hide: !column.show,
        filterable: false,
        sortable: false,
        alwaysHide: column.alwaysHide,
        disableColumnMenu: true,
        cellClassName: "actions",
        renderCell: ({ id }: any) => {
          return [
            <GridActionsCellItem
              key={uuidv4()}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      };
    }

    return {
      field: column.field,
      headerName: (columnNames as never)[column.field],
      width: column.width,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      alwaysHide: column.alwaysHide,
      editable: false,
    };
  });

  let rowsCreateLoc = usersConfigRows?.map((row: any) => {
    const rows = {
      id: row.id,
      username: row.username,
      assignedSite: row.assignedSite,
      assignedAction: row.assignedAction,
      nomadUser: row.nomadUser,
      actions: row.id,
      isNew: false,
    };
    return rows;
  }) as any;

  const columnsFilter = columns?.filter((columns: any) => !columns.alwaysHide);

  return {
    rawColumns: columnsFilter,
    rowsCreateLoc,
  };
};

export default CreateLocationsColumnsRows;
