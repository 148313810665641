import React from "react";

import { Alert, Box, Button, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { State } from "../../../../redux/slices";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getLocalizedTextByLocaleVersionTwo } from "../../../../utils/localized-text";
import {
  AssociatedGeoPoint,
  LocalizedTextGrouping,
} from "../../../../redux/actions/users/userTypes";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import {
  setEditingUser,
  setSelectedUser,
  setUserValuesToEdit,
} from "../../../../redux/slices/userGroups/userGroupsSliceMultipleTables";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

// update user

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { initialState } from "../../../../redux/slices/userGroups/userGroupsSliceMultipleTables";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { userUpdateValues } from "../../../../redux/actions/users/userAsyncActions";
import { LoadingButton } from "@mui/lab";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { v4 as uuidv4 } from "uuid";

export const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "calc(100% - 20px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "calc(100% - 20px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    selectUser: () => {
      dispatch(setSelectedUser(null));
      dispatch(setEditingUser(false));
      dispatch(setUserValuesToEdit(initialState.userValuesUpdate));
    },
    setIsEditingUser: (userEditValues: any) => {
      dispatch(setEditingUser(true));
      dispatch(setUserValuesToEdit(userEditValues));
    },

    setUserEditValues: (values: any) => {
      dispatch(setUserValuesToEdit(values));
    },

    updateUser: (token: string, userId: string) => {
      dispatch(userUpdateValues({ token, userId }));
    },
  };
};

export const DetailUserView = () => {
  const [t] = useTranslation("global");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { selectUser, setIsEditingUser, setUserEditValues, updateUser } =
    SetActions();
  const {
    selectedUser,
    isEditingUser,
    userValuesUpdate,
    isUpdateLoading,
    isUpdateError,
  } = useSelector((state: State) => state.CreateTablesUsersReducer);

  const { locations } = useSelector((state: State) => state.MapReducer);
  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { actions } = useSelector((state: State) => state.MasterInfoReducer);
  const { user } = useSelector((state: State) => state.Authentication);

  const token = user?.token ? user.token : "";

  const createdAt = moment(selectedUser?.createdAt);
  createdAt.locale(locale);
  const createdAtModify = createdAt.format("l, LT");
  const [showMoreActions, setShowMore] = useState(false);
  const [showMoreLocations, setShowMoreLocations] = useState(false);

  const assignedActions = selectedUser?.assignedAction?.map(
    (x: LocalizedTextGrouping) => {
      return getLocalizedTextByLocaleVersionTwo(locale, x.name.localizedTexts);
    }
  );

  const assignedSites = selectedUser?.assignedSite?.map(
    (x: AssociatedGeoPoint) => {
      return x;
    }
  );

  const showActions = assignedActions?.slice(
    0,
    !showMoreActions && assignedActions.length > 3 ? 3 : assignedActions.length
  );

  const showLocations = assignedSites?.slice(
    0,
    !showMoreLocations && assignedSites.length > 3 ? 3 : assignedSites.length
  );

  // disable for now
  // const handleChange = (newPhone: any) => {
  //   setUserEditValues({
  //     ...userValuesUpdate,
  //     phone: newPhone,
  //   });
  // };

  const selectLocalePhoneDefault = () => {
    if (locale === "fr") {
      return { code: "FR", label: "France", phone: "33", suggested: true };
    }
    if (locale === "en") {
      return {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
      };
    }

    if (locale === "es") {
      return {
        code: "MX",
        label: "Mexico",
        phone: "52",
        suggested: true,
      };
    }

    if (locale === "de") {
      return {
        code: "DE",
        label: "Germany",
        phone: "49",
        suggested: true,
      };
    } else {
      return {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
      };
    }
  };

  const selectLocalePhoneDefaultTwo = () => {
    if (locale === "fr") {
      return "+33";
    }
    if (locale === "en") {
      return "+1";
    }

    if (locale === "es") {
      return "+52";
    }

    if (locale === "de") {
      return "+49";
    } else {
      return "+1";
    }
  };

  const DetailUser = (
    <div className="modal-detail-user-container">
      <div>
        <div className="username-title-modal">{selectedUser?.username}</div>
        <div className="username-subtitle-modal">{`${t(
          "Users_details.created"
        )} ${
          selectedUser?.creator === ""
            ? t("Users_details.at")
            : t("Users_details.by")
        } ${createdAtModify}`}</div>
      </div>
      <div className="access-and-permissions">
        <div className="access-title">{t("Users_details.access_perm")}</div>
        <div className="access-perm-info">
          <div className="access-values-users">
            <div className="option-left mt-2">
              <span className="title-view-span">{t("Users_details.role")}</span>
            </div>
            <div className="option-right">
              <Chip
                size="small"
                label={selectedUser ? selectedUser?.role : ""}
                variant="outlined"
              />
            </div>
          </div>
          <div className="access-values-users">
            <div className="option-left">
              <span className="title-view-span">
                {t("Users_details.assigned_site")}
              </span>
            </div>
            <div className="option-right-list">
              {assignedSites?.length >= 1 ? (
                showLocations?.map((actions: any) => {
                  return <li className="list-value-user">{actions?.name}</li>;
                })
              ) : (
                <li className="list-value-user">
                  {`${assignedSites?.length} ${t(
                    "Users_tables.columns_table_one.actions"
                  )}`}
                </li>
              )}
              {assignedSites && assignedSites?.length > 3 ? (
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() =>
                    setShowMoreLocations(showMoreLocations ? false : true)
                  }
                  endIcon={
                    !showMoreLocations ? <ExpandMoreIcon /> : <ExpandLessIcon />
                  }
                >
                  {!showMoreLocations
                    ? `+ ${assignedSites?.length - 3} ${t(
                        "Common_options.others"
                      )} `
                    : t("Common_options.show_less")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="access-values-users last-value-user-details">
            <div className="option-left">
              <span className="title-view-span">
                {t("Users_details.assigned_action")}
              </span>
            </div>
            <div className="option-right-list">
              {assignedActions?.length >= 1 ? (
                showActions?.map((actions: any) => {
                  return <li className="list-value-user">{actions}</li>;
                })
              ) : (
                <li className="list-value-user">
                  {`${assignedActions?.length} ${t(
                    "Users_tables.columns_table_one.actions"
                  )}`}
                </li>
              )}
              {assignedActions && assignedActions?.length > 3 ? (
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => setShowMore(showMoreActions ? false : true)}
                  endIcon={
                    !showMoreActions ? <ExpandMoreIcon /> : <ExpandLessIcon />
                  }
                >
                  {!showMoreActions
                    ? `+ ${assignedActions?.length - 3} ${t(
                        "Common_options.others"
                      )} `
                    : t("Common_options.show_less")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="divider-user-details" />
        <div className="access-title">{t("Users_details.login_info")}</div>
        <div className="access-values-users">
          <div className="option-left">
            <span className="title-view-span">{t("Users_details.pass")}</span>
          </div>
          <div className="option-right-list">
            <li className="list-value-user">
              {selectedUser?.password === ""
                ? t("Common_options.null")
                : selectedUser?.password}
            </li>
          </div>
        </div>

        <div className="access-values-users">
          <div className="option-left">
            <span className="title-view-span">{t("Users_details.email")}</span>
          </div>
          <div className="option-right-list">
            <li className="list-value-user">
              {selectedUser?.email === ""
                ? t("Common_options.null")
                : selectedUser?.email}
            </li>
          </div>
        </div>

        <div className="access-values-users last-value-user-details">
          <div className="option-left">
            <span className="title-view-span">{t("Users_details.phone")}</span>
          </div>
          <div className="option-right-list">
            <li className="list-value-user last-value-user-details">
              {selectedUser?.phone === ""
                ? t("Common_options.null")
                : selectedUser?.phone}
            </li>
          </div>
        </div>
      </div>
      <div className="buttons-user-view">
        <div className="container-buttons-users-actions">
          <div>
            <Button
              onClick={() =>
                setIsEditingUser({
                  ...userValuesUpdate,
                  role: selectedUser.role,
                  site: selectedUser.assignedSiteId[0], /// <= Modify just site-user
                  phoneCode: selectLocalePhoneDefault(),
                  phone: selectLocalePhoneDefaultTwo(),
                  copyOfActions: {
                    valuesSelected: selectedUser?.assignedAction?.map(
                      (c: any) => ({
                        id: c.name.id,
                        name: getLocalizedTextByLocaleVersionTwo(
                          locale,
                          c.name.localizedTexts
                        ),
                        scenarioId: c.scenarioId,
                        scopeId: c.scopeId,
                      })
                    ),
                  },
                  contactEmail: selectedUser.email,
                  actions: {
                    allValuesSelected: false,
                    valuesSelected: selectedUser?.assignedAction?.map(
                      (c: any) => ({
                        id: c.name.id,
                        name: getLocalizedTextByLocaleVersionTwo(
                          locale,
                          c.name.localizedTexts
                        ),
                        scenarioId: c.scenarioId,
                        scopeId: c.scopeId,
                      })
                    ),
                  },
                })
              }
              endIcon={<EditIcon />}
              sx={{ textTransform: "none" }}
              disabled={selectedUser?.role !== "site"}
            >
              {t("Common_options.Edit")}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => selectUser()}
              sx={{ textTransform: "none" }}
            >
              {t("Fleet_overview.search_sidebar.close")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  // Update user

  const handleLocationChange = (event: SelectChangeEvent) => {
    setUserEditValues({
      ...userValuesUpdate,
      site: event.target.value as string,
    });
  };

  const AutoCompleteSelectActions = () => {
    const loading = false;
    const actionOptions = actions?.data?.map((c: any) => ({
      id: c?.name?.id,
      name: getLocalizedTextByLocaleVersionTwo(locale, c?.name?.localizedTexts),
      scenarioId: c.id,
    }));

    return (
      <div>
        <Autocomplete
          multiple
          disabled={loading || isUpdateLoading}
          options={actionOptions}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, params) => {
            const filter = createFilterOptions();
            const filtered = filter(options, params as any);
            return [
              { name: t("Dashboard.setAllLocations"), id: "all" },
              ...filtered,
            ];
          }}
          disableCloseOnSelect
          value={userValuesUpdate.actions.valuesSelected as any}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  option.id === "all"
                    ? !!(
                        userValuesUpdate.actions.valuesSelected.length ===
                        actionOptions.length
                      )
                    : selected
                }
              />
              {option.name}
            </li>
          )}
          renderTags={(list) => {
            if (list.length === 1) {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {list.map((value: any) => (
                    <Chip key={value.id} label={value.name} />
                  ))}
                </Box>
              );
            }

            if (list.length > 1) {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    label={`${list[0].name} , ${list[1]?.name}, ${
                      list.length >= 3
                        ? `+ ${list.length - 2} ${t("Common_options.others")}`
                        : ""
                    }  `}
                  />
                </Box>
              );
            }
          }}
          onChange={(event, newValue) => {
            if (newValue.find((option) => option.id === "all"))
              return setUserEditValues({
                ...userValuesUpdate,
                actions: {
                  allValuesSelected: false,
                  valuesSelected:
                    userValuesUpdate.actions.valuesSelected.length ===
                    actionOptions.length
                      ? []
                      : actionOptions,
                },
              });

            setUserEditValues({
              ...userValuesUpdate,
              actions: {
                allValuesSelected: false,
                valuesSelected: newValue,
              },
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              label={t("Users_details.assigned_action")}
            />
          )}
        />
      </div>
    );
  };

  const UpdateUser = (
    <div className="modal-detail-user-container">
      <div>
        <div className="username-title-modal">
          {`${t("Common_options.Edit")} ${selectedUser?.username}`}
        </div>
      </div>
      <div>
        <div className="access-title">{t("Users_details.access_perm")}</div>
        <div>
          {isUpdateError ? <Alert severity="error">{isUpdateError}</Alert> : ""}
        </div>
        <div className="access-perm-info">
          <div className="access-values-users spacer-bottom"></div>
          <div className="access-values-users spacer-bottom">
            <div className="option-left-update mt-2">
              <FormControl fullWidth>
                <InputLabel>{t("Users_details.assigned_site")}</InputLabel>
                <Select
                  label={t("Users_details.assigned_site")}
                  value={userValuesUpdate?.site as any}
                  onChange={handleLocationChange}
                  disabled={isUpdateLoading}
                >
                  {locations.map((location) => {
                    return (
                      <MenuItem
                        key={`${location.name}${uuidv4()}`}
                        value={location.id}
                      >
                        {location.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="auto-complete-actions-user spacer-bottom">
            <div className="option-left-update-autocomplete mt-2">
              {AutoCompleteSelectActions()}
            </div>
          </div>

          <div className="access-title">{t("Users_details.login_info")}</div>
          <div className="access-values-users spacer-bottom">
            <div className="option-left-update mt-2">
              <TextField
                label={t("Users_details.email")}
                variant="outlined"
                type="email"
                size="medium"
                value={userValuesUpdate.contactEmail}
                sx={{ width: "70%" }}
                disabled={isUpdateLoading}
                onChange={(event) =>
                  setUserEditValues({
                    ...userValuesUpdate,
                    contactEmail: event.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          {/* <div className="access-values-users spacer-bottom">
            <div className="option-left-update mt-2">
              <MuiTelInput
                style={{ width: "70%" }}
                size="medium"
                label={t("Users_details.phone")}
                value={userValuesUpdate.phone}
                onChange={handleChange}
                langOfCountryName={selectLocalePhoneDefault().code}
                disabled={isUpdateLoading}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="buttons-user-view">
        <div className="container-buttons-users-actions">
          <div></div>
          <div>
            <div>
              <Button
                variant="text"
                onClick={() => selectUser()}
                disabled={isUpdateLoading}
                sx={{ textTransform: "none", marginRight: "2px" }}
              >
                {t("Common_options.Cancel")}
              </Button>

              <LoadingButton
                loading={isUpdateLoading}
                onClick={() => updateUser(token, selectedUser.id)}
                sx={{ textTransform: "none" }}
                variant="contained"
              >
                {t("Common_options.save_close")}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return <Box sx={style2}>{!isEditingUser ? DetailUser : UpdateUser}</Box>;
};
