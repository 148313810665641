import React, { FC } from "react";
import { State } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateLocationStep,
  CreateNewSite,
  CreateNewSiteSetValues,
  initialState,
  setCreateLocationStep,
} from "../../../redux/slices/location/createLocation";

import { FluxStandardAction } from "flux-standard-action";
import { coordinatesGeocoder } from "../../map/mapEditCreateDeleteLocations/MapEditCreateLocationSideBar";
import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";

import { fetchLocationMapboxCoordinates } from "../../../redux/actions/locations/locationsAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreateSiteValues: (value: CreateNewSite) => {
      dispatch(CreateNewSiteSetValues(value));
    },

    setStep: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },

    setSearchAddress: (longitude: number, latitude: number) => {
      dispatch(fetchLocationMapboxCoordinates({ longitude, latitude }));
    },
  };
};

export const StepTwoOptionB: FC<any> = () => {
  const [t] = useTranslation("global");
  const { createNewSite, createLocationStep, error } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const { locations } = useSelector((state: State) => state.MapReducer);

  const { identifier, latitude, longitude, siteName, createNewUsers } =
    createNewSite;

  const { setCreateSiteValues, setSearchAddress, setStep } = SetActions();

  const geocoder = new MapboxGeocoder({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
    localGeocoder: coordinatesGeocoder as unknown as (
      query: string
    ) => Result[],
    zoom: 4,
    placeholder: t(`Create_site.stepone.search`),
    mapboxgl: mapboxgl as any,
    reverseGeocode: true,
  });

  React.useEffect(() => {
    geocoder.addTo("#geocoder");
    const results = document.getElementById("result") as any;
    let searchResult = results?.innerText as void | FluxStandardAction<
      string,
      any,
      undefined
    >;

    geocoder.on("result", (e: any) => {
      searchResult =
        e.result.geometry.coordinates[1] < -90 ||
        e.result.geometry.coordinates[1] > 90
          ? console.log(e)
          : setSearchAddress(
              e.result.geometry.coordinates[0],
              e.result.geometry.coordinates[1]
            );

      geocoder.on("clear", () => {
        setCreateSiteValues({
          ...createNewSite,
          siteAddress: initialState.createNewSite.siteAddress,
          countryName: initialState.createNewSite.countryName,
          longitude: initialState.createNewSite.longitude,
          latitude: initialState.createNewSite.latitude,
        });
      });
    });
  }, []);

  const setStepConfig = (longitude: number, latitude: number) => {
    setSearchAddress(longitude, latitude);
    setStep({ ...createLocationStep, step: 2 });
  };

  const existingLocations = locations.map((locations) => locations.name);

  const theLocationExistValidator = (locationName: string) =>
    existingLocations.filter((val) => locationName === val);

  const showingLocationExistError = () => {
    if (theLocationExistValidator(siteName).length >= 1) {
      return (
        <Alert severity="error">
          {t("Create_multi_location.csv_validator.used_location")}
        </Alert>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Create_site.stepone.step")}</div>
      <div>
        <div className="step-title-create">
          {t("Create_site.stepone.create_title")}
        </div>
        <div>{showingLocationExistError()}</div>
        <div className="create-site-form" style={{ background: "#fff" }}>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1 } }}
            noValidate
            autoComplete="off"
          >
            <div className="input-create-loc-form">
              <TextField
                inputProps={{ style: { height: "31.5px" } }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("Create_site.stepone.site_name")}
                value={siteName}
                variant="outlined"
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    siteName: e.currentTarget.value,
                  })
                }
              />
            </div>

            <div className="container-address-form">
              <div className="container-address-input">
                <label className="form-label-input-address">
                  {t("Create_site.stepone.site_address")}
                </label>
                <div id="geocoder" />
              </div>
              <span className="or-span-create-loc">
                {t("Create_site.stepone.or")}
              </span>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                inputProps={{ style: { height: "31.5px" } }}
                error={!!error}
                sx={{
                  marginTop: "-10.5px",
                  width: 150,
                  maxWidth: "calc(100% - 20px)",
                }}
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    latitude: parseFloat(e.currentTarget.value),
                  })
                }
                value={latitude}
                label={t("Create_site.stepone.latitude")}
                variant="outlined"
              />
              -
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginTop: "-10.5px",
                  width: 150,
                  maxWidth: "calc(100% - 20px)",
                  marginRight: "24px",
                }}
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    longitude: parseFloat(e.currentTarget.value),
                  })
                }
                inputProps={{
                  style: { height: "31.5px" },
                  inputMode: "numeric",
                }}
                error={!!error}
                value={longitude}
                label={t("Create_site.stepone.longitude")}
                variant="outlined"
                type="number"
              />
              {error ? (
                <div className="error-find-location-create-location">
                  {error}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="create-site-form-2">
              {/* //For now disable */}
              {/* {AutoCompleteGroupsCreateLocations(t)} */}

              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: 406,
                  maxWidth: "calc(100% - 20px)",
                }}
                disabled
                inputProps={{ style: { height: "31.5px" } }}
                value={identifier}
                onChange={(e) =>
                  setCreateSiteValues({
                    ...createNewSite,
                    identifier: e.currentTarget.value,
                  })
                }
                label={t("Create_site.stepone.identifier")}
                variant="outlined"
              />
            </div>
            <div>
              <div className="title-need-new-users">
                {t("Create_site.stepone.more_users")}
              </div>
              <div className="option-create-users">
                <FormGroup sx={{ flexDirection: "inherit" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                      />
                    }
                    onChange={() =>
                      setCreateSiteValues({
                        ...createNewSite,
                        createNewUsers: true,
                      })
                    }
                    checked={!!createNewUsers}
                    label={t("Common_options.yes")}
                  />
                  <FormControlLabel
                    onChange={() =>
                      setCreateSiteValues({
                        ...createNewSite,
                        createNewUsers: false,
                      })
                    }
                    control={
                      <Checkbox
                        checked={!createNewUsers}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                      />
                    }
                    label={t("Common_options.no")}
                  />
                </FormGroup>
              </div>
            </div>
          </Box>
        </div>
        <Button
          variant="contained"
          sx={{ textTransform: "none", marginTop: "24px" }}
          disabled={
            !siteName ||
            !longitude ||
            !latitude ||
            theLocationExistValidator(siteName).length >= 1
          }
          onClick={() => setStepConfig(longitude, latitude)}
        >
          {t("Create_site.stepone.Continue")}
        </Button>
      </div>
      <div className="background-components-fix" />
    </div>
  );
};

export default StepTwoOptionB;
