import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import neutralLocImg from "../../../assets/images/Style=Neutral.png";
import { Badge, Button, Chip, IconButton, Modal } from "@mui/material";

/**
 *  @DataImplementionWithMapCurrentDisable
 */

/**
 *  @DataImplementionWithMapCurrentDisable
 */

// Material UI
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TableContainer from "@mui/material/TableContainer";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import ChatIcon from "@mui/icons-material/Chat";
import ModeIcon from "@mui/icons-material/Mode";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Skeleton } from "@mui/lab";
import Box from "@mui/material/Box";

import { State } from "../../../redux/slices";
import { PaperSideBar, TableSideBar } from "../../overview/OverviewStyles";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { LoadingCircularProgress } from "../../Loading";
import { Viewport } from "../../../redux/slices/map/mapTypes";

import {
  overviewSetTrueFalseToModifyLocation,
  setSideBarGeoLocOpenClose,
  setModalDeleteLocation,
  logEventsNewPosition,
  setViewport,
  setLatitudeEditCreateLocation,
  setLongitudeEditCreateLocation,
  setLocationNotification,
  setTypeOfActionMap,
} from "../../../redux/slices/map/map";
import { DeleteLocBody } from "../mapEditCreateDeleteLocations/OverviewDeleteLocBody";
import { fetchOrgGeoPointTypes } from "../../../redux/actions/map/mapAsyncActions";
import {
  resetDashboardState,
  setComesFromMap,
  setDashboardLoading,
  setDateActivityDashboard,
  setLocationNameDashboardInput,
  setMultipleLocations,
} from "../../../redux/slices/dashboard/dashboard";
import {
  fetchActivityPocketLabDataDashboard,
  fetchActivityPocketLabDataDashboard2,
  fetchDashboardLocationNames,
  fetchGeoLocDataDashboard,
  getPocketLabRecentVersion,
} from "../../../redux/actions/dashboard/dashboardAsyncActions";
import ActivityMiniChart from "./MapSidebarMiniChart";
import { inarixColors } from "inarix-theme";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setSideBarLocationClose: (viewport: Viewport) => {
      dispatch(setSideBarGeoLocOpenClose(false));
      dispatch(setViewport({ ...viewport, pitch: 0 }));
    },

    openModaleDeleteLocation: () => dispatch(setModalDeleteLocation(true)),
    closeModalDeleteLocation: () => dispatch(setModalDeleteLocation(false)),

    setComesFromMap: () => dispatch(setComesFromMap(true)),

    modifyLocationPrepare: (
      token: string,
      latitude: number,
      longitude: number
    ) => {
      dispatch(setTypeOfActionMap("editLocation"));
      dispatch(overviewSetTrueFalseToModifyLocation(true));
      dispatch(setSideBarGeoLocOpenClose(false));
      dispatch(fetchOrgGeoPointTypes(token));
      dispatch(setLatitudeEditCreateLocation(latitude));
      dispatch(setLongitudeEditCreateLocation(longitude));
      dispatch(logEventsNewPosition({ lng: longitude, lat: latitude }));
    },

    selectLocations: (locations: any) => {
      dispatch(setMultipleLocations(locations));
    },

    setLocationFilterNotification: (locationName: string) =>
      dispatch(setLocationNotification(locationName)),

    fetchactionsAfterPush: (token: string) => {
      console.log("Data implementation on map disable");
    },

    setActivityDateDashboard: (date: Date[]) =>
      dispatch(setDateActivityDashboard(date)),

    fetchActivity: (token: string, id: any) => {
      dispatch(fetchGeoLocDataDashboard({ token, id }));
      dispatch(fetchActivityPocketLabDataDashboard({ token, id }));
      dispatch(fetchActivityPocketLabDataDashboard2({ token, id }));
    },

    setLocationNameDash: (name: string) =>
      dispatch(setLocationNameDashboardInput(name)),

    setLoadingDash: (visible: boolean) =>
      dispatch(setDashboardLoading(visible)),

    setLocationNameDashReset: () => {
      dispatch(resetDashboardState());
    },

    fetchActivityAll: (
      token: string
    ) => {
      dispatch(fetchDashboardLocationNames(token));
      dispatch(getPocketLabRecentVersion(token));

    },
  };
};

const SidebarLocation = (t: (arg0: string) => string, token: string) => {
  const navigate = useNavigate();
  const {
    setSideBarLocationClose,
    openModaleDeleteLocation,
    modifyLocationPrepare,
    setLocationFilterNotification,
    fetchactionsAfterPush,
    setActivityDateDashboard,
    fetchActivity,
    setLocationNameDash,
    setLoadingDash,
    setLocationNameDashReset,
    selectLocations,
    setComesFromMap,
    fetchActivityAll,
  } = SetActions();

  const {
    locationDevicesActivity,
    newGeoLoc,
    imgLocationLoading,
    imgLocationUrl,
    modifyLocation,
    sidebarGeoLoc,
    deleteLocationModal,
    viewport,
    locationDateActivity,
    locationDevicesActivityLoading,
    miniChartActivity,
  } = useSelector((state: State) => state.MapReducer);

  const { loadingDashboardInfo } = useSelector(
    (state: State) => state.DashboardReducer
  );

  const { isViewerOnly } = useSelector((state: State) => state.Authentication);

  const handleImgLocation = () => {
    if (imgLocationLoading) {
      return <Skeleton width="420px" height="225px" />;
    }
    if (imgLocationUrl) {
      return <img src={imgLocationUrl} className="img-loc" alt="geoloc" />;
    } else {
      return <img src={neutralLocImg} className="img-loc" alt="geoloc" />;
    }
  };

  const infoLocationActivity = () => {
    if (newGeoLoc && newGeoLoc.hasActivity > 0 && !modifyLocation) {
      return (
        <TableContainer component={PaperSideBar}>
          <TableSideBar>
            <TableHead>
              <TableRow>
                <TableCell>{t("Fleet_overview.table.devices")}</TableCell>
                <TableCell align="right">
                  {t("Fleet_overview.table.aquisitions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!locationDevicesActivityLoading ? (
                locationDevicesActivity?.map((row) => (
                  <TableRow
                    key={row.username}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Chip label={row?.username} />
                    </TableCell>
                    <TableCell align="right">{row?.count}</TableCell>
                  </TableRow>
                ))
              ) : (
                <div className="loading-activity-devices">
                  {LoadingCircularProgress()}
                </div>
              )}
            </TableBody>
          </TableSideBar>
        </TableContainer>
      );
    }
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  function PersistentDrawerLeft() {
    const theme = useTheme();
    const locationInfo = [{ name: newGeoLoc?.name, id: newGeoLoc?.id }];

    const handleDrawerClose = () => {
      setSideBarLocationClose(viewport);
    };

    const redirectUserNotification = () => {
      setLocationFilterNotification(newGeoLoc?.locationName as string);
      navigate(`/data`);
      fetchactionsAfterPush(token);
    };

    const redirectUserDashboard = () => {
      setLocationNameDashReset();
      setComesFromMap();
      fetchActivityAll(token);
      setTimeout(() => {
        setLoadingDash(true);
        setActivityDateDashboard(locationDateActivity);
        setLocationNameDash(newGeoLoc?.name as string);
      }, 1000);

      setTimeout(() => {
        selectLocations(locationInfo);
        fetchActivity(token, locationInfo);
      }, 1500);

      setTimeout(() => {
        navigate(`/dashboard`);
      }, 2500);
    };

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer
          sx={{
            width: 420,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 420,
              boxSizing: "border-box",
              position: "absolute",
              zIndex: 1,
              overflow: "auto",
              overflowX: "hidden",
            },
          }}
          variant="persistent"
          anchor="left"
          open={sidebarGeoLoc}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {newGeoLoc && !modifyLocation ? <div>{handleImgLocation()}</div> : ""}
          {newGeoLoc && !modifyLocation ? (
            <div className="container-title">
              <span className="loc-name-side mt-3">
                {newGeoLoc.locationName}
              </span>
              <span className="loc-name-side-notes">{newGeoLoc?.notes}</span>
              <span className="loc-name-side-address">
                {newGeoLoc?.address}
              </span>

              <div className="type-side-bar">
                <span className="loc-name-admin-name">
                  {newGeoLoc?.primaryContactName}
                </span>
                <span className="loc-name-side-email">{newGeoLoc?.email}</span>
              </div>
              <span className="loc-name-side-phone">
                {newGeoLoc?.telephone}
              </span>
              <div className="modify-edit-loc-postion">
                <div className="position-notification-button">
                  <IconButton
                    onClick={() =>
                      newGeoLoc ? redirectUserNotification() : ""
                    }
                    size="large"
                    color="inherit"
                  >
                    {newGeoLoc?.unAckNotifications > 0 ? (
                      <Badge badgeContent={newGeoLoc?.unAckNotifications}>
                        <ChatIcon />
                      </Badge>
                    ) : (
                      <ChatIcon />
                    )}
                  </IconButton>
                </div>
                {!isViewerOnly ? (
                  <Button
                    variant="text"
                    startIcon={<ModeIcon />}
                    sx={{
                      color: inarixColors.primary_text_inarix,
                      textTransform: "lowercase",
                      "&:hover": {
                        color: inarixColors.main_inarix,
                      },
                    }}
                    onClick={() =>
                      modifyLocationPrepare(
                        token,
                        newGeoLoc.latitude,
                        newGeoLoc.longitude
                      )
                    }
                  >
                    {t("Fleet_overview.overview_panel.editLoc")}
                  </Button>
                ) : (
                  ""
                )}
                {!isViewerOnly ? (
                  <Button
                    variant="text"
                    startIcon={<DeleteIcon />}
                    sx={{
                      color: inarixColors.primary_text_inarix,
                      textTransform: "lowercase",
                      "&:hover": {
                        color: inarixColors.modal_danger_color,
                      },
                    }}
                    onClick={() => openModaleDeleteLocation()}
                  >
                    {t("Fleet_overview.overview_panel.deleteLoc")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="divider-sidebar" />
          <div className="minichart-position">
            {ActivityMiniChart(miniChartActivity, t)}
          </div>
          <div className="button-dashboard-container">
            {!loadingDashboardInfo ? (
              <Button
                variant="contained"
                size="large"
                data-cy="login"
                type="submit"
                className=""
                sx={{ width: "352px" }}
                onClick={() => redirectUserDashboard()}
              >
                {t("Fleet_overview.overview_panel.open_site_dashboard")}
              </Button>
            ) : (
              LoadingCircularProgress()
            )}
          </div>

          {infoLocationActivity()}

          <Modal open={deleteLocationModal}>{DeleteLocBody(token)}</Modal>
        </Drawer>
      </Box>
    );
  }

  return PersistentDrawerLeft();
};

export default SidebarLocation;
