import { createSlice } from "@reduxjs/toolkit";
import { listActionsScenariosGeneral } from "../../actions/masterInfoActions/ActionsScenarios";

export interface MasterInfoState {
  actions: any;
  errorFetchActions: string;
  loadingFetchingActions: boolean;
  scopesFromActionsToUsers: any;
}

export const initialState: MasterInfoState = {
  actions: [],
  errorFetchActions: "",
  loadingFetchingActions: false,
  scopesFromActionsToUsers: [],
};

export const CreateMasterInfoSlice = createSlice({
  name: "MasterInfoReducer",
  initialState,
  reducers: {
    logOutMasterInfo() {
      return initialState;
    },

    setScopesForUsers(state, action) {
      return {
        ...state,
        scopesFromActionsToUsers: action.payload,
      };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(listActionsScenariosGeneral.pending, (state) => {
        state.errorFetchActions = "";
        state.loadingFetchingActions = true;
      })
      .addCase(listActionsScenariosGeneral.fulfilled, (state, actions) => {
        state.loadingFetchingActions = false;
        state.actions = actions.payload;
      })
      .addCase(listActionsScenariosGeneral.rejected, (state, actions) => {
        state.loadingFetchingActions = false;
        state.errorFetchActions = actions.payload as string;
      });
  },
});

export const { logOutMasterInfo, setScopesForUsers } =
  CreateMasterInfoSlice.actions;

export const MasterInfo = CreateMasterInfoSlice.reducer;
