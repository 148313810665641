import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";

import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import {
  ButtonCloseOverviewSideBars,
  CloseIconInarix,
  ListItemButtonInarix,
  MyLocationIconInarix,
  TextFieldSearch,
} from "../OverviewStyles";

import { Viewport } from "../../../redux/slices/map/mapTypes";
import { ThunkDispatch } from "@reduxjs/toolkit";
import themeInarix from "../../StylesMuiInarix";
import { State } from "../../../redux/slices";
import {
  resetMapModifyLocation,
  searchLocationSuccess,
  setSearchLocation,
  setSearchSidebarOpenClose,
  setViewport,
} from "../../../redux/slices/map/map";
import {
  manageLaboImgWithoutShadows,
  manageSiloImgWithoutShadows,
} from "../MapHelpers";
import { OrgGeoPointData } from "../../../redux/actions/locations/locationsTypes";
import { skeletonSearchLocatioMap } from "../../Helpers";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    sideBarSearchClose: () => dispatch(setSearchSidebarOpenClose(false)),

    modifyLocationFalse: () => dispatch(resetMapModifyLocation()),
    resetLocations: (locations: OrgGeoPointData) =>
      dispatch(searchLocationSuccess(locations)),

    setNameLocation: (value: string) => dispatch(setSearchLocation(value)),
    setNewViewport: (viewport: Viewport) => dispatch(setViewport(viewport)),
  };
};

const SidebarSearch = (t: (arg0: string) => string, token: string) => {
  const { sideBarSearchClose, setNameLocation, setNewViewport } = SetActions();

  const {
    modifyLocation,
    searchLocation,
    viewport,
    sidebarSearch,
    loadingLocationsSidebarSearch,
    locations,
  } = useSelector((state: State) => state.MapReducer);

  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const widthSideMenu = () => {
    if (sidebarSearch && !modifyLocation) {
      return "side-nav";
    }

    if (!sidebarSearch || (!sidebarSearch && !modifyLocation)) {
      return "new-side-bar-off";
    }
  };

  const optionsLocations = locations
    ?.filter((locations: OrgGeoPointData) => {
      const locationNormalize = locations.name
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");

      if (searchLocation === "") {
        return "";
      } else if (
        locationNormalize?.toLowerCase().includes(searchLocation.toLowerCase())
      ) {
        return locations;
      }
      return "";
    })
    .map((locations: OrgGeoPointData) => {
      const typeOflocation = () => {
        const silo = 1;
        const labo = 2;
        if (locations.typeId === silo) {
          return "Silo";
        }
        if (locations.typeId === labo) {
          return "Labo";
        }
      };

      return (
        <div key={locations.id}>
          <ListItemButtonInarix
            onClick={() => {
              setNewViewport({
                ...viewport,
                longitude: locations.longitude,
                latitude: locations.latitude,
                zoom: 16,
                transitionDuration: 1,
              });
            }}
          >
            <ListItemIcon sx={{ boxShadow: "" }}>
              {manageSiloImgWithoutShadows(locations)}
              {manageLaboImgWithoutShadows(locations)}
            </ListItemIcon>
            <ListItemText
              primary={locations.name}
              secondary={typeOflocation()}
            />
            <ListItemIcon>
              <MyLocationIconInarix />
            </ListItemIcon>
          </ListItemButtonInarix>
          <div className="divider-search-sidebar-locations" />
        </div>
      );
    });

  const noResultSearch = () => {
    if (optionsLocations.length === 0 && searchLocation.length >= 2) {
      return (
        <div>
          <WrongLocationIcon />
          {t("Fleet_overview.search_sidebar.no_results")}
        </div>
      );
    }
  };

  const NewSidebarSearch = () => {
    return (
      <div className={widthSideMenu()}>
        <ButtonCloseOverviewSideBars
          onClick={sideBarSearchClose}
          data-cy="close-button-search"
          variant="text"
          startIcon={<CloseIconInarix />}
        >
          {t("Fleet_overview.search_sidebar.close")}
        </ButtonCloseOverviewSideBars>

        <div data-cy="title-search" className="title-search">
          <span> {t("Fleet_overview.search_sidebar.search")}</span>
        </div>
        <div className="divider-search-sidebar" />

        <div className="input-search-location-sidebar">
          <ThemeProvider theme={themeInarix(locale)}>
            <TextFieldSearch
              data-cy="input-search-location-sidebar"
              label={t("Fleet_overview.search_sidebar.search_any")}
              value={searchLocation}
              onChange={(e) => setNameLocation(e.currentTarget.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ThemeProvider>
        </div>

        {!loadingLocationsSidebarSearch ? (
          <List>
            <div
              id="no-result-search"
              className="no-result-styles-sidebar-search"
            >
              {noResultSearch()}
            </div>
            {optionsLocations}
          </List>
        ) : (
          skeletonSearchLocatioMap()
        )}
      </div>
    );
  };

  return NewSidebarSearch();
};

export default SidebarSearch;
