import { createSlice } from "@reduxjs/toolkit";
import { DashboardLocIdInfo } from "../../actions/dashboard/dashboardTypes";
import { IdDataSBFilters, TableFilters } from "../data/data";
import { userUpdateValues } from "../../actions/users/userAsyncActions";
import { ColumnTables } from "./userTablestyping";

export interface UsersSBFilterValues {
  name: string;
  id: IdDataSBFilters;
}

export interface CheckboxFilters {
  id: any;
  name: string;
  isSelected: boolean;
}

export interface UsersSBFilters {
  inputSearch: string;
  rolesSelected: CheckboxFilters[];
  locations: {
    valuesSelected: DashboardLocIdInfo[];
    allValuesSelected: boolean;
  };

  actions: {
    valuesSelected: DashboardLocIdInfo[];
    allValuesSelected: boolean;
  };

  varieties: {
    valuesSelected: DashboardLocIdInfo[];
    allValuesSelected: boolean;
  };

  CreationDateSelected: CheckboxFilters[];

  filtersRoles: TableFilters | undefined;
  filtersLocation: TableFilters | undefined;
  filterActions: TableFilters | undefined;
  filterVarieties: TableFilters | undefined;
  filterCreationDate: TableFilters | undefined;
  filtersSelectedAfterFetch: number;
}
export interface userSBFilterValues {
  scopeId: string;
  scenarioId: number;
  name: string;
  id: IdDataSBFilters;
}

export interface UserTablesState {
  allUserColumnsTable: ColumnTables[];
  rowsNumber: number;
  usersSelected: string[];
  modalUserSelected: boolean;
  showFilters: boolean;
  usersSBFilters: UsersSBFilters;
  searchInputUsersTable: string;
  selectedUser: any | null;
  isEditingUser: boolean;
  userValuesUpdate: {
    role: string;
    site: number;
    sites: number[];
    actions: {
      allValuesSelected: boolean;
      valuesSelected: userSBFilterValues[];
    };
    copyOfActions: { valuesSelected: userSBFilterValues[] };
    contactEmail: string;
    phone: string;
    phoneCode: string;
  };

  isUpdateSuccess: boolean;
  isUpdateLoading: boolean;
  isUpdateError: string;
}

export const initialState: UserTablesState = {
  rowsNumber: 25,
  usersSelected: [],
  modalUserSelected: false,
  allUserColumnsTable: [
    {
      field: "id",
      sortable: true,
      width: 100,
      alwaysHide: true,
    },
    {
      field: "username",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },
    // {
    //   field: "role",
    //   sortable: false,
    //   width: 240,
    //   alwaysHide: false,
    // },
    {
      field: "assignedSite",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },
    {
      field: "assignedAction",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },

    // {
    //   field: "varieties",
    //   sortable: false,
    //   width: 240,
    //   alwaysHide: false,
    // },

    {
      field: "actions",
      sortable: false,
      width: 100,
      alwaysHide: false,
    },
  ],
  showFilters: false,

  usersSBFilters: {
    inputSearch: "",
    rolesSelected: [],
    filtersRoles: undefined,
    filtersLocation: undefined,
    filterActions: undefined,
    filterVarieties: undefined,
    filterCreationDate: undefined,
    filtersSelectedAfterFetch: 0,
    locations: { valuesSelected: [], allValuesSelected: false },

    actions: {
      valuesSelected: [],
      allValuesSelected: false,
    },
    varieties: {
      valuesSelected: [],
      allValuesSelected: false,
    },

    CreationDateSelected: [],
  },
  searchInputUsersTable: "",
  selectedUser: null,
  isEditingUser: false,
  userValuesUpdate: {
    role: "",
    site: 0,
    sites: [],
    actions: {
      allValuesSelected: false,
      valuesSelected: [],
    },
    copyOfActions: {
      valuesSelected: [],
    },
    contactEmail: "",
    phone: "",
    phoneCode: "",
  },
  isUpdateSuccess: false,
  isUpdateLoading: false,
  isUpdateError: "",
};

export const CreateTablesUsersSlice = createSlice({
  name: "CreateTablesUsersReducer",
  initialState,
  reducers: {
    setUserValuesToEdit(state, actions) {
      return {
        ...state,
        userValuesUpdate: actions.payload,
      };
    },
    setEditingUser(state, actions) {
      return {
        ...state,
        isEditingUser: actions.payload,
      };
    },

    setSelectedUser(state, action) {
      return {
        ...state,
        selectedUser: action.payload,
      };
    },
    setSearchInput(state, action) {
      return {
        ...state,
        searchInputUsersTable: action.payload,
      };
    },
    setAllUsersTableColumns(state, action) {
      return {
        ...state,
        allUserColumnsTable: action.payload,
      };
    },
    setNumberRowsUsers(state, action) {
      return {
        ...state,
        rowsNumber: action.payload,
      };
    },
    setSelectionModelUsers(state, action) {
      return {
        ...state,
        usersSelected: action.payload,
      };
    },

    showFilters(state, action) {
      return {
        ...state,
        showFilters: action.payload,
      };
    },

    setFiltersConfig(state, action) {
      return {
        ...state,
        usersSBFilters: action.payload,
      };
    },

    setUserUpdateSuccess(state, action) {
      return {
        ...state,
        isUpdateSuccess: action.payload,
      };
    },

    resetTablesState(state) {
      return {
        ...state,
        usersSBFilters: initialState.usersSBFilters,
      };
    },
    logOutUserTables() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userUpdateValues.pending, (state) => {
        state.isUpdateLoading = true;
        state.isUpdateSuccess = false;
      })
      .addCase(userUpdateValues.fulfilled, (state) => {
        state.isUpdateLoading = false;
        state.isUpdateSuccess = true;
        state.selectedUser = null;
        state.isEditingUser = false;
        state.isUpdateError = "";
        state.userValuesUpdate = initialState.userValuesUpdate;
      })
      .addCase(userUpdateValues.rejected, (state, action) => {
        state.isUpdateLoading = false;
        state.isUpdateError = action.payload as string;
      });
  },
});

export const {
  setUserUpdateSuccess,
  setUserValuesToEdit,
  setEditingUser,
  setSelectedUser,
  logOutUserTables,
  setSearchInput,
  setAllUsersTableColumns,
  resetTablesState,
  setNumberRowsUsers,
  setSelectionModelUsers,
  showFilters,
  setFiltersConfig,
} = CreateTablesUsersSlice.actions;

export const CreateTables = CreateTablesUsersSlice.reducer;
