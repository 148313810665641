import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import { ThunkDispatch } from "@reduxjs/toolkit";

import { useNavigate } from "react-router-dom";

import { State } from "../../../redux/slices";
import {
  CreateUsersStep,
  setCreateUsersStep,
  resetStateCreateUsers,
  setCreateUsers,
  initialState,
} from "../../../redux/slices/users/createUsers";
import { StepZero } from "./StepZeroChooseType";
import Step12CreateUsers from "./stepsSiteUsers/Step1-2";
import StepConfigCreateManagerUsers from "./stepsManagerUsers/UsersManagersConfigTable";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setSteps: (value: CreateUsersStep) => {
      dispatch(setCreateUsersStep(value));
    },

    cancel: () => {
      dispatch(resetStateCreateUsers());
    },

    resetCreateUsersValues: () => {
      dispatch(setCreateUsers(initialState.createUsers));
    },
  };
};

export const MenuCreateUsers = (
  createUserSteps: CreateUsersStep,
  t: (arg0: string) => string,
  setSteps: (value: CreateUsersStep) => void,
  navigate: any,
  cancel: any,
  resetCreateUsersValues: () => void,
  multiLocationLoading: boolean
) => {
  return (
    <div className="top-options-create-locations">
      <div className="spacer-div-back-button">
        {createUserSteps.step === 0 ? (
          <div />
        ) : (
          <Button
            sx={{
              fontSize: "14px",
              textTransform: "none",
              color: "#232128",
            }}
            onClick={() => {
              if (
                createUserSteps.place !== "configs" &&
                createUserSteps.step === 1 &&
                createUserSteps.option === "a"
              ) {
                setSteps({
                  ...createUserSteps,
                  step: createUserSteps.step - 1,
                });
                resetCreateUsersValues();
              }

              if (createUserSteps.place !== "configs") {
                setSteps({
                  ...createUserSteps,
                  step: createUserSteps.step - 1,
                });
              }

              if (
                createUserSteps.step === 1 &&
                createUserSteps.option === "a" &&
                createUserSteps.place === "configs"
              ) {
                navigate("/create-users");
              }
            }}
            startIcon={<ArrowBackIcon />}
            variant="text"
            disabled={multiLocationLoading}
          >
            {t("Common_options.Back")}
          </Button>
        )}
      </div>
      <div className="create-loc-title"> {t("Users_add_users.add")}</div>
      <div>
        <Button
          sx={{
            fontSize: "14px",
            textTransform: "none",
            color: "#232128",
          }}
          disabled={multiLocationLoading}
          variant="text"
          endIcon={<CloseIcon />}
          onClick={() => {
            navigate("/users");
            cancel();
          }}
        >
          {t("Common_options.Cancel")}
        </Button>
      </div>
    </div>
  );
};

const CreateUsers: FC<any> = () => {
  const [t] = useTranslation("global");
  const { setSteps, cancel, resetCreateUsersValues } = SetActions();
  const { createUserSteps } = useSelector(
    (state: State) => state.CreateUsersReducer
  );

  const { multiLocationLoading } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const navigate = useNavigate();

  return (
    <div className="create-sites-container">
      {MenuCreateUsers(
        createUserSteps,
        t,
        setSteps,
        navigate,
        cancel,
        resetCreateUsersValues,
        multiLocationLoading
      )}
      {createUserSteps.step === 0 ? <StepZero /> : ""}
      {createUserSteps.step === 1 && createUserSteps.option === "a" ? (
        <Step12CreateUsers />
      ) : (
        ""
      )}

      {createUserSteps.step === 1 && createUserSteps.option === "b" ? (
        <StepConfigCreateManagerUsers />
      ) : (
        ""
      )}
    </div>
  );
};

export default CreateUsers;
