import { FC, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectLanguage } from "./Login";
import NewInarixIcon from "../../assets/images/Union.svg";
import { useTranslation } from "react-i18next";
import { Badge, Button, TextField } from "@mui/material";
import { LoadingCircularProgress } from "../Loading";
import { State } from "../../redux/slices";
import { setEmailRecover } from "../../redux/slices/authentication/authentication";
import { recoverPassword } from "../../redux/actions/authentication/authentication";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ThemeProvider } from "@mui/material/styles";
import themeInarix from "../StylesMuiInarix";
import validator from "validator";
import React from "react";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setEmailRecoverPassword: (email: string) =>
      dispatch(setEmailRecover(email)),
    recoverPassword: () => dispatch(recoverPassword()),
  };
};

const EmailSentInfo = (
  error: string,
  errorAuthenticated: number | null,
  t: (arg0: string) => string
) => {
  return (
    <>
      <h4 className="title-form-login">{t("email_sent.email_sent")}</h4>
      <div className="inst-container2 first-div-inst">
        <span className="recover-pass-ins">{t("email_sent.desc_one")}</span>
      </div>
      <div className="inst-container2">
        <span className="recover-pass-ins">{t("email_sent.desc_two")}</span>
      </div>
      <div className="inst-container2">
        <span className="recover-pass-ins">{t("email_sent.desc_three")}</span>
      </div>
      <div className="buttons-container-recover">
        <Button
          variant="text"
          data-cy="login"
          type="submit"
          className="button-recover-pass"
          href="/login"
          sx={{ textTransform: "none" }}
        >
          {t("Login.login")}
        </Button>
      </div>
    </>
  );
};

const RecoverPassword: FC<any> = () => {
  const [t] = useTranslation("global");

  const { loading, recoverEmail, emailSent, error, errorAuthenticated } =
    useSelector((state: State) => state.Authentication);
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const { setEmailRecoverPassword, recoverPassword } = SetActions();
  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    recoverPassword();
  };

  const [isValidate, setValidate] = React.useState(false);

  const validate = (value: string) => {
    setEmailRecoverPassword(value);

    if (validator.isEmail(value)) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };

  return (
    <div className="reset-password-container">
      <div className="select-language-div-recover">
        <SelectLanguage />
      </div>
      <div className="form-recover-container">
        <div className="form-design-recover">
          <img
            data-cy="brand-logo-container"
            src={NewInarixIcon}
            className="inarix-icon-design"
            alt="inarix-icon"
          />
          {!emailSent ? (
            <>
              <h4 className="title-form-login">
                {t("Recover_pass.reset_pass")}
              </h4>
              <div className="inst-container first-div-inst">
                <Badge badgeContent={1} color="primary" />
                <span className="ml-4 recover-pass-ins">
                  {t("Recover_pass.first")}
                </span>
              </div>
              <div className="inst-container">
                <Badge badgeContent={2} color="primary" />
                <span className="ml-4 recover-pass-ins">
                  {t("Recover_pass.second")}
                </span>
              </div>
              <div className="inst-container">
                <Badge badgeContent={3} color="primary" />
                <span className="ml-4 recover-pass-ins">
                  {t("Recover_pass.third")}
                </span>
              </div>
              <form
                onSubmit={submitHandler}
                className="input-button-recover-container"
              >
                <ThemeProvider theme={themeInarix(locale)}>
                  <TextField
                    error={!!error}
                    helperText={error ? JSON.stringify(error) : undefined}
                    fullWidth
                    size="small"
                    data-cy="recover-email"
                    className="mb-5 input-recover-pass"
                    label={t("Recover_pass.email")}
                    value={recoverEmail}
                    type="email"
                    onChange={(e) => validate(e.currentTarget.value)}
                    required
                  />
                </ThemeProvider>
                <div className="buttons-container-recover">
                  {!loading ? (
                    <Button
                      variant="contained"
                      data-cy="login"
                      type="submit"
                      className="mb-4 button-recover-pass"
                      style={{ textTransform: "none" }}
                      disabled={!isValidate}
                    >
                      {t("Recover_pass.reset")}
                    </Button>
                  ) : (
                    <LoadingCircularProgress />
                  )}
                  <Button
                    variant="text"
                    data-cy="login"
                    type="submit"
                    className="button-recover-pass"
                    href="/login"
                    style={{ textTransform: "none" }}
                  >
                    {t("Login.login")}
                  </Button>
                </div>
              </form>
            </>
          ) : (
            EmailSentInfo(error, errorAuthenticated, t)
          )}
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
