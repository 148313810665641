import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../../redux/slices";
import { Alert, Box, Button, TextField } from "@mui/material";

import {
  initialState,
  setCreatedGroupValues,
  setSelectLocations,
} from "../../../redux/slices/fleet/locations";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AutoCompleteSelectLocations } from "./AutoCompleteEditAddToLocations";
import { style } from "../../userGroups/userGroupsSections/modals/userDetail";
import {
  createLocationGroup,
  moveSitesTogroup,
} from "../../../redux/actions/locations/locationsAsyncActions";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { v4 as uuidv4 } from "uuid";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreatedGroupValues: (values: any) => {
      dispatch(setCreatedGroupValues(values));
    },

    cancelAddGroup: () => {
      dispatch(setCreatedGroupValues(initialState.createdGroups));
      dispatch(setSelectLocations([]));
    },

    createNewGroup: (token: string) => {
      dispatch(createLocationGroup({ token }));
    },

    modifyGroup: (token: string) => {
      dispatch(moveSitesTogroup({ token }));
    },
  };
};

export const BodyModalAddGroups = (
  t: (arg0: string) => string,
  token: string
) => {
  const { cancelAddGroup, setCreatedGroupValues, createNewGroup, modifyGroup } =
    SetActions();
  const { createdGroups, groups } = useSelector(
    (state: State) => state.LocationsReducer
  );

  const handleChange = (event: SelectChangeEvent) => {
    setCreatedGroupValues({
      ...createdGroups,
      groupSelectedToMoveLocation: event.target.value as string,
    });
  };

  const selectGroup = () => {
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel>{t("Locations_groups.name_of_the_group")}</InputLabel>
          <Select
            value={
              !createdGroups.groupSelectedToMoveLocation
                ? ""
                : createdGroups.groupSelectedToMoveLocation
            }
            label={t("Locations_groups.name_of_the_group")}
            onChange={handleChange}
          >
            {groups.map((group: any) => {
              return (
                <MenuItem value={group.id} key={`${group.id}${uuidv4()}`}>
                  {group?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <Box sx={{ ...style }}>
      <div>
        {createdGroups.typeOfGroup === "new" ? (
          <div className="title-modal-create-group">
            {t("Locations_groups.Create")}
          </div>
        ) : (
          <div className="title-modal-create-group">
            {t("Locations_groups.add_to_group")}
          </div>
        )}
        {createdGroups.errorCreateGroup ? (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            {JSON.stringify(createdGroups.errorCreateGroup)}
          </Alert>
        ) : (
          ""
        )}

        {groups.length === 0 ? (
          <div className="desc-modal-create-group">
            {t("Locations_groups.Desc")}
          </div>
        ) : (
          ""
        )}
        <div className="container-actions-modal-create-group">
          <div className="text-field-modal-create-group">
            {createdGroups.typeOfGroup === "new" ? (
              <TextField
                fullWidth
                label={t("Locations_groups.Group_name")}
                variant="outlined"
                value={createdGroups.groupName}
                onChange={(e) =>
                  setCreatedGroupValues({
                    ...createdGroups,
                    groupName: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              selectGroup()
            )}
          </div>
          <div>{AutoCompleteSelectLocations(t)}</div>

          <div className="container-buttons-modal-create-group">
            <Button
              onClick={() => cancelAddGroup()}
              sx={{ textTransform: "none" }}
              variant="text"
            >
              {t("Common_options.Cancel")}
            </Button>
            <Button
              onClick={() =>
                createdGroups.typeOfGroup === "new"
                  ? createNewGroup(token)
                  : modifyGroup(token)
              }
              disabled={
                createdGroups.locationsSelectedAutoComplete.valuesSelected
                  .length === 0 ||
                (createdGroups.groupName.length === 0 &&
                  createdGroups.typeOfGroup === "new") ||
                (groups.length === 0 && createdGroups.typeOfGroup !== "new")
              }
              sx={{ textTransform: "none" }}
              variant="contained"
            >
              {t("Common_options.confirm")}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};
