import { ObjectRowsTransform } from "./StepThreeMultiLoc";
import { Alert, Button, Popover, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

export const inarixTips = (t: (arg0: string) => string) => {
  return (
    <div className="tips-container">
      <div className="title-inarix-tips">
        {t("Create_multi_location.step_two.tips")}
      </div>
      <div className="titles-tips-csv">
        {t("Create_multi_location.step_two.tip_one_title")}
      </div>
      <div className="subtitles-tips-csv spacer-csv-tips ">
        {t("Create_multi_location.step_two.tip_one_desc")}
      </div>

      <div className="titles-tips-csv">
        {t("Create_multi_location.step_two.tip_two_title")}
      </div>
      <div className="subtitles-tips-csv">
        {t("Create_multi_location.step_two.tip_two_desc_one")}
      </div>
      <div className="subtitles-tips-csv spacer-csv-tips ">
        {t("Create_multi_location.step_two.tip_two_desc_two")}
      </div>

      <div className="titles-tips-csv">
        {t("Create_multi_location.step_two.tip_three_title")}
      </div>
      <div className="subtitles-tips-csv">
        {t("Create_multi_location.step_two.latitude_validation")}
      </div>
      <div className="subtitles-tips-csv spacer-csv-tips ">
        {t("Create_multi_location.step_two.longitude_validation")}
      </div>

      <div className="titles-tips-csv mb-1">
        {t("Create_multi_location.step_two.example_desc")}
      </div>

      <div className="table-csv-example">
        <div className="cells-results">
          <div className="cell-option-csv">
            {t("Create_multi_location.step_two.site_name")}
          </div>
          <div className="cell-option-csv">Marseille-1</div>
          <div className="cell-option-csv">Paris-2</div>
        </div>
        <div className="cells-results">
          <div className="cell-option-csv">
            {t("Create_multi_location.step_two.latitude")}
          </div>
          <div className="cell-option-csv">43.294219</div>
          <div className="cell-option-csv">48.853928</div>
        </div>
        <div className="cells-results">
          <div className="cell-option-csv">
            {t("Create_multi_location.step_two.longitude")}
          </div>
          <div className="cell-option-csv">5.37722</div>
          <div className="cell-option-csv">2.348347</div>
        </div>
      </div>
    </div>
  );
};

export const managingErrorValidator = (
  encounterErrorLatitude: ObjectRowsTransform[],
  encounterErrorLongitude: ObjectRowsTransform[],
  encounterErrorSiteName: ObjectRowsTransform[],
  encounterErrorLocationExist: ObjectRowsTransform[],
  encounterRepeatedName: ObjectRowsTransform[],
  t: (arg0: string) => string,
  anchorEl: any,
  setAnchorEl: any
) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const basicPopover = (
    id: string | undefined,
    open: boolean,
    setAnchorEl: (value: HTMLButtonElement | null) => unknown,
    anchorEl: any
  ) => {
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          {inarixTips(t)}
        </Typography>
      </Popover>
    );
  };

  const isNewLocationRepeated = () => {
    const results = encounterRepeatedName.map((results) => {
      return (
        <span className="mr-2">
          {encounterRepeatedName.length === 1
            ? `${results.rowPosition}`
            : `${results.rowPosition},`}
        </span>
      );
    });

    return (
      <div>
        {results.length >= 1 ? (
          <div>
            <span className="mr-2">
              - {t("Create_multi_location.csv_validator.repeated")}
            </span>
            <span>
              {results.length === 1
                ? t("Users_managers.line")
                : `${t("Users_managers.line")} : `}
            </span>
            {results}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const isLatitudeGood = () => {
    const resultsLatitudeErrors = encounterErrorLatitude.map((results) => {
      return (
        <span className="mr-2">
          {encounterErrorLatitude.length === 1
            ? `${results.rowPosition}`
            : `${results.rowPosition},`}
        </span>
      );
    });

    return (
      <div>
        {resultsLatitudeErrors.length >= 1 ? (
          <div>
            <span className="mr-2">
              - {t("Create_multi_location.step_two.latitude_validation")}
            </span>
            <span>
              {resultsLatitudeErrors.length === 1
                ? `${t("Users_managers.line")} : `
                : `${t("Users_managers.line")} : `}
            </span>
            {resultsLatitudeErrors}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const isLongitudeGood = () => {
    const resultsLongitudeErrors = encounterErrorLongitude.map((results) => (
      <span className="mr-2">
        {encounterErrorLongitude.length === 1
          ? `${results.rowPosition}`
          : `${results.rowPosition},`}
      </span>
    ));

    return (
      <div>
        {resultsLongitudeErrors.length >= 1 ? (
          <div>
            <span className="mr-2">
              - {t("Create_multi_location.step_two.longitude_validation")}
            </span>

            <span>
              {resultsLongitudeErrors.length === 1
                ? `${t("Users_managers.line")} : `
                : `${t("Users_managers.line")} : `}
            </span>
            {resultsLongitudeErrors}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const isSiteNameGood = () => {
    const resultSiteErrors = encounterErrorSiteName.map(
      (results: ObjectRowsTransform) => (
        <span className="mr-2">
          {encounterErrorSiteName.length === 1
            ? `${results.rowPosition}`
            : `${results.rowPosition},`}
        </span>
      )
    );

    return (
      <div>
        {resultSiteErrors.length >= 1 ? (
          <div>
            <span className="mr-2">
              - {t("Create_multi_location.csv_validator.incorrect_site_name")}
            </span>
            <span>
              {resultSiteErrors.length === 1
                ? t("Users_managers.line")
                : `${t("Users_managers.line")} : `}
            </span>
            {resultSiteErrors}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const locationExists = () => {
    const resultsLocationErrors = encounterErrorLocationExist.map((results) => (
      <span className="mr-2">
        {encounterErrorLocationExist.length === 1
          ? `${results.siteName}, ${t("Users_managers.line")} : ${
              results.rowPosition
            }`
          : `${results.siteName}, ${t("Users_managers.lines")} : ${
              results.rowPosition
            }`}
      </span>
    ));

    return (
      <div>
        {resultsLocationErrors.length >= 1 ? (
          <div>
            <span className="mr-2">
              - {t("Create_multi_location.csv_validator.used_location")} :
            </span>
            {resultsLocationErrors}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <Alert
      className="mt-3"
      sx={{
        overflow: "auto",
        height: "100%",
        maxHeight: "450px",
        width: "40vw",
      }}
      severity="error"
    >
      <div>
        <div className="title-csv-error mb-3">
          {t("Create_multi_location.csv_validator.contains_error")}
        </div>

        <div className="title-csv-incorrect mb-2">
          {t("Create_multi_location.csv_validator.incorrect")}
        </div>
        <div>{isLatitudeGood()}</div>
        <div>{isLongitudeGood()}</div>
        <div>{isSiteNameGood()}</div>
        <div>{locationExists()}</div>
        <div>{isNewLocationRepeated()}</div>
        <div>{basicPopover(id, open, setAnchorEl, anchorEl)}</div>

        <div className="guide-csv-desc">
          <div className="mt-2">
            {t("Create_multi_location.csv_validator.find")}
            <Button
              aria-describedby={id}
              className="mb-1"
              sx={{
                textTransform: "none",
                color: "#5F2120",
                textDecoration: "underline",
              }}
              variant="text"
              onClick={handleClick}
            >
              {t("Create_multi_location.csv_validator.guide")}
            </Button>
          </div>
        </div>
      </div>
    </Alert>
  );
};

export default function locationsCreatedSnackBar(
  multiLocsCreated: number,
  t: (arg0: string) => string,
  multiLocationCreatedSuccess: boolean
) {
  return (
    <div>
      <Snackbar
        open={multiLocationCreatedSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // onClose={handleClose}
      >
        <Alert
          //  onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {multiLocsCreated} {t("Create_multi_location.csv_validator.created")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export function usersCreatedSnackBar(
  multiUsersCreated: number,
  t: (arg0: string) => string,
  multiUsersCreatedSuccess: boolean
) {
  return (
    <div>
      <Snackbar
        open={multiUsersCreatedSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          {multiUsersCreated} {t("Users_add_users.created_users")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export function usersUpdateSnackBar(
  isUpdateSuccess: boolean,
  t: (arg0: string) => string
) {
  return (
    <div>
      <Snackbar
        open={isUpdateSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          {t("Fleet_users.alert.Saved_1")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export function groupsCreatedSnackBar(
  multiUsersCreated: number,
  t: (arg0: string) => string,
  multiUsersCreatedSuccess: boolean
) {
  return (
    <div>
      <Snackbar
        open={multiUsersCreatedSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", textTransform: "lowercase" }}
        >
          {multiUsersCreated} {t("Locations_groups.Group")} <span />
          {t("Common_options.have_been_created")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export function groupsUpdateSnackBar(
  isUpdateSuccess: boolean,
  t: (arg0: string) => string
) {
  return (
    <div>
      <Snackbar
        open={isUpdateSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          {t("Fleet_users.alert.Saved_1")}
        </Alert>
      </Snackbar>
    </div>
  );
}
