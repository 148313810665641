import React, { FC, useEffect } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { State } from "../../../../redux/slices";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  initialState,
  CreateUsersStep,
  setCreateUsers,
  setRowsAlpha,
  setUserConfigRows,
  CreateUsersQuantity,
  setCreateUsersStep,
  setMaximumNumberFromUserCreations,
} from "../../../../redux/slices/users/createUsers";
import { generateFromEmail } from "unique-username-generator";
import { SiteUsersConfigRows } from "../../../../redux/actions/users/userTypes";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreateUsersValues: (value: CreateUsersQuantity) => {
      dispatch(setCreateUsers(value));
    },

    setUserConfigRows: (value: SiteUsersConfigRows[]) => {
      dispatch(setUserConfigRows(value));
    },

    resetRowsAlpha: () => {
      dispatch(setRowsAlpha(initialState.rowsAlpha));
    },

    setSteps: (value: CreateUsersStep) => {
      dispatch(setCreateUsersStep(value));
    },
    setMaximumNumber: (value: number) => {
      dispatch(setMaximumNumberFromUserCreations(value));
    },
  };
};

export const Step1_2CreateUsers: FC<any> = () => {
  const [t] = useTranslation("global");
  const [errorMaxValue, setErrorMaxValues] = React.useState("");
  const navigate = useNavigate();
  const {
    setCreateUsersValues,
    setUserConfigRows,
    resetRowsAlpha,
    setSteps,
    setMaximumNumber,
  } = SetActions();
  const { users } = useSelector((state: State) => state.UsersReducer);
  const { companyName } = useSelector((state: State) => state.Authentication);
  const { createUsers, createUserSteps } = useSelector(
    (state: State) => state.CreateUsersReducer
  );
  const { numberOfUserToCreate, usersToCreate } = createUsers;

  const companyNameUsers = companyName ? companyName : "error";

  const repeatelem = function (elem: any, n: number) {
    let arr: any[] = [];

    for (let i = 0; i <= n; i++) {
      arr = arr.concat(elem);
    }

    return setCreateUsersValues({
      ...createUsers,
      usersToCreate: arr,
      numberOfUserToCreate: arr.length,
    });
  };

  const previewUsers = usersToCreate.map((user, i) => {
    if (user === undefined || user === null) {
      return `${companyNameUsers}-${i + 1}`;
    } else {
      return `${user}-${i + 1}`;
    }
  });

  const existingUserNames = users.data.map((users) => users.username);

  const duplicated = previewUsers.filter((val) =>
    existingUserNames.includes(val)
  );

  const noDuplicated = previewUsers.filter(
    (val) => !existingUserNames.includes(val)
  );

  const usernameTransform = duplicated.map((usernameDuplicated) =>
    generateFromEmail(usernameDuplicated, 4)
  );

  const managingDuplicates = usernameTransform.concat(noDuplicated);

  // new
  const found = existingUserNames.filter((usernames: string) => {
    return usernames.startsWith(companyNameUsers);
  });

  const splitingElements = found.map((d) => d.split("-"));
  const convertSplitting = splitingElements.map((values) => {
    const [username, usernameNumber] = values;
    const yourObject = { username, usernameNumber: parseInt(usernameNumber) };
    return yourObject;
  });

  const getJustUsernameNumbers = convertSplitting.map((x) => x.usernameNumber);

  const largest = (arr: number[]) => {
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > max) max = arr[i];
    }
    return max;
  };

  const repeatelem2 = function (elem: any, n: number) {
    let arr: any[] = [];

    for (let i = 0; i <= n; i++) {
      arr = arr.concat(elem);
    }
    return arr;
  };

  const newValues = repeatelem2(companyNameUsers, numberOfUserToCreate - 1);

  const previewUsers2 = newValues.map((user, i) => {
    if (user === undefined || user === null) {
      return `-${i + 1}`;
    } else {
      return `${user}-${i + largest(getJustUsernameNumbers) + 1}`;
    }
  });

  const rowsTransform = managingDuplicates.map((r, i) => ({
    id: i,
    username: `${r}`,
    assignedSite: { name: t("Common_options.select") },
    assignedAction: [] as number[],
    nomadUser: false,
    isNew: false,
  }));
  const rowsTransformFound = previewUsers2.map((r, i) => ({
    id: i,
    username: `${r}`,
    assignedSite: { name: t("Common_options.select") },
    assignedAction: [] as number[],
    nomadUser: false,
    isNew: false,
  }));

  const splitingElementsTransform =
    found.length >= 1
      ? rowsTransformFound.map((d) => d.username.split("-"))
      : rowsTransform.map((d) => d.username.split("-"));

  const convertSplittingTransform = splitingElementsTransform.map((values) => {
    const [username, usernameNumber] = values;
    const yourObject = { username, usernameNumber: parseInt(usernameNumber) };
    return yourObject;
  });

  const getJustUsernameNumbersTransform = convertSplittingTransform.map(
    (x) => x.usernameNumber
  );

  const nextStep = () => {
    setMaximumNumber(largest(getJustUsernameNumbersTransform));
    setUserConfigRows(
      found.length >= 1
        ? (rowsTransformFound as SiteUsersConfigRows[])
        : (rowsTransform as SiteUsersConfigRows[])
    );
    navigate("/create-users/configs");
  };

  useEffect(() => {
    resetRowsAlpha();
    setSteps({ ...createUserSteps, place: "" });
  }, []);

  const showErrorMaxValues = () => {
    return <Alert severity="error">{errorMaxValue}</Alert>;
  };

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Create_site.create_users.step")}</div>
      <div>
        <div className="step-title-create">
          {t("Create_site.create_users.create")}
        </div>

        <div className="create-site-form" style={{ background: "#fff" }}>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1 } }}
            noValidate
            autoComplete="off"
          >
            <div className="input-create-loc-users-form">
              <TextField
                inputProps={{ style: { height: "31.5px" } }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("Create_site.create_users.number_users")}
                type="number"
                variant="outlined"
                value={
                  numberOfUserToCreate === 0 ? undefined : numberOfUserToCreate
                }
                onChange={(e) => {
                  if (parseInt(e.currentTarget.value) - 1 >= 100) {
                    repeatelem(usersToCreate[0], 98);
                    setErrorMaxValues(t("Users_add_users.max_number_user"));
                  } else {
                    repeatelem(
                      usersToCreate[0],
                      parseInt(e.currentTarget.value) - 1
                    );
                    setErrorMaxValues("");
                  }
                }}
              />
            </div>
            <div className="create-site-users-container">
              <div className="create-info-loc-user-names">
                <TextField
                  inputProps={{ style: { height: "31.5px" } }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={t("Create_site.create_users.usernames")}
                  placeholder={t("Create_site.create_users.placeholder")}
                  variant="outlined"
                  value={companyNameUsers}
                  disabled
                  onChange={(e) => {
                    repeatelem(e.currentTarget.value, numberOfUserToCreate - 1);
                  }}
                />
                <div className="mt-3">
                  {errorMaxValue ? showErrorMaxValues() : ""}
                </div>
              </div>

              <Alert
                sx={{
                  width: "580px",
                  maxWidth: "calc(100% - 20px)",
                }}
                severity="info"
              >
                {usersToCreate.length >= 1
                  ? `${t("Create_site.create_users.previewOneUsers")}  ${
                      found.length >= 1 ? previewUsers2 : managingDuplicates
                    }`
                  : t("Create_site.create_users.previewone")}
              </Alert>
            </div>
          </Box>
        </div>
        <Button
          disabled={numberOfUserToCreate === 0}
          variant="contained"
          sx={{ textTransform: "none", marginTop: "24px" }}
          onClick={nextStep}
        >
          {t("Create_site.stepone.Continue")}
        </Button>
      </div>
      <div className="background-components-fix" />
    </div>
  );
};

export default Step1_2CreateUsers;
