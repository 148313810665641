import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateLocationStep,
  setCreateLocationStep,
} from "../../../redux/slices/location/createLocation";
import { State } from "../../../redux/slices";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
  };
};

export const StepOneMultiLocations: FC<any> = () => {
  const [t] = useTranslation("global");
  const { setSteps } = SetActions();
  const { createLocationStep } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const csvData = [
    [
      t("Create_multi_location.step_two.site_name"),
      t("Create_multi_location.step_two.latitude"),
      t("Create_multi_location.step_two.longitude"),
    ],
    [`Marseille-1`, 43.294219, 5.37722],
    [`Paris-2`, 48.853928, 2.348347],
  ];

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Create_site.stepone.step")}</div>
      <div>
        <div className="step-title-create">
          {t("Create_multi_location.step_one.download")}
        </div>
        <div className="instruction-multi-locations">
          {t("Create_multi_location.step_one.desc_one")}
        </div>
        <div className="instruction-multi-locations mt-4">
          {t("Create_multi_location.step_one.desc_two")}
        </div>

        <div className="csv-container">
          <div className="file-container">
            <div className="my-sites-div">
              {t("Create_multi_location.step_one.csv_title")}.csv
            </div>
            <div className="my-sites-span">
              {t("Create_multi_location.step_one.csv_desc")}
            </div>
          </div>
          <CSVLink
            data={csvData}
            filename={t("Create_multi_location.step_one.csv_title")}
          >
            {t("Common_options.Download")}
          </CSVLink>
        </div>

        <Button
          variant="contained"
          onClick={() => setSteps({ ...createLocationStep, step: 2 })}
          sx={{ textTransform: "none" }}
        >
          {t("Create_site.stepone.Continue")}
        </Button>
      </div>
    </div>
  );
};
