import React, { FC, useEffect } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import {
  fetchUsersNewRequest,
  userProfiles,
} from "../../redux/actions/users/userAsyncActions";
import {
  initialState,
  setConfirmModal,
  setInfoConfirmModal,
  setTabNameLayout,
} from "../../redux/slices/layout";

import { useTranslation } from "react-i18next";

import {
  cleanMessageNDError,
  disableUsersErrors,
  setModalAddLocUsers,
  setNumberRowsUsers,
  setRemoveUsersModal,
  setSelectionModelUsers,
  setUserModal,
} from "../../redux/slices/fleet/users";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import { ThunkDispatch } from "@reduxjs/toolkit";
import NotFound from "../NotFound";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AllUsersTable } from "./userGroupsSections/tables/AllUserTable";
import SidebarUsersFilters from "./userGroupsSections/layout/SbFilters";
import {
  setEditingUser,
  setFiltersConfig,
  setSearchInput,
  setSelectedUser,
  setUserUpdateSuccess,
  setUserValuesToEdit,
  showFilters,
  UsersSBFilters,
} from "../../redux/slices/userGroups/userGroupsSliceMultipleTables";
import {
  fetchActionsNamesUsersConfig,
  fetchLocationsGroups,
} from "../../redux/actions/locations/locationsAsyncActions";
import {
  usersCreatedSnackBar,
  usersUpdateSnackBar,
} from "../userLocations/multilocation/InarixTips";
import { DetailUserView } from "./userGroupsSections/modals/userDetail";
import { getLocalizedTextByLocaleVersionTwo } from "../../utils/localized-text";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    listUsersInfo: (token: string, filters: UsersSBFilters) => {
      dispatch(fetchUsersNewRequest(token));
      dispatch(fetchLocationsGroups(token));
      dispatch(setTabNameLayout("/users"));
      dispatch(setSelectionModelUsers([]));
      dispatch(setFiltersConfig(filters));
      dispatch(userProfiles(token));
    },

    setUpdateSuccessToFalse: () => dispatch(setUserUpdateSuccess(false)),
    removeUsersError: () => dispatch(disableUsersErrors()),

    setRowsNumber: (rows: GridPaginationModel) =>
      dispatch(setNumberRowsUsers(rows)),

    setSelectionModel: (users: SelectionMode[]) => {
      dispatch(setSelectionModelUsers(users));
    },
    openUserModal: (open: boolean) => dispatch(setUserModal(open)),
    openUserRemoveModal: (open: boolean) => dispatch(setRemoveUsersModal(open)),

    closeAllModals: () => {
      dispatch(setUserModal(false));
      dispatch(dispatch(cleanMessageNDError()));
      dispatch(setInfoConfirmModal(initialState.infoConfirmModal));
      dispatch(setConfirmModal(false));
      dispatch(setRemoveUsersModal(false));
      dispatch(setModalAddLocUsers(false));
    },

    ///v2
    fetchActionsNames: (token: string) => {
      dispatch(fetchActionsNamesUsersConfig(token));
    },

    setVisibleSBExtraFilters: (visible: boolean) => {
      dispatch(showFilters(visible));
    },

    setInputValues: (values: string) => {
      dispatch(setSearchInput(values));
    },
    selectUser: () => {
      dispatch(setSelectedUser(null));
      dispatch(setEditingUser(false));
      dispatch(
        setUserValuesToEdit({
          role: "",
          site: 0,
          sites: [],
          actions: [],
          contactEmail: "",
          phone: "",
          phoneCode: "",
        })
      );
    },
  };
};

const UserGroups: FC<any> = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const {
    listUsersInfo,
    setRowsNumber,
    closeAllModals,
    setVisibleSBExtraFilters,
    fetchActionsNames,
    setInputValues,
    selectUser,
    setUpdateSuccessToFalse,
  } = SetActions();
  const [value, setValue] = React.useState(t("Users_tables.menu.all_users"));

  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0).toISOString();

  const creationDate = [
    {
      id: new Date(new Date().setDate(new Date().getDate() - 4)),
      name: t("Users_tables.creation_date.3"),
      isSelected: false,
    },
    {
      id: new Date(new Date().setDate(new Date().getDate() - 31)),
      name: t("Users_tables.creation_date.30"),
      isSelected: false,
    },
    {
      id: start,
      name: t("Users_tables.creation_date.year"),
      isSelected: false,
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const prepareUsersValues = () => {
    navigate("/create-users");
    fetchActionsNames(token);
  };
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );

  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const { users } = useSelector((state: State) => state.UsersReducer);

  const { verifyIfAllIsCreated, createManagers, userRoles } = useSelector(
    (state: State) => state.CreateUsersReducer
  );

  const {
    usersSBFilters,
    searchInputUsersTable,
    selectedUser,
    isUpdateSuccess,
  } = useSelector((state: State) => state.CreateTablesUsersReducer);

  const { actions } = useSelector((state: State) => state.MasterInfoReducer);

  const token = user?.token ? user.token : "";

  const bodeDetailUserView = DetailUserView();

  const roles = userRoles.map((roles: any) => ({
    id: roles.id,
    name: getLocalizedTextByLocaleVersionTwo(locale, roles.name.localizedTexts),
    isSelected: false,
  }));

  const dashboardOptions = [t("Users_tables.menu.all_users")];

  const globalComponets = () => {
    return (
      <div>
        <div className="button-add-users">
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={() => prepareUsersValues()}
            disabled={verifyIfAllIsCreated.createdUsers}
            endIcon={<AddIcon />}
          >
            {t("Users_tables.menu.add_users")}
          </Button>
        </div>

        <div className="input-filter-position-users">
          <FormControl
            id="input-filter-position-us"
            sx={{ width: "430px", maxWidth: "100% - 20%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("Users_tables.menu.input_search")}
            </InputLabel>
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={searchInputUsersTable}
              onChange={(e) => setInputValues(e.target.value)}
              label={t("Users_tables.menu.input_search")}
            />
          </FormControl>
          <Button
            sx={{ textTransform: "none", marginLeft: "10px", marginTop: "2px" }}
            startIcon={<FilterListIcon />}
            onClick={() => setVisibleSBExtraFilters(true)}
          >
            <div className="mr-1" style={{ fontWeight: 600, fontSize: "13px" }}>
              {t("Users_tables.menu.filters")}
            </div>
            {usersSBFilters.filtersSelectedAfterFetch >= 1
              ? `(${usersSBFilters.filtersSelectedAfterFetch})`
              : ""}
          </Button>
        </div>
        <div>
          <div className="container-tabs-users-tables ">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {dashboardOptions.map((options) => (
                      <Tab
                        sx={{ width: "150.75px", textTransform: "none" }}
                        key={options}
                        label={
                          options === t("Users_tables.menu.all_users")
                            ? `${t("Users_tables.menu.all_users")} (${
                                users.total
                              })`
                            : options
                        }
                        value={options}
                      />
                    ))}
                  </TabList>
                </Box>
                {dashboardOptions.map((options, key) => {
                  if (options === t("Users_tables.menu.all_users")) {
                    return (
                      <TabPanel key={key} value={options} title="">
                        <AllUsersTable />
                      </TabPanel>
                    );
                  }

                  return "";
                })}
              </TabContext>
            </Box>
          </div>
        </div>
        <div className="data-table-sidebar-styles">
          {SidebarUsersFilters(t, token, locale)}
        </div>

        {usersCreatedSnackBar(
          verifyIfAllIsCreated.usersCreated,
          t,
          verifyIfAllIsCreated.createdUsers
        )}
        {usersCreatedSnackBar(
          createManagers.usersManagersCreated,
          t,
          createManagers.createSuccess
        )}
        {usersUpdateSnackBar(isUpdateSuccess, t)}
        <Modal
          open={selectedUser !== null ? true : false}
          onClose={() => selectUser()}
        >
          {bodeDetailUserView}
        </Modal>
      </div>
    );
  };

  if (isUpdateSuccess) {
    setTimeout(() => {
      setUpdateSuccessToFalse();
    }, 3000);
  }

  useEffect(() => {
    listUsersInfo(token, {
      ...usersSBFilters,
      rolesSelected: roles,
      CreationDateSelected:
        usersSBFilters.filtersSelectedAfterFetch === 0
          ? creationDate
          : usersSBFilters.CreationDateSelected,
    });
    closeAllModals();
    setRowsNumber({ page: 1, pageSize: 25 });
  }, []);

  return (
    <Layout
      title={!isViewerOnly ? t("Fleet_users.table.My_users") : ""}
      component={!isViewerOnly ? globalComponets() : <NotFound />}
    />
  );
};

export default UserGroups;
