import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Paper,
  FormControlLabel,
  Divider,
  Chip,
  Button,
} from "@mui/material";
import { DashboardLocIdInfo } from "../../../../redux/actions/dashboard/dashboardTypes";
import { ListboxComponent } from "../../../data/dataExtraFilters/DataAutocompleteSites";
import { State } from "../../../../redux/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { createLocationLinkExistingUsers } from "../../../../redux/actions/locations/locationsAsyncActions";
import {
  resetStateCreatLocUsers,
  setMultiLocError,
  setValuesAutCompleteStep2,
} from "../../../../redux/slices/location/createLocation";
import { TokenPayload } from "../../../../redux/actions/authentication/authentication";
import jwt from "jsonwebtoken";
import { useNavigate } from "react-router-dom";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    createLocationLinkExistingUsers: (
      token: string,
      usersSelected: string[],
      realmId: number,
      withUsers: boolean
    ) => {
      dispatch(
        createLocationLinkExistingUsers({
          token,
          usersSelected,
          realmId,
          withUsers,
        })
      );
    },

    setValues: (values: any) => {
      dispatch(setValuesAutCompleteStep2(values));
    },
    removeError: () => {
      dispatch(setMultiLocError(""));
    },

    cancel: () => {
      dispatch(resetStateCreatLocUsers());
    },
  };
};

export const StepTwoNoCreateUsers: FC<any> = () => {
  const [t] = useTranslation("global");
  const { user } = useSelector((state: State) => state.Authentication);
  const { users } = useSelector((state: State) => state.UsersReducer);
  const {
    userSelectedAutocompleteStep2NoUsers,
    multiLocationCreatedSuccess,
    multilocError,
  } = useSelector((state: State) => state.CreateLocationReducer);
  const token = user?.token ? user.token : "";

  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;
  const { createLocationLinkExistingUsers, setValues, cancel, removeError } =
    SetActions();
  const navigate = useNavigate();

  const usersFilterOptions = users.data.filter(
    (values) => values.associatedGeoPoint === null
  );

  const usersOptions = usersFilterOptions.map((values) => ({
    name: values.username,
    id: values.id,
  }));

  const userSelected = userSelectedAutocompleteStep2NoUsers.valuesSelected.map(
    (values) => values.id
  );

  const AutoCompleteLinkUsersCreateLocations = () => {
    const loading = false;
    const transform = usersOptions?.map((c: any) => c);

    return (
      <Autocomplete
        sx={{
          height: "57px",
        }}
        disabled={loading}
        multiple
        disableListWrap={true}
        options={usersOptions as any}
        disableCloseOnSelect
        ListboxComponent={ListboxComponent}
        value={userSelectedAutocompleteStep2NoUsers.valuesSelected as any}
        getOptionLabel={(option: DashboardLocIdInfo) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_e, value, reason) => {
          if (reason === "clear" || reason === "removeOption")
            setValues({
              allValuesSelected: false,
              valuesSelected: [],
            });

          if (reason === "selectOption" && value.length === usersOptions.length)
            setValues({
              allValuesSelected: true,
              valuesSelected: transform as any,
            });

          setValues({ allValuesSelected: false, valuesSelected: value });

          if (reason === "selectOption" && usersOptions.length === 0)
            console.log("");
        }}
        renderInput={(params: { InputLabelProps: any }) => {
          return (
            <TextField
              {...params}
              label={t("Create_site.not_create_users.select")}
              variant="outlined"
              size="medium"
              sx={{
                width: "40vw",
                maxWidth: "calc(100% - 20px)",
                marginBottom: "24px",
                marginLeft: "24px",
                marginTop: "24px",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          );
        }}
        renderTags={(list) => {
          if (list.length === 1) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {list.map((value: any) => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </Box>
            );
          }

          if (list.length > 1) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <Chip
                  label={`${list[0].name} + ${list.length - 1} ${t(
                    "Common_options.others"
                  )}`}
                />
              </Box>
            );
          }
        }}
        renderOption={(props, option, { selected }) =>
          [props, option.name, selected] as React.ReactNode
        }
        PaperComponent={(paperProps) => {
          const { children, ...restPaperProps } = paperProps;
          return (
            <Paper {...restPaperProps}>
              <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
                <FormControlLabel
                  onClick={(e) => {
                    if (usersOptions.length >= 1) {
                      e.preventDefault();
                      !userSelectedAutocompleteStep2NoUsers.allValuesSelected
                        ? setValues({
                            allValuesSelected: true,
                            valuesSelected: transform,
                          })
                        : setValues({
                            allValuesSelected: false,
                            valuesSelected: [],
                          });
                    }
                  }}
                  disabled={usersOptions.length === 0}
                  label={t("Create_site.not_create_users.select")}
                  control={
                    <Checkbox
                      disabled={usersOptions.length === 0}
                      checked={
                        userSelectedAutocompleteStep2NoUsers.allValuesSelected
                      }
                    />
                  }
                />
              </Box>
              <Divider />
              {children}
            </Paper>
          );
        }}
        renderGroup={(params) => params as unknown as React.ReactNode}
      />
    );
  };

  if (multiLocationCreatedSuccess) {
    navigate("/");
    setTimeout(() => {
      cancel();
    }, 2000);
  }

  if (multilocError) {
    setTimeout(() => {
      removeError();
    }, 20000);
  }

  useEffect(() => {
    setValues({
      allValuesSelected: false,
      valuesSelected: [],
    });
  }, []);

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Common_options.step")} 2/2</div>
      <div className="step-title-create">
        {t("Create_site.not_create_users.link")}
      </div>
      <div className="design-scope">
        {t("Create_site.not_create_users.link_desc")}
      </div>
      <div className="autocomplete-container-locations">
        {AutoCompleteLinkUsersCreateLocations()}
      </div>
      <div className="container-button-create-location-link-users">
        <Button
          disabled={userSelected.length === 0}
          onClick={() =>
            createLocationLinkExistingUsers(
              token,
              userSelected as string[],
              realmId,
              true
            )
          }
          variant="contained"
          sx={{ textTransform: "none" }}
        >
          {t("Create_site.not_create_users.link_users")}
        </Button>
      </div>
      <div className="container-button-create-location-link-users">
        <Button
          onClick={() =>
            createLocationLinkExistingUsers(
              token,
              userSelected as string[],
              realmId,
              false
            )
          }
          sx={{ textTransform: "none" }}
        >
          {t("Create_site.not_create_users.create_without_users")}
        </Button>
      </div>
      <div className="background-components-fix" />
    </div>
  );
};

export default StepTwoNoCreateUsers;
