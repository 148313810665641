import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import { API } from "../../../config";
import axios from "axios";
import { setScopesForUsers } from "../../slices/masterInfoReducer/masterInfoSlice";
import { LocalizedTextGrouping, Method, Scopes } from "../users/userTypes";

export const listActionsScenariosGeneral = createAsyncThunk(
  "MasterInfoReducer/listScenarios",
  async (arg: { token: string; orgId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const url = `${API}/core/scenario?isSystemScenario=false&deletedAt=null&statusConstantId[$in][]=34&statusConstantId[$in][]=35&$eager=[scopes.[group], cerealType.text.[localizedTexts.[locale]],statusConstant, name.[localizedTexts.[locale]], desc.[localizedTexts.[locale]]]&$modifyEager[scopes.group][orgId]=${arg.orgId}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${arg.token}`,
          "Accept-Language": `${locale}`,
        },
      });
      const { data } = response;

      const newActionObject = data?.data?.map(
        (x: { name: LocalizedTextGrouping; scopes: Scopes[]; id: number }) => ({
          actionId: x.id,
          name: x.name,
          scopes: x.scopes.filter((e: any) => e.deletedAt === null),
        })
      );

      const scenarios = newActionObject?.filter(
        (x: { name: LocalizedTextGrouping; scopes: Scopes[] }) =>
          x.name !== null
      ) as Method[];

      store.dispatch(setScopesForUsers(scenarios));

      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);
